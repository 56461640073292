import { TagList } from './list'
import { TagCreate } from './create'
import { TagEdit } from './edit'

// eslint-disable-next-line
export default {
    list: TagList,
    create: TagCreate,
    edit: TagEdit
};
