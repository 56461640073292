import { CoreError } from "../../model/core/CoreError"
import { ErrorCode } from "../../model/core/ErrorCode"
import { firestore } from "../persistence"


export const SitemapService = { 
    get: async () => {
        const documentSnapshot = await firestore.collection("settings").doc("sitemap").get()

        if (!documentSnapshot.exists) {
            throw new CoreError(ErrorCode.NOT_EXISTS)
        }
        const data = documentSnapshot.data();
        return data?.sitemap;
    },
    save: async (sitemapData: Array<object>) => {
        try {
            await firestore.collection("settings").doc("sitemap").set({ sitemap: sitemapData });

            return true;
        } catch(e: any) {
            console.log(e.message);
            throw new CoreError(ErrorCode.INTERNAL, e.message);
        }
    }
}