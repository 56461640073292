import { GetListParams, UpdateParams } from "react-admin";
import { BroadcastCategory } from "../../model/broadcast_category/BroadcastCategory";
import { DocumentData, DocumentSnapshot } from "../../services/persistence";
import { EntityMapper } from "../core/EntityMapper";

export class BroadcastCategoryMapper extends EntityMapper<BroadcastCategory> {

    toFirestoreCriteria(_: GetListParams) {
        return {
            types: ["podcast", "video"],
        }
    }

    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data } = params
        return Object.assign(
            {},
            super.toFirestore(params),
            this.toFirestoreField(data, 'types'),
            this.toFirestoreField(data, 'name'),
            this.toFirestoreField(data, 'description'),
            this.toFirestoreField(data, 'color')
        ) as DocumentData;
    }

    fromFirestore(snapshot: DocumentSnapshot): BroadcastCategory {
        const data = snapshot.data();

        return Object.assign(
            new BroadcastCategory(),
            super.fromFirestore(snapshot),
            this.fromFirestoreField(data, 'types'),
            this.fromFirestoreField(data, 'name'),
            this.fromFirestoreField(data, 'description'),
            this.fromFirestoreField(data, 'color')
        );
    }
}