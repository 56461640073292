import { FormDataConsumer, SelectInput } from "react-admin";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import i18n from "../../../../language/i18n";
import { ButtonWithLinkModule } from "../button";
import { GridModule } from "../grid";
import { HtmlModule } from "../html";
import { MenuModule } from "../menu";
import { StructureModule } from "../structure";
import { TypographyModule } from "../typography";
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Button from '@material-ui/core/Button';

import styles from "./index.module.css";
import { VideoModule } from "../video";

export const PageSectionsIterator = (props: any) => {
    const currentForm = useForm()


    const pageSections = [
        { id: "typography", name: i18n.t("module.pageSection.select.title.typography"), disabled: true },
        { id: 'typography_title', name: i18n.t("module.pageSection.select.title.typography_title") },
        { id: 'typography_subtitle', name: i18n.t("module.pageSection.select.title.typography_subtitle") },
        { id: 'typography_paragraph', name: i18n.t("module.pageSection.select.title.typography_paragraph") },
        { id: 'typography_quote', name: i18n.t("module.pageSection.select.title.typography_quote") },
        { id: 'typography_highlight', name: i18n.t("module.pageSection.select.title.typography_highlight") },
        { id: 'typography_free_text', name: i18n.t("module.pageSection.select.title.typography_free_text") },
        { id: "structure", name: i18n.t("module.pageSection.select.title.structure"), disabled: true },
        { id: 'structure_image', name: i18n.t("module.pageSection.select.title.structure_image") },
        { id: 'structure_text_text', name: i18n.t("module.pageSection.select.title.structure_text_text") },
        { id: 'structure_image_text', name: i18n.t("module.pageSection.select.title.structure_image_text") },
        { id: 'structure_text_image', name: i18n.t("module.pageSection.select.title.structure_text_image") },
        { id: 'structure_image_image', name: i18n.t("module.pageSection.select.title.structure_image_image") },
        { id: 'structure_separator', name: i18n.t("module.pageSection.select.title.structure_separator") },
        { id: 'structure_spacing', name: i18n.t("module.pageSection.select.title.structure_spacing") },
        { id: "links", name: i18n.t("module.pageSection.select.title.links"), disabled: true },
        { id: 'menu_with_link', name: i18n.t("module.pageSection.select.title.menu_with_link") },
        { id: 'button_with_link', name: i18n.t("module.pageSection.select.title.button_with_link") },
        { id: "grid", name: i18n.t("module.pageSection.select.title.grid"), disabled: true },
        { id: 'grid_4x4', name: i18n.t("module.pageSection.select.title.grid_4x4") },
        { id: "others", name: i18n.t("module.pageSection.select.title.others"), disabled: true },
        { id: 'html', name: i18n.t("module.pageSection.select.title.html") },
        { id: 'video', name: i18n.t("module.pageSection.select.title.video") }
    ]

    const SelectField = ({ record }: any) => {
        let classes = [];

        if (!record.disabled) {
            classes.push(styles.selectInput);
        }

        return (
            <span className={classes.join(" ")}>
                {record.name}
            </span>
        );
    }

    function onDragEnd(result: DropResult) {
        const { source, destination } = result;

        if (!props.fields.value || !destination || !source) {
            return
        }

        // Get the item
        const item = props.fields.value[source.index];

        // Remove item from array
        const newArray = props.fields.value.filter((el: any, index: number) => index !== Number(source.index));

        // Insert item at destination
        newArray.splice(Number(destination.index), 0, item)

        currentForm.change('sections', newArray)
    }

    function onRemove(indexToRemove: number) {
        const newSections = [...props.fields.value];
        newSections.splice(indexToRemove, 1)
        currentForm.change('sections', newSections)
    }
    function onAdd() {
        const newSections = [...props.fields.value];
        newSections.push(undefined);
        currentForm.change('sections', newSections);
    }

    return (
        <FieldArray name="sections">
            {
                () => {
                    const inputs = props.fields.value || [{ name: '' }]
                    return (
                        <DragDropContext
                            onDragEnd={onDragEnd}
                        >
                            <TableContainer>
                                <Table className={styles.table}>
                                    <Droppable droppableId="droppable-sections" type="LINK">
                                        {(provided) => {
                                            return (
                                                <TableBody
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    {inputs.map((value: any, index: number) => {

                                                        return (
                                                            <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                                                {(provided) => (
                                                                    <TableRow
                                                                        hover
                                                                        tabIndex={-1}
                                                                        key={index}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <TableCell {...provided.dragHandleProps}>
                                                                            <DragHandleIcon />
                                                                        </TableCell>
                                                                        <TableCell className={styles.inputContainer}>

                                                                            <SelectInput
                                                                                source={`sections[${index}].type`}
                                                                                label={i18n.t("module.pageSections.select")}
                                                                                choices={pageSections}
                                                                                optionText={<SelectField />}
                                                                                optionValue={"id"}
                                                                                style={{ width: "100%" }}
                                                                            />

                                                                            <FormDataConsumer>
                                                                                {(formProps: any) => {
                                                                                    const { formData } = formProps;

                                                                                    if (formData.sections === undefined) {
                                                                                        return null;
                                                                                    }

                                                                                    const section = value

                                                                                    if (section === undefined) {
                                                                                        return null;
                                                                                    }

                                                                                    const sectionIndex = `sections[${index}]`

                                                                                    if (section.type.search("grid") >= 0) {
                                                                                        return <GridModule id={sectionIndex} posts={props.posts} categories={props.categories} />
                                                                                    }

                                                                                    if (section.type.search("menu") >= 0) {
                                                                                        return <MenuModule id={sectionIndex} formData={formData} menuType={section.type} />
                                                                                    }

                                                                                    if (section.type.search("button") >= 0) {
                                                                                        return <ButtonWithLinkModule id={sectionIndex} />
                                                                                    }

                                                                                    if (section.type.search("typography") >= 0) {
                                                                                        return <TypographyModule id={sectionIndex} pageId={props.pageId} />
                                                                                    }

                                                                                    if (section.type.search("structure") >= 0) {
                                                                                        return <StructureModule id={sectionIndex} />
                                                                                    }

                                                                                    if (section.type.search("html") >= 0) {
                                                                                        return <HtmlModule id={sectionIndex} />
                                                                                    }

                                                                                    if (section.type === "video") {
                                                                                        return <VideoModule id={sectionIndex} />
                                                                                    }

                                                                                    return null;
                                                                                }}
                                                                            </FormDataConsumer>
                                                                        </TableCell>
                                                                        <TableCell className={styles.removeContainer}>
                                                                            <Button style={{ color: 'red' }} type="button" onClick={() => onRemove(index)}>
                                                                                {i18n.t("default.delete")}
                                                                            </Button>
                                                                        </TableCell>
                                                                    </ TableRow>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    })}
                                                </TableBody>
                                            )
                                        }}
                                    </Droppable>
                                </Table>
                                <Button
                                    type="button"
                                    onClick={onAdd}
                                    className={styles.addButton}
                                    color="secondary"
                                    variant="contained"
                                    style={{ marginTop: '16px' }}
                                >
                                    {i18n.t("module.pageSections.input")}
                                </Button>
                            </TableContainer>
                        </DragDropContext>
                    )
                }
            }
        </FieldArray>
    )
}