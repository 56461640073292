import * as React from 'react';
import { FC } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useRecordContext } from 'ra-core';

import { InjectedFieldProps, PublicFieldProps, sanitizeFieldRestProps } from 'react-admin';
import { fieldPropTypes } from 'ra-ui-materialui/lib/field/types';



const useStyles = makeStyles(
    {
        list: {
            display: 'flex',
            listStyleType: 'none',
        },
        image: {
            margin: '0.5rem',
            maxHeight: '10rem',
        },
    },
    { name: 'RaImageField' }
);

export interface ImageFieldProps extends PublicFieldProps, InjectedFieldProps {
    src?: string;
    title?: string;
    classes?: object;
}

const ImagePdfField: FC<ImageFieldProps> = props => {
    const {
        className,
        classes: classesOverride,
        emptyText,
        source,
        src,
        title,
        ...rest
    } = props;
    const record = useRecordContext(props);
    const sourceValue :string= get(record, source!);
    const classes = useStyles(props);
    
    
    if (!sourceValue) {
        return emptyText ? (
            <Typography
                component="span"
                variant="body2"
                className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                {emptyText}
            </Typography>
        ) : (
            <div className={className} {...sanitizeFieldRestProps(rest)} />
        );
    }

    if (Array.isArray(sourceValue)) {
        return (
            <ul
                className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                {sourceValue.map((file, index) => {
                    const fileTitleValue = get(file, title!) || title;
                    const srcValue = get(file, src!) || title;

                    return (
                        <li key={index}>
                            <img
                                alt={fileTitleValue}
                                title={fileTitleValue}
                                src={srcValue}
                                className={classes.image}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    }

    const titleValue = get(record, title!) || title;
    

    if(sourceValue.includes(".pdf")){
        let title = sourceValue.slice(0,sourceValue.indexOf(".pdf") +4)
        title = title.slice(title.indexOf("menuImage") +12, title.length)

        return (
            <div className={className}>
                <span>{title}</span>
            </div>
        )
    }

    return (
        <div className={className} {...sanitizeFieldRestProps(rest)}>
            <img
                title={titleValue}
                alt={titleValue}
                src={sourceValue}
                className={classes.image}
            />
        </div>
    );
};

ImagePdfField.defaultProps = {
    addLabel: true,
};

ImagePdfField.propTypes = {
    ...fieldPropTypes,
    src: PropTypes.string,
    title: PropTypes.string,
};

export default ImagePdfField;
