import firebase from "firebase/app";
import { auth } from "../persistence";
import { AuthProvider as RaAuthProvider, UserIdentity } from 'ra-core';
import { Claims } from "../../model/auth/Claims";
import { AuthContainer } from "./AuthContainer";
import i18n from "../../language/i18n";

class AuthClient {
    private auth: firebase.auth.Auth;

    constructor() {
        this.auth = auth
    }

    public async HandleAuthLogin() {
        return this.getUserLogin() as any
    }

    public HandleAuthLogout() {
        return auth.signOut();
    }

    public async HandleAuthCheck(params: any): Promise<void> {
        const user = await this.getUserLogin() as any
        const token = await user.getIdTokenResult();

        const isAdminOrEditor = [Claims.ADMINISTRATOR, Claims.EDITOR].some(claim => token.claims[claim])
        if (!isAdminOrEditor) {
            return Promise.reject({ redirectTo: '/access-denied', message: i18n.t("default.errors.accessDenied") })
        }

        return user; // Prevents breaking change
    }

    public HandleAuthError(errorHttp: any) {
        console.log('HandleAuthLogin: error occured', { errorHttp });
        return Promise.resolve();
    }

    public async HandleGetPermissions() {
        const user = await this.getUserLogin();
        const token = await user.getIdTokenResult();

        return token.claims;
    }

    public async HandleGetIdentity(): Promise<UserIdentity> {
        try {
            const { uid, displayName, photoURL } = await this.getUserLogin();

            const identity: UserIdentity = {
                id: uid,
                fullName: String(displayName),
                avatar: String(photoURL),
            };
            AuthContainer.setUser(identity)

            return identity;
        } catch (e) {
            console.log('HandleGetIdentity: no user is logged in', e);
            return null as any;
        }
    }

    private getUserLogin(): Promise<firebase.User> {
        return new Promise((resolve, reject) => {

            if (this.auth.currentUser) {
                return resolve(this.auth.currentUser);
            }

            const unsubscribe = this.auth.onAuthStateChanged((user) => {
                unsubscribe();
                if (user) {
                    return resolve(user);
                } else {
                    return reject();
                }
            });
        });
    }
}

export function AuthProvider(): RaAuthProvider {
    const auth = new AuthClient();

    const provider: RaAuthProvider = {
        // React Admin Interface
        login: () => auth.HandleAuthLogin(),
        logout: () => auth.HandleAuthLogout(),
        checkAuth: (params) => auth.HandleAuthCheck(params),
        checkError: (error) => auth.HandleAuthError(error),
        getPermissions: () => auth.HandleGetPermissions(),
        getIdentity: () => auth.HandleGetIdentity()
    };
    return provider;
}