export class CoreError extends Error {
    details?: string;

    constructor(message: string, details?: string) {
        super(message);
        this.details = details;

        // allows to restore the prototype chain
        Object.setPrototypeOf(this, new.target.prototype);
    }
}