import { MapperInterface } from "./MapperInterface";
import { DocumentData, DocumentSnapshot, QueryDocumentSnapshot } from '../../services/persistence'
import { CreateParams, GetListParams, UpdateParams } from "react-admin";

export abstract class Mapper<T> implements MapperInterface<T, DocumentData, QueryDocumentSnapshot> {

    abstract toFirestore(params: UpdateParams | CreateParams): Promise<DocumentData> | DocumentData;

    abstract fromFirestore(snapshot: DocumentSnapshot): Promise<T> | T;

    abstract toFirestoreCriteria(params: GetListParams): any

    protected toFirestoreField(entity: any, key: string) {
        return entity[key] !== undefined ? { [key]: entity[key] } : {};
    }

    protected fromFirestoreField(entity: any, key: string) {
        return entity[key] !== undefined ? { [key]: entity[key] } : {};
    }

}