import i18n from "../language/i18n";

export function generateCode(length: number = 6) {
    return Math.random().toString(36).substr(2, length).toUpperCase();
}

export function countDecimals(n: number) {

    if (typeof n !== 'number') {
        return 0
    }

    if (Math.floor(n.valueOf()) === n.valueOf()) {
        return 0
    };

    const str = n.toString();
    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
        return str.split("-")[1] || 0;
    } else if (str.indexOf(".") !== -1) {
        return str.split(".")[1].length || 0;
    }
    return str.split("-")[1] || 0;
}

export function getPostTypes() {
    return [
        { id: 'news', name: i18n.t("post.type.news") },
        { id: 'blog', name: i18n.t("post.type.blog") },
        { id: 'note', name: i18n.t("post.type.note") },
    ];
}

export function getBroadcastTypes() {
    return [
        { id: 'video', name: i18n.t("post.type.video") },
        { id: 'podcast', name: i18n.t("post.type.podcast") },
    ];
}

export function getEventTypes() {
    return [
        { id: 'event', name: i18n.t("post.type.event") },
    ];
}

