import { TopToolbar, sanitizeListRestProps } from 'react-admin';

import { TagCreationButton } from '../../sections/TagCreationButton';

export const TagActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;

    return (
        <TopToolbar {...sanitizeListRestProps(rest)}>
            <TagCreationButton />
        </TopToolbar>
    );
};
