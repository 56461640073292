import { Divider, Dropdown, Space } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useState } from 'react';
import i18n from '../../../../../../../../language/i18n';
import { GetLabel } from '../../../../../../helpers/getLabel';

import { Tag } from '../../../../../../model/tag/tag';
import ChevronDown from '../../../../../base/icon/chevronDown';
import B1 from '../../../../../base/text/b1';
import styles from './index.module.css';

interface FilterDesktopProps {
    checkedList: Tag[],
    optionsList: Tag[],
    indeterminate: boolean,
    allChecked: boolean,
    loading: boolean
    // list must be 'any' to avoid conflicts with AntD
    handleFilterChange: (list: any) => void,
    handleClearAll: () => void,
    handleCheckAll: (event: CheckboxChangeEvent) => void,
}

function FilterDesktop({
    optionsList,
    checkedList,
    handleFilterChange,
    indeterminate,
    allChecked,
    handleClearAll,
    handleCheckAll,
    loading
}: FilterDesktopProps) {
    const [categoriesVisible, setCategoriesVisible] = useState<boolean>(false);
    const getLabel = new GetLabel();

    const handleVisibleCategoriesChange = (flag: boolean) => {
        setCategoriesVisible(flag);
    }

    const menuFilter = (
        <Space direction="vertical" className={styles.menuFilterContainer}>
            <Checkbox
                indeterminate={indeterminate}
                onChange={handleCheckAll}
                checked={allChecked}
                className={styles.selectAll}
                disabled={loading}
            >
                {i18n.t("plus.section.filters.all")}
            </Checkbox>
            <Checkbox.Group
                value={checkedList as any}
                onChange={handleFilterChange}
                className={styles.selectGroup}
                disabled={loading}
            >
                {optionsList.map((item: Tag) => {
                    return <Checkbox className={styles.checkbox} value={item} key={item.id}>{item.name}</Checkbox>
                })}
            </Checkbox.Group>
            <span
                className={styles.clearButton}
                onClick={loading ? undefined : handleClearAll}

            >
                {i18n.t("plus.section.filters.clear")}
            </span>
        </Space>
    );

    return (
        <div className={styles.filterContainer}>
            <Dropdown
                overlay={menuFilter}
                trigger={['click']}
                onVisibleChange={handleVisibleCategoriesChange}
                visible={categoriesVisible}
            >
                <div className={styles.dropdown}>
                    <Divider type="vertical" className={styles.divider} />
                    <span className={[styles.pointer, styles.filterLabel].join(" ")} >
                        <B1>
                            {getLabel.getDropdownCheckboxLabel(checkedList, optionsList)} <ChevronDown className={styles.chevron} />
                        </B1>
                    </span>
                </div>
            </Dropdown>
        </div>
    )
}

export default FilterDesktop;