import { Mapper } from "../../mappers/core/Mapper";
import { CoreError } from "../../model/core/CoreError";
import { ErrorCode } from "../../model/core/ErrorCode";
import { storage } from "../../services/persistence";
import { ResourceItemInterface } from "../../services/resource";
import { CoreRepositoryInterface } from "../core";

export const StorageRepository: CoreRepositoryInterface = {
    getOne: async (id: string, collection: string, mapper: Mapper<any>) => {
        const imgData = await getUrl(`${collection}/${id}`);

        if (imgData === undefined) {
            throw new CoreError(ErrorCode.NOT_EXISTS);
        }

        const object = mapper.fromFirestore({id, filename: id, image: imgData } as any);

        return {
            data: {
                id: id,
                ...object
            } as any
        };
    },
    create: async (params: any, requestKind: string, mapper: Mapper<any>) => {
        const data = await mapper.toFirestore(params.data);
        
        const storageRef = storage.ref(`${requestKind}/${data.filename}.jpg`);
        await storageRef.put(data.image);

        return {
            response: {
                storageRef: {
                    id: data.filename,
                    path: `${requestKind}/${data.filename}`
                }
            }
        };
    },
    update: async (params: any, service: ResourceItemInterface) => {
        const currentId = params.id;
        const data = await service.mapper.toFirestore(params.data);

        if (typeof data.image === 'string') {
            const imgRef = await getValidRef(`${service.requestKind}/${currentId}`);
            const downloadURL = await imgRef?.getDownloadURL();
            const imageBlob = await getBlob(downloadURL);
            
            const storageRef = storage.ref(`${service.requestKind}/${data.filename}.jpg`);
            if (imageBlob !== undefined) {
                await storageRef.put(imageBlob);
            }
        }

        if (data.image instanceof File) {
            const storageRef = storage.ref(`${service.requestKind}/${data.filename}.jpg`);
            await storageRef.put(data.image);
        }

        if (data.filename !== currentId) {
            await StorageRepository.delete(params, service);
        }

        return {
            response: {
                storageRef: {
                    id: data.filename,
                    path: `${service.requestKind}/${data.filename}`
                }
            }
        };
    },
    delete: async (params: any, service: ResourceItemInterface) => {
        const validRef = await getValidRef(`${service.collection}/${params.id}`);
        await validRef?.delete();

        return;
    }
}

const getBlob = async (link: string) : Promise<Blob | undefined> => {
    return new Promise(resolve => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", link, true);
        xhr.responseType = 'blob';
        xhr.onload = function() {
            resolve(xhr.response);
        };
        xhr.onerror = function () {
            resolve(undefined);
            console.error("** An error occurred during the XMLHttpRequest");
        };
        xhr.send();
    }); 
}

const getValidRef = async (reference: string) => {
    const extensions = ["jpg", "jpeg", "png"];
    
    const imgRefs = await Promise.all(extensions.map(async (extension: string) => {
        try {
            const imgRef = storage.ref(`${reference}.${extension}`);
            await imgRef.getDownloadURL();
            
            return imgRef;
        } catch(e){
            return undefined;
        }
    }));

    const imgResponse = imgRefs.filter((data: any) => data !== undefined);
    return imgResponse[0];
}

const getUrl = async (reference: string) => {
    try {
        const validRef = await getValidRef(reference);
        return await validRef?.getDownloadURL();
    } catch(e) {
        return undefined;
    }
}
