import { TopToolbar, sanitizeListRestProps } from 'react-admin';

import { PartnershipCategoryCreationButton } from '../../sections/PartnershipCategoryCreationButton';

export const PartnershipCategoryActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;

    return (
        <TopToolbar {...sanitizeListRestProps(rest)}>
            <PartnershipCategoryCreationButton />
        </TopToolbar>
    );
};
