import styles from './index.module.css';
import { Datagrid, DatagridProps, DatagridBodyProps, DatagridBody } from 'react-admin';
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { useMediaQuery } from '@material-ui/core';

export type TableProps = DatagridBodyProps
    & DatagridProps
    & { rowConfig: any, responsiveList: any };

export const Table: React.FC<TableProps> = (props: TableProps) => {
    const isSmall = useMediaQuery<Theme>((theme: Theme) => theme.breakpoints.down('sm'));

    if (isSmall && props.responsiveList !== undefined) {
        return props.responsiveList;
    }

    return (
        <Datagrid
            {...props}
            className={styles.tableBase}
            body={<DatagridBody {...props} row={props.rowConfig} />}
        >
            {props.children}
        </Datagrid>
    );
}
