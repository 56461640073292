import { List, ListProps } from 'react-admin';
import { BroadcastCategoryListSection } from '../../../sections/BroadcastCategoryList';
import { BroadcastCategoryActions } from '../actions';
import { BroadcastCategoryFilter } from '../filter';
import styles from './index.module.css';

export const BroadcastCategoryList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<BroadcastCategoryActions />}
            filters={<BroadcastCategoryFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <BroadcastCategoryListSection />
        </List>
    )
}