import { ArrayInput } from "react-admin";
import i18n from "../../../../language/i18n";
import { PageSectionsIterator } from "../iterator";
import { PageVideoType } from "../video";

export const PageSectionModule = (props: any) => {
    const validate = (values: any) => {
        if (Array.isArray(values) === false) {
            return undefined;
        }

        const sections = [...values].filter((section: any) => section && section.type === "video");

        if (sections.length === 0) {
            return undefined;
        }

        const sectionsIsValid = sections.flatMap((section: any) => {
            if (section.video === undefined) {
                return [];
            }

            if (section.video.link === undefined || section.video.link === "") {
                return "O link do vídeo é obrigatório";
            }

            if (section.video.provider === PageVideoType.Youtube.toLowerCase()) {
                // eslint-disable-next-line
                const youtubeRegex = new RegExp(/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/|embed\/|watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/);
                return youtubeRegex.test(section.video.link) === true ? [] : "O link do vídeo é inválido";
            }

            if (section.video.provider === PageVideoType.Microsoft.toLowerCase()) {
                // eslint-disable-next-line
                let regex = /(https?:\/\/web.microsoftstream.com\/(video|channel)\/[\w\-]+)/;

                if (section.video.link.includes('embed')) {
                    // eslint-disable-next-line
                    regex = /(https?:\/\/web\.microsoftstream\.com\/embed\/(video|channel)\/[\w\-]+)/;
                }

                const microsoftStreamRegex = new RegExp(regex);
                return microsoftStreamRegex.test(section.video.link) === true ? [] : "O link do vídeo é inválido";
            }

            return [];
        });

        return sectionsIsValid.length > 0 ? sectionsIsValid[0] : undefined;
    };

    return (
        <>
            <ArrayInput
                source={"sections"}
                label={i18n.t("module.pageSections.input")}
                id={"page-sections"}
                validate={validate}
            >
                <PageSectionsIterator {...props} categories={props.categories} pageId={props.pageId} posts={props.posts} />
            </ArrayInput>
        </>
    );
}
