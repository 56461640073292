import { TopToolbar, sanitizeListRestProps } from 'react-admin';
import { CityCreationButton } from '../../sections/CityCreationButton';

export const CityActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;

    return (
        <TopToolbar {...sanitizeListRestProps(rest)}>
            <CityCreationButton />
        </TopToolbar>
    );
};
