import { Entity } from "../core/Entity";
import { PostStatus } from "../posts/PostStatus";
import { Tag } from "../tag/Tag";

export class Broadcast extends Entity {
    public title?: string
    public subTitle?: string
    public body?: string
    public images?: [];
    public status?: PostStatus;
    public tags?: Array<Tag>;
    public type?: 'podcast' | 'video';

    static templateEvent() {
        return Object.assign(
            {},
            { title: 'Título do Broadcast' },
            { subTitle: 'Subtítulo do Broadcast' },
            { body: 'Conteúdo do Broadcast' },
            { type: 'podcast' }
        ) as Broadcast
    }

}

export const BROADCAST_KIND = "post"