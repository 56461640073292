import { useEffect } from 'react';
import {
    Button, useCreate, useNotify, useRedirect
} from 'react-admin';
import i18n from '../../language/i18n';
import { Partnership, PARTNERSHIP_KIND } from '../../model/partnership/Partnership';

export function PartnershipCreationButton() {
    const [create, { data, loaded, loading, error }] = useCreate();
    const redirect = useRedirect()
    const notify = useNotify()

    useEffect(() => {

        if (error) {
            notify(i18n.t("default.errors.partnership"), 'error')
            return
        }

        if (loaded === false) {
            return
        }

        if (data === null || data === undefined) {
            notify(i18n.t("default.errors.partnership"), 'error')
            return
        }

        redirect('edit', '/partnership', data.id)
    }, [loaded, data, error, notify, redirect])

    async function onClick(event: any) {
        event.preventDefault()
        create(PARTNERSHIP_KIND, Partnership.templatePartnership())
    }

    return (
        <Button
            label={i18n.t("default.create")}
            onClick={onClick}
            disabled={loading}
            color="primary"
            variant="outlined"
        />
    )
}
