import { TextInput, Filter } from 'react-admin';
import styles from './index.module.css';
import i18n from "../../../language/i18n";

export const PartnershipFilter = (props: any) => {
    return (
        <Filter {...props} classes={{ form: styles.form }}>
            <TextInput className={styles.search} label={i18n.t("default.search")} source="searchText" alwaysOn />
        </Filter>
    );
}
