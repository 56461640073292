import { Button, useRedirect } from 'react-admin';
import i18n from '../../language/i18n';

export const EventScheduleButton = ({ data }: any) => {
    const redirect = useRedirect();

    const onClick = (event: any) => {
        event.preventDefault();
        redirect(`/event/${data.id}/schedule`);
    }

    const label = data.scheduled === true ?
        i18n.t("posts.action.unschedule.label") : i18n.t("posts.action.schedule.label")

    return (
        <Button
            id={"schedule-post-btn"}
            label={label}
            onClick={onClick}
            variant="outlined"
            color="primary"
        />
    )

}
