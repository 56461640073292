import {
    Edit,
    EditProps,
    ImageField,
    ImageInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    TopToolbar,
} from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";

import styles from "./index.module.css";

const BroadcastToolbar = (props: EditProps) => (
    <Toolbar {...(props as any)}>
        <SaveButton redirect={false} />
    </Toolbar>
);

const BroadcastActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.broadcast_config")} </PageTitle>
        </TopToolbar>
    );
};

export const SetupBroadcast = (props: EditProps) => {

    const validatePageEdit = (values: any) => {
        const errors: any = {};

        return errors
    };

    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            actions={<BroadcastActions />}
            className={"mid-container"}
        >
            <SimpleForm validate={validatePageEdit} toolbar={<BroadcastToolbar {...props} />}>
                <TextInput source={"title"} label={i18n.t("broadcast_config.title")} style={{ width: 400 }} />
                <TextInput source={"subtitle"} label={i18n.t("broadcast_config.subtitle")} multiline={true} fullWidth={true} />
                <ImageInput
                    source="background"
                    accept="image/*"
                    multiple={false}
                    label={i18n.t("broadcast_config.background")}
                    labelMultiple=""
                >
                    <ImageField source="src" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    );
};
