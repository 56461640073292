import { TextInput, Filter, SelectInput } from 'react-admin';
import styles from './index.module.css';
import i18n from "../../../language/i18n";
import { useEffect, useState } from 'react';
import SetupTeamsService from '../../../services/setupTeams';

export const TeamsFilter = (props: any) => {
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ config, setConfig ] = useState<Array<{ value: string, type: string}>|undefined>(undefined);

    useEffect(() => {
        getPageData()
        .then((data: any) => {
            const areasConfig = data.tabs.map((item: { value: string, type: string}) => {
                return { "id": item.type, "name": item.value}
            });

            setConfig(areasConfig);
            setLoading(false);
        })
        .catch((e) => {
            setLoading(false);
        });
    }, []);
    
    async function getPageData() {
        try {
            return await SetupTeamsService.getConfig()
        } catch (error) {
            console.log("error", error)
        }
    }

    if (loading === true) {
        return null;
    }

    return (
        <Filter {...props} classes={{ form: styles.form }} >
            <TextInput className={styles.search} label={i18n.t("default.search")} source="searchText" alwaysOn />
            <SelectInput
                source="type"
                choices={config}
                label={i18n.t("teams.list.label")}
            />
        </Filter>
    );
}

