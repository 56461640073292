import { CoreError } from "../../model/core/CoreError"
import { ErrorCode } from "../../model/core/ErrorCode"
import { firestore } from "../persistence"


export const JobTitleService = { 
    save: async (data: Array<string>) => {
        try {
            await firestore.collection("settings").doc("job_titles").set({ teamsPageOrder: data }, { merge: true });

            return true;
        } catch(e: any) {
            console.log(e.message);
            throw new CoreError(ErrorCode.INTERNAL, e.message);
        }
    }
}