import { Button, useCreate, useRedirect } from 'react-admin';
import i18n from '../../language/i18n';

export function JobsOrderButton() {
    const [, { loading }] = useCreate();
    const redirect = useRedirect()

    async function onClick(event: any) {
        event.preventDefault()
        redirect("/job_title_settings/job_titles")
    }

    return (
        <Button
            label={i18n.t("page.order_jobs.button")}
            onClick={onClick}
            disabled={loading}
            color="primary"
            variant="outlined"
            style={{ marginRight: 10 }}
        />
    )
}
