import { GetListParams, UpdateParams } from "react-admin";
import { Tag } from "../../model/tag/Tag";
import { DocumentData, DocumentSnapshot } from "../../services/persistence";
import { EntityMapper } from "../core/EntityMapper";

export class TagMapper extends EntityMapper<Tag> {

    toFirestoreCriteria(params: GetListParams) {
        return {
            ...(params.filter.types ? { types: params.filter.types } : { types: ["news", "note", "blog"] }),
        }
    }

    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data } = params
        return Object.assign(
            {},
            super.toFirestore(params),
            this.toFirestoreField(data, 'types'),
            this.toFirestoreField(data, 'name'),
            this.toFirestoreField(data, 'description'),
            this.toFirestoreField(data, 'color')
        ) as DocumentData;
    }

    fromFirestore(snapshot: DocumentSnapshot): Tag {
        const data = snapshot.data();

        return Object.assign(
            new Tag(),
            super.fromFirestore(snapshot),
            this.fromFirestoreField(data, 'types'),
            this.fromFirestoreField(data, 'name'),
            this.fromFirestoreField(data, 'description'),
            this.fromFirestoreField(data, 'color')
        );
    }

}