import {
    ArrayInput,
    Edit,
    EditProps,
    SaveButton,
    SimpleForm,
    Toolbar,
    TopToolbar,
} from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";

import styles from "./index.module.css";
import JobsOrderIterator from "../iterator";

const OrderJobsActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle>{i18n.t("page.order_jobs.title")}</PageTitle>
        </TopToolbar>
    );
};

const OrderJobsToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);


export const OrderJobsPage = (props: EditProps) => {
    const redirect = (_: string, id: string) => `/job_title_settings/job_titles`;
    
    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            actions={<OrderJobsActions />}
            className={"mid-container"}
        >
            <SimpleForm redirect={redirect} toolbar={<OrderJobsToolbar />}>
                <ArrayInput source="teamsPageOrder" label={i18n.t("page.order_jobs.label")}>
                    <JobsOrderIterator {...props} />
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
};
