import { Entity } from "../core/Entity";

export class SetupTeams extends Entity {
    public title?: string;
    public subtitle?: string;
    public background?: string;
    public tabLeft?: string;
    public tabCenter?: string;
    public tabRight?: string;
    public descriptionLeft?: string;
    public descriptionCenter?: string;
    public descriptionRight?: string;
}
