import { ImageField, ImageInput } from "ra-ui-materialui"
import { useFormState } from 'react-final-form';
import i18n from "../../../../language/i18n"

export const ImageModule = () => {
    const { values } = useFormState();

    if (["video"].includes(values.type)) {
        return null
    }

    return (
        <ImageInput
            source="images"
            accept="image/*"
            label={`${i18n.t("posts.edit.images.label")}`}
            labelMultiple={i18n.t("posts.edit.images.description")}
            multiple
        >
            <ImageField source="src" />
        </ImageInput>
    )
}