import { useState } from 'react';
import { Popper } from '../../components/base/popper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { PopperForm } from "../../components/base/popperForm";
import { PopperFormHeader } from "../../components/base/popperFormHeader";
import { ResourceService } from '../../services/resource';
import { POST_KIND } from '../../model/posts/Post';
import { RequestPostAction } from '../../model/posts/RequestPostAction';
import { Request } from '../../model/request/Request';
import { useDataProvider } from 'ra-core';
import i18n from '../../language/i18n';

export type PostPublishFormType = {
    onConfirm: (response: any) => void;
    onCancel: () => void;
    onError: (error: any) => void;
    open: boolean;
    data: any;
}

export const PostPublishForm = (props: PostPublishFormType) => {
    const publishBtnElement = document.getElementById("publish-post-btn")
    const dataProvider = useDataProvider();
    const [value, setValue] = useState<string>('');
    const [loading, setLoading] = useState(false)

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleSubmit = async (event: React.MouseEvent) => {
        event.preventDefault();
        setLoading(true)

        let res
        try {
            const service = ResourceService[POST_KIND]
            const data = { status: value }
            const request = Request.fromCustomAction(POST_KIND, props.data.id, RequestPostAction.CHANGE_STATUS, service, data)

            res = await dataProvider.requestAction({ resource: POST_KIND, request })
        } catch (error) {
            setLoading(false)
            props.onError && props.onError(error);
            return
        }

        setLoading(false)
        props.onConfirm && props.onConfirm(res);
    };

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    };

    const getAvailableStatus = () => {
        let radioGroupStatus;

        switch (props.data.status) {
            case 'draft':
                radioGroupStatus =
                    (
                        <>
                            <FormControlLabel value="published" control={<Radio />} label={i18n.t("posts.status.publish")} disabled={loading} />
                            <FormControlLabel value="archived" control={<Radio />} label={i18n.t("posts.status.archive")} disabled={loading} />
                        </>
                    );
                break;
            case 'published':
                radioGroupStatus =
                    (
                        <>
                            <FormControlLabel value="unpublished" control={<Radio />} label={i18n.t("posts.status.unpublish")} disabled={loading} />
                            <FormControlLabel value="archived" control={<Radio />} label={i18n.t("posts.status.archive")} disabled={loading} />
                            <FormControlLabel value="draft" control={<Radio />} label={i18n.t("posts.status.draft")} disabled={loading} />
                        </>
                    );
                break;
            case 'unpublished':
                radioGroupStatus =
                    (
                        <FormControlLabel value="published" control={<Radio />} label={i18n.t("posts.status.publish")} disabled={loading} />
                    );
                break;
            case 'archived':
                radioGroupStatus =
                    (
                        <>
                            <FormControlLabel value="published" control={<Radio />} label={i18n.t("posts.status.publish")} disabled={loading} />
                        </>
                    );
                break;
        }

        return radioGroupStatus;
    }

    const header = (<PopperFormHeader>{i18n.t("posts.action.updateStatus.description")}</PopperFormHeader>);

    const body = (
        <div>
            <RadioGroup aria-label="publish_post" name="publish_post" value={value} onChange={handleRadioChange}>
                {getAvailableStatus()}
            </RadioGroup>
        </div>
    );

    const footer = (
        <>
            <Button onClick={handleCancel} disabled={loading}>
                {i18n.t("default.cancel")}
            </Button>
            <Button onClick={handleSubmit} color="primary" disabled={loading || !value}>
                {i18n.t("default.confirm")}
            </Button>
        </>
    );

    return (
        <Popper
            open={props.open === true}
            anchor={publishBtnElement}
        >
            <PopperForm
                header={header}
                body={body}
                footer={footer}
            />
        </Popper>
    );
}
