import { useState } from 'react';
import { Record, useDataProvider } from 'react-admin';
import Button from '@material-ui/core/Button';
import { PopperFormHeader } from '../../components/base/popperFormHeader';
import { POST_KIND } from '../../model/posts/Post';
import { RequestPostAction } from '../../model/posts/RequestPostAction';
import { Request } from '../../model/request/Request';
import { ResourceService } from '../../services/resource';
import { Popper } from '../../components/base/popper';
import { PopperForm } from '../../components/base/popperForm';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import i18n from '../../language/i18n';
import { pt } from 'date-fns/esm/locale';

export type PostScheduleFormProps = {
    onLoadingStarts?: () => void;
    onConfirm: (response: any) => void;
    onCancel: () => void;
    onError: (error: any) => void;
    open: boolean;
    data: Record;
}

export function PostScheduleForm(props: PostScheduleFormProps) {
    const scheduleBtnElement = document.getElementById("schedule-post-btn")
    const isScheduled = props.data.scheduled === true
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false)
    const [selectedPublishDate, setSelectedPublishDate] = useState(isScheduled ? props.data.publishDate : new Date())

    async function requestUnschedule() {
        const service = ResourceService[POST_KIND]
        const request = Request.fromCustomAction(POST_KIND, props.data.id as string, RequestPostAction.UNSCHEDULE, service)

        return await dataProvider.requestAction({ resource: POST_KIND, request })
    }

    async function requestSchedule() {
        const service = ResourceService[POST_KIND]
        const data = { publishDate: selectedPublishDate }
        const request = Request.fromCustomAction(POST_KIND, props.data.id as string, RequestPostAction.SCHEDULE, service, data)

        return await dataProvider.requestAction({ resource: POST_KIND, request })
    }

    const handleSubmit = async (event: React.MouseEvent) => {
        event.preventDefault();
        props.onLoadingStarts && props.onLoadingStarts();
        setLoading(true)

        let res
        try {

            res = isScheduled ? await requestUnschedule() : await requestSchedule()

        } catch (error) {
            setLoading(false)
            props.onError && props.onError(error);
            return
        }

        setLoading(false)
        props.onConfirm && props.onConfirm(res);
    };

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    };

    const header = (
        <PopperFormHeader>
            {isScheduled ? i18n.t("posts.action.unschedule.description") : i18n.t("posts.action.schedule.description")}
        </PopperFormHeader>
    );

    const body = (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
            <DateTimePicker
                ampm={false}
                minutesStep={15}
                disablePast
                format="dd/MM/yyyy HH:mm"
                variant="dialog"
                label={i18n.t("posts.publishDate")}
                value={selectedPublishDate}
                onChange={(date) => setSelectedPublishDate(date as Date)}
                disabled={isScheduled || loading}
            />
        </MuiPickersUtilsProvider>
    )

    const footer = (
        <>
            <Button onClick={handleCancel} disabled={loading}>
                {isScheduled ? i18n.t("default.no") : i18n.t("default.cancel")}
            </Button>
            <Button onClick={handleSubmit} color="primary" disabled={loading}>
                {isScheduled ? i18n.t("default.yes") : i18n.t("default.confirm")}
            </Button>
        </>
    );

    return (
        <Popper
            open={props.open === true}
            anchor={scheduleBtnElement}
        >
            <PopperForm
                header={header}
                body={body}
                footer={footer}
            />
        </Popper>
    );
}
