import ReactAdminRichTextInput from 'ra-input-rich-text';
import React from 'react';
import styles from "./index.module.css";

export type CustomRichTextInputProps = {
    wrapperClassNames?: string[]
}

export default function RichTextInput(props: React.ComponentProps<typeof ReactAdminRichTextInput> & CustomRichTextInputProps) {

    function getCustomClasses() {
        const customClassNames = [styles.customRichTextInput]

        if (props.wrapperClassNames) {
            customClassNames.push(...props.wrapperClassNames)
        }

        return customClassNames.join(" ")
    }

    return (
        <div className={getCustomClasses()}>
            <ReactAdminRichTextInput
                {...props}
            />
        </div>
    );
}