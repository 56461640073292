
import { Page } from "../../page/Page";
import { firestore } from "../../../../services/persistence";


const pageService = {
    get: async function (id: string): Promise<Page> {
        const pageSnapshot = await firestore.collection('pages').doc(id).get();        
        return pageSnapshot.data() as Page;
    },
}

export default pageService