import { Grid, withStyles } from "@material-ui/core";
import { Title } from '../../../../../sections/Title';
import { AutocompleteArrayInput } from "react-admin";

import styles from "./index.module.css";
import i18n from '../../../../../language/i18n';

export type AppsFormGridProps = {
    apps?: Array<object>
}

const AppsFormGrid = ({ apps }: AppsFormGridProps) => {
    return (
        <div>
            <div>
                <Grid container >
                    <Grid xs={12}>
                        <Title>{i18n.t("homepage.setup.appsForm.homepageTitle")}</Title>
                    </Grid>
                    <Grid item xs={12} className={styles.hotTopicGrid} spacing={1}>
                        <AutocompleteArrayInput
                            label={i18n.t("homepage.setup.appsForm.homepageInput")}
                            source={"homepageApps"}
                            fullWidth={true}
                            choices={apps || []}
                        />
                    </Grid>
                </Grid>
            </div>
            <div>
                <Grid container >
                    <Grid xs={12}>
                        <Title>{i18n.t("homepage.setup.appsForm.headerTitle")}</Title>
                    </Grid>
                    <Grid item xs={12} className={styles.hotTopicGrid} spacing={1}>
                        <AutocompleteArrayInput
                            label={i18n.t("homepage.setup.appsForm.headerInput")}
                            source={"headerApps"}
                            fullWidth={true}
                            choices={apps || []}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default withStyles({})(AppsFormGrid);