import { Mapper } from "../../mappers/core/Mapper";
import { EventMapper } from "../../mappers/event/EventMapper";
import { HighlightedEventMapper } from "../../mappers/highlighted_event/HighlightedEventMapper";
import { PageMapper } from "../../mappers/page/PageMapper";
import { HomepageMapper } from "../../mappers/pages_custom/HomepageMapper";
import { PartnershipMapper } from "../../mappers/partnership/PartnershipMapper";
import { PartnershipCategoryMapper } from "../../mappers/partnership_category/PartnershipCategoryMapper";
import { BroadcastMapper } from "../../mappers/broadcast/BroadcastMapper";
import { PostMapper } from "../../mappers/post/PostMapper";
import { TagMapper } from "../../mappers/tag/TagMapper";
import { Schemas } from "../../model/core/Schemas";
import { BroadcastCategoryMapper } from "../../mappers/broadcast_category/BroadcastCategoryMapper";
import { EventCategoryMapper } from "../../mappers/event_category";
import { PLMJPLUSMapper } from "../../mappers/pages_custom/PLMJPLUSMapper";
import { AgendaMapper } from "../../mappers/pages_custom/AgendaMapper";
import { WhoIsWhoMapper } from "../../mappers/pages_custom/WhoIsWhoMapper";
import { CoreRepositoryInterface } from "../../repositories/core";
import { EntityRepository } from "../../repositories/entity";
import { UserProfileMapper } from "../../mappers/user_profile/UserProfileMapper";
import { StorageRepository } from "../../repositories/storage";
import { CityMapper } from "../../mappers/city/CityMapper";
import { SetupTeamsMapper } from "../../mappers/pages_custom/SetupTeamsMapper";
import { TeamsMapper } from "../../mappers/teams/TeamsMapper";
import { TeamsRepository } from "../../repositories/teams";
import { SetupBroadcastMapper } from "../../mappers/pages_custom/SetupBroadcastMapper";
import { JobTitleMapper } from "../../mappers/job_title/JobTitleMapper";
import { OrderJobsMapper } from "../../mappers/job_title/OrderJobsMapper";
import { JobTitleRepository } from "../../repositories/jobTitle";
import { ApplicationMapper } from "../../mappers/application/ApplicationMapper";
import { KioskItemMapper } from "../../mappers/kiosk_item/KioskItemMapper";

export interface ResourceItemInterface {
    search: string;
    sort: string[];
    responseRef: string;
    requestRef: string;
    collection: string;
    storagePath?: string;
    requestKind: string;
    mapper: Mapper<any>;
    repository: CoreRepositoryInterface;
    defaultListSort: { field: string, order: string }
}
interface ResourceServiceInterface {
    [key: string]: ResourceItemInterface;
}

const defaultListSort = { field: 'updatedDate', order: 'desc' };

const SORTABLE_DEFAULT = ["creationDate", "updatedDate"];
export const ResourceService: ResourceServiceInterface = {
    post: {
        search: "searchPosts",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: 'postRef',
        requestRef: 'postRef',
        collection: Schemas.POSTS.collection,
        storagePath: 'posts',
        mapper: new PostMapper(),
        requestKind: 'post',
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    tag: {
        search: "searchTags",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: 'tagRef',
        requestRef: 'tagRef',
        collection: Schemas.TAGS.collection,
        mapper: new TagMapper(),
        requestKind: 'tag',
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    page: {
        search: "searchPages",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: 'pageRef',
        requestRef: 'pageRef',
        storagePath: 'pages',
        collection: Schemas.PAGES.collection,
        mapper: new PageMapper(),
        requestKind: 'page',
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    partnership_category: {
        search: "searchPartnershipCategory",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: 'partnershipCategoryRef',
        requestRef: 'partnershipCategoryRef',
        collection: Schemas.PARTNERSHIP_CATEGORY.collection,
        mapper: new PartnershipCategoryMapper(),
        requestKind: 'partnership_category',
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    partnership: {
        search: "searchPartnerships",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: "partnershipRef",
        requestRef: "partnershipRef",
        storagePath: "partnership",
        collection: Schemas.PARTNERSHIPS.collection,
        mapper: new PartnershipMapper(),
        requestKind: 'partnership',
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    event: {
        search: "searchEvents",
        sort: [...["startDate", "endDate"], ...SORTABLE_DEFAULT],
        responseRef: 'postRef',
        requestRef: 'postRef',
        collection: Schemas.POSTS.collection,
        storagePath: 'posts',
        mapper: new EventMapper(),
        requestKind: 'post',
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    highlighted_event: {
        search: "searchHighlightedEvents",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: "highlightedEventRef",
        requestRef: "highlightedEventRef",
        storagePath: "highlightedEvent",
        collection: Schemas.HIGHLIGHTED_EVENTS.collection,
        mapper: new HighlightedEventMapper(),
        requestKind: "highlighted_event",
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    event_category: {
        search: "searchTags",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: 'tagRef',
        requestRef: 'tagRef',
        collection: Schemas.TAGS.collection,
        mapper: new EventCategoryMapper(),
        requestKind: 'tag',
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    pages_plmj_homepage: {
        search: "searchPagesCustom",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: "Ref",
        requestRef: "homepageRef",
        storagePath: "pages_custom",
        collection: Schemas.PAGES_CUSTOM.collection,
        mapper: new HomepageMapper(),
        requestKind: "homepagesetup",
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    pages_plmj_plus: {
        search: "searchPagesCustom",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: "Ref",
        requestRef: "plmjplusRef",
        storagePath: "pages_custom",
        collection: Schemas.PAGES_CUSTOM.collection,
        mapper: new PLMJPLUSMapper(),
        requestKind: "plmjplussetup",
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    pages_plmj_agenda: {
        search: "searchPagesCustom",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: "Ref",
        requestRef: "agendaRef",
        storagePath: "pages_custom",
        collection: Schemas.PAGES_CUSTOM.collection,
        mapper: new AgendaMapper(),
        requestKind: "agendasetup",
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    pages_plmj_whoiswho: {
        search: "searchPagesCustom",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: "Ref",
        requestRef: "whoiswhoRef",
        storagePath: "pages_custom",
        collection: Schemas.PAGES_CUSTOM.collection,
        mapper: new WhoIsWhoMapper(),
        requestKind: "whoiswhosetup",
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    pages_plmj_teams: {
        search: "searchPagesCustom",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: "Ref",
        requestRef: "teamsRef",
        storagePath: "pages_custom",
        collection: Schemas.PAGES_CUSTOM.collection,
        mapper: new SetupTeamsMapper(),
        requestKind: "teamssetup",
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    broadcast: {
        search: "searchPosts",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: "postRef",
        requestRef: "postRef",
        collection: Schemas.POSTS.collection,
        storagePath: 'posts',
        mapper: new BroadcastMapper(),
        requestKind: 'post',
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    broadcast_category: {
        search: "searchTags",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: 'tagRef',
        requestRef: 'tagRef',
        collection: Schemas.TAGS.collection,
        mapper: new BroadcastCategoryMapper(),
        requestKind: 'tag',
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    user_profile: {
        search: "searchUserPhotos",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: 'storageRef',
        requestRef: 'photoRef',
        collection: Schemas.USER_PROFILE.collection,
        mapper: new UserProfileMapper(),
        requestKind: 'profilePictures',
        repository: StorageRepository,
        defaultListSort: defaultListSort
    },
    city: {
        search: "searchCities",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: 'cityRef',
        requestRef: 'cityRef',
        collection: Schemas.CITIES.collection,
        mapper: new CityMapper(),
        requestKind: 'city',
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    teams: {
        search: "searchDepartments",
        sort: [...[]],
        responseRef: 'teamsRef',
        requestRef: 'teamsRef',
        collection: Schemas.TEAMS.collection,
        mapper: new TeamsMapper(),
        requestKind: 'teams',
        repository: TeamsRepository,
        defaultListSort: { field: "name", order: "asc" }
    },
    pages_plmj_broadcast: {
        search: "searchPagesCustom",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: "Ref",
        requestRef: "broadcastRef",
        storagePath: "pages_custom",
        collection: Schemas.PAGES_CUSTOM.collection,
        mapper: new SetupBroadcastMapper(),
        requestKind: "broadcastsetup",
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    job_title: {
        search: "searchJobs",
        sort: [...[]],
        responseRef: 'jobTitleRef',
        requestRef: 'jobTitleRef',
        collection: Schemas.JOBTITLES.collection,
        mapper: new JobTitleMapper(),
        requestKind: 'job_title',
        repository: EntityRepository,
        defaultListSort: defaultListSort
    },
    job_title_settings: {
        search: "searchJobs",
        sort: [...[], ...SORTABLE_DEFAULT],
        responseRef: "Ref",
        requestRef: "noRef",
        storagePath: "noPath",
        collection: Schemas.JOBTITLES_SETTINGS.collection,
        mapper: new OrderJobsMapper(),
        requestKind: "nokind",
        repository: JobTitleRepository,
        defaultListSort: defaultListSort
    },
    application: {
        search: "searchApplications",
        sort: [...[]],
        responseRef: 'applicationRef',
        requestRef: 'applicationRef',
        collection: Schemas.APPLICATIONS.collection,
        mapper: new ApplicationMapper(),
        requestKind: 'application',
        repository: EntityRepository,
        defaultListSort: defaultListSort,
        storagePath: "applications",
    },
    kiosk_item: {
        search: "searchKioskItems",
        sort: [...[]],
        responseRef: 'kioskItemRef',
        requestRef: 'kioskItemRef',
        collection: Schemas.KIOSK_ITEMS.collection,
        mapper: new KioskItemMapper(),
        requestKind: 'kiosk_item',
        repository: EntityRepository,
        defaultListSort: defaultListSort,
        storagePath: "kiosk_items",
    },
}