import styles from "./index.module.css"

export type TextProps = {
    className?: string
    center?: boolean
    noMargin?: boolean
    style?: any
    onClick?: any
    children: any
}

export const Text: React.FC<TextProps> = (props: TextProps) => {
    const centerText = () => {
        return props.center ? styles.center : ''
    }

    const getMargin = () => {
        return props.noMargin ? styles.noMargin : ''
    }

    const getStyle = (): string => {
        return styles.text
    }

    const classNames = [
        getStyle(),
        getMargin(),
        centerText(),
        props.className
    ];

    return (
        <span className={classNames.join(' ')} style={props.style} onClick={props.onClick}>
            {props.children}
        </span>
    );
}

