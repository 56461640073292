import { UpdateParams } from "react-admin";
import { JobTitle } from "../../model/jobTitle/JobTitle";
import { DocumentData, DocumentSnapshot } from "../../services/persistence";
import { EntityMapper } from "../core/EntityMapper";

type valueObject = {job: string}
export class JobTitleMapper extends EntityMapper<JobTitle> {
    
    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data } = params
        return Object.assign(
            {},
            super.toFirestore(params),
            this.toFirestoreField(data, 'name'),
            data.values ? { values: data.values.map((value:valueObject) => value.job) } : {},
        ) as DocumentData;
    }

    fromFirestore(snapshot: DocumentSnapshot): JobTitle {
        const data = snapshot.data();

        return Object.assign(
            new JobTitle(),
            super.fromFirestore(snapshot),
            this.fromFirestoreField(data, 'name'),
            data?.values ? { values: data.values.map((value: string) => ({job: value})) } : {}
        );
    }
}