import { UserIdentity } from "ra-core";

export class AuthContainer {
    static user: UserIdentity | undefined = undefined

    static getUser() {
        return AuthContainer.user
    }

    static setUser(user: UserIdentity) {
        AuthContainer.user = user
    }

}