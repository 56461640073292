import React from 'react';
import { List, ListProps } from 'react-admin';
import { PageListSection } from '../../../sections/PagetList';
import { PageActions } from '../actions';
import { PageFilter } from '../filter'
import styles from './index.module.css';

export const PageList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<PageActions />}
            filters={<PageFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <PageListSection />
        </List>
    )
}

