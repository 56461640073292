import { Create, SimpleForm, TextInput, TopToolbar } from "ra-ui-materialui";
import ColorInput from "../../../components/ColorInput";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import styles from "./index.module.css";

const validateEventCategoryCreate = (values: any) => {
    const errors: any = {};

    if (!values.name) {
        errors.name = i18n.t("event_category.create.errors.name");
    }

    if (!values.description) {
        errors.description = i18n.t("event_category.create.errors.description");
    }

    if (!values.color) {
        errors.color = i18n.t("event_category.create.errors.color");
    }

    return errors
};

const EventCategoryCreateActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.event")} - {i18n.t("sidebar.tags")} </PageTitle>
        </TopToolbar>
    );
};

export const EventCategoryCreate = (props: any) => {
    const redirect = (_: string, id: string) => `/event_category/${id}`;

    return (
        <Create mutationMode="pessimistic" {...props} actions={<EventCategoryCreateActions />} className={"mid-container"}>

            <SimpleForm validate={validateEventCategoryCreate} redirect={redirect}>
                <TextInput source="name" label={i18n.t("event_category.create.name")} />
                <TextInput source="description" label={i18n.t("event_category.create.description")} />
                <ColorInput source="color" label={i18n.t("event_category.create.color")} />
            </SimpleForm>
        </Create>
    )
};
