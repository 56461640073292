import { Box, Grid } from "@material-ui/core";
import i18n from '../../../../language/i18n';
import { TextInput } from 'ra-ui-materialui';
import { SelectInput } from 'react-admin';

export enum PageVideoType {
    Youtube = "Youtube",
    Microsoft = "Microsoft",
    Others = "Outros"
}

export const VideoModule = ({ id }: any) => {
    const sectionId = id && id.substring(id.indexOf("[") + 1, id.lastIndexOf("]"));
    const alignmentOptions = [
        { id: 'flex-end', name: i18n.t("module.pageSection.structure.image.align.end") },
        { id: 'flex-start', name: i18n.t("module.pageSection.structure.image.align.start") },
        { id: 'center', name: i18n.t("module.pageSection.structure.image.align.center") }
    ];

    const widthOptions = [
        { id: '100', name: i18n.t("module.pageSection.video.width_options.100") },
        { id: '50', name: i18n.t("module.pageSection.video.width_options.50") },
    ]

    return (
        <>
            <Box display={"flex"} flexDirection={"column"}>
                <Grid container spacing={2} style={{ flexGrow: 1 }}>
                    <Grid item xs={4} sm={4} lg={4} xl={4}>
                        <SelectInput
                            source={`sections[${sectionId}].video.provider`}
                            label={i18n.t("module.pageSection.video.type")}
                            choices={[
                                { id: 'microsoft', name: PageVideoType.Microsoft },
                                { id: 'youtube', name: PageVideoType.Youtube },
                                { id: 'others', name: PageVideoType.Others },
                            ]}
                            optionValue={"id"}
                            defaultValue={"youtube"}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} lg={4} xl={4}>
                        <SelectInput
                            source={`sections[${sectionId}].video.size`}
                            label={i18n.t("module.pageSection.video.width")}
                            choices={widthOptions}
                            defaultValue={"100"}
                            optionValue={"id"}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} lg={4} xl={4}>
                        <SelectInput
                            source={`sections[${sectionId}].video.align`}
                            label={i18n.t("module.pageSection.video.alignment")}
                            choices={alignmentOptions}
                            defaultValue={"center"}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <TextInput 
                            source={`sections[${sectionId}].video.link`}
                            label={i18n.t("module.pageSection.video.link")}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}