export const Schemas = {
    APPLICATIONS: {
        collection: "applications"
    },
    USERS: {
        collection: 'users'
    },
    REQUEST: {
        collection: 'request'
    },
    POSTS: {
        collection: 'posts'
    },
    TAGS: {
        collection: 'tags'
    },
    PAGES: {
        collection: 'pages'
    },
    PARTNERSHIP_CATEGORY: {
        collection: 'partnership_category'
    },
    PARTNERSHIPS: {
        collection: "partnership"
    },
    HIGHLIGHTED_EVENTS: {
        collection: "highlighted_event"
    },
    PAGES_CUSTOM: {
        collection: "pages_custom"
    },
    USER_PROFILE: {
        collection: "profilePictures"
    },
    CITIES: {
        collection: "cities"
    },
    TEAMS: {
        collection: "departments"
    },
    JOBTITLES: {
        collection: "job_titles"
    },
    JOBTITLES_SETTINGS: {
        collection: "settings"
    },
    KIOSK_ITEMS: {
        collection: "kiosk_items"
    }
}