import {
    Edit,
    EditProps,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    TopToolbar,
} from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";

import styles from "./index.module.css";

const WhoIsWhoToolbar = (props: EditProps) => (
    <Toolbar {...(props as any)}>
        <SaveButton redirect={false} />
    </Toolbar>
);

const WhoIsWhoActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.whoiswho")} </PageTitle>
        </TopToolbar>
    );
};

export const SetupWhoIsWho = (props: EditProps) => {
    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            actions={<WhoIsWhoActions />}
            className={"mid-container"}
        >
            <SimpleForm toolbar={<WhoIsWhoToolbar {...props} />}>
                <TextInput source={"title"} label={i18n.t("whoiswho.title")} />
                <TextInput source={"subtitle"} label={i18n.t("whoiswho.subtitle")} />
            </SimpleForm>
        </Edit>
    );
};
