import { Entity } from "../core/Entity";

export class KioskItem extends Entity{
    public name?: string;
    public link?: string;
    public cover?: string;
    public recurrency?: string;
    public emailAccount?: string;
    public passwordAccount?: string;

    static template() {
        return Object.assign(
            {},
            { name: TEMPLATE_NAME },
            { link: TEMPLATE_LINK },
            { recurrency: TEMPLATE_RECURRENCY},
            { emailAccount:TEMPLATE_EMAIL_ACCOUNT},
            { passwordAccount: TEMPLATE_PASSWORD_ACCOUNT}
        ) as KioskItem
    }
}
export const TEMPLATE_NAME = 'Kiosk_Item Template'
export const TEMPLATE_LINK = 'Kiosk_Item Template Link'
export const TEMPLATE_RECURRENCY = 'daily'
export const TEMPLATE_EMAIL_ACCOUNT = 'Kiosk_Item Template Email Account'
export const TEMPLATE_PASSWORD_ACCOUNT = 'Kiosk_Item Template PasswordAccount'
export const KIOSK_ITEM_KIND = 'kiosk_item'