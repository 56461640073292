import { TextField, SimpleList, DatagridBodyProps, Record, useRedirect } from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { DateService } from "../../services/date";
import { Table } from "../Table";
import { SubTitle } from "../SubTitle";
import { Title } from "../Title";
import i18n from '../../language/i18n';
import { format } from 'date-fns';

export const HighlightedEventListSection = () => {
    const getTitle = (name: string) => {
        return (
            <Title>
                {name}
            </Title>
        );
    }

    const getSubTitle = (updatedDate: string | Date) => {

        let date
        if (typeof updatedDate === 'string') {
            date = new Date(updatedDate);
        } else {
            date = updatedDate;
        }

        const [interval, unit] = DateService.toInterval(date);

        return (
            <SubTitle>
                {i18n.t("default.list.lastUpdate")}: {interval} {i18n.t(`default.list.unit.${unit}`)}
            </SubTitle>
        );
    }
    const renderDate = (date: Date) => {
        return date ? format(new Date(date), 'dd MMM yy, HH:mm') : '---'
    }

    const HighlightedEventRowConfig = ({ record, id }: DatagridBodyProps) => {
        const redirect = useRedirect();

        if (record === undefined) {
            return null;
        }


        return (
            <TableRow key={id} onClick={() => redirect('edit', '/highlighted_event', id)}>
                <TableCell
                    key={`${id}-name`}
                    size={'medium'}

                >
                    {getTitle(record.title)}
                    <br />
                    {getSubTitle(record.updatedDate)}
                </TableCell>
                <TableCell
                    key={`${id}-startDate`}
                    size={'medium'}
                >
                    {renderDate(record.startDate)}
                </TableCell>
                <TableCell
                    key={`${id}-endDate`}
                    size={'medium'}
                >
                    {renderDate(record.endDate)}
                </TableCell>
            </TableRow>
        );
    };

    const HighlightedEventResponsiveTable = () => {
        return (
            <SimpleList
                primaryText={(record: Record) => getTitle(record.event)}
                secondaryText={(record: Record) => getSubTitle(record.updatedDate)}
                linkType={record => "edit"}
            />
        );
    }

    return (
        <Table rowConfig={<HighlightedEventRowConfig />} responsiveList={<HighlightedEventResponsiveTable />}>
            <TextField source="event" label={i18n.t('highlighted_event.list.header.name')} sortable={false} />
            <TextField source="startDate" label={i18n.t('highlighted_event.list.header.startDate')} sortable={true} />
            <TextField source="endDate" label={i18n.t('highlighted_event.list.header.endDate')} sortable={true} />
        </Table>
    );
}
