import { Entity } from "../core/Entity";

export class Application extends Entity {
    public title?: string;
    public cover?: any;
    public link?: string;

    static template() {
        return Object.assign(
            {},
            { title: TEMPLATE_TITLE },
            { link: TEMPLATE_LINK }
        ) as Application
    }
}

export const APPLICATION_KIND = 'application'
export const TEMPLATE_TITLE = 'App Template'
export const TEMPLATE_LINK = 'App Template Link'