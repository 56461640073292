import {
    Create,
    SimpleForm,
    TextInput,
    TopToolbar, ImageField, ImageInput
} from "ra-ui-materialui";
import { useNotify } from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import styles from "./index.module.css";

const validateProfileCreate = (values: any) => {
    const errors: any = {};

    if (values.filename === undefined) {
        errors.filename = i18n.t("whoiswho_profiles.file.error");
    }

    if (values.image === undefined) {
        errors.image = i18n.t("whoiswho_profiles.image.error");
    }

    return errors;
};

const UsersProfileCreateActions = ({ data, resource }: any) => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle>
                {i18n.t("sidebar.whoiswho_profiles")}
            </PageTitle>
        </TopToolbar>
    );
};

export const UserProfileCreate = (props: any) => {
    const notify = useNotify();

    const onSuccess = () => {
        notify(`${i18n.t("whoiswho_profiles.create.success")}`);
    };

    return (
        <Create
            mutationMode="pessimistic"
            className={"mid-container"}
            onSuccess={onSuccess}
            actions={<UsersProfileCreateActions />}
            {...props}
        >
            <SimpleForm validate={validateProfileCreate} redirect={false}>
                <TextInput 
                    source="filename" 
                    label={i18n.t("whoiswho_profiles.file.name")} 
                    helperText={i18n.t("whoiswho_profiles.file.helper")}
                    required
                />
                <ImageInput
                    source="image"
                    accept="image/jpg,image/jpeg,image/png"
                    label={`${i18n.t("whoiswho_profiles.image.label")}`}
                    isRequired
                >
                    <ImageField source="src" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};
