
import Card from '@material-ui/core/Card';

export default function AccessDeniedPage(_: any) {

    return (
        <Card>
            <span>Sem Permissões</span>
        </Card>
    )

}