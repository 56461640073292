import { Edit, SimpleForm, TextInput, TopToolbar } from "ra-ui-materialui";
import { ImageField, ImageInput, EditProps, DeleteWithConfirmButton, SaveButton, Toolbar, useEditController } from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import styles from "./index.module.css";

const validateApplicationEdit = (values: any) => {
    const errors: any = {};

    if (!values.title) {
        errors.title = i18n.t("application.edit.errors.title");
    }

    if (!values.cover) {
        errors.cover = i18n.t("application.edit.errors.cover");
    }

    if (!values.link) {
        errors.link = i18n.t("application.edit.errors.link");
    }

    return errors
};

const ApplicationEditActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.applications.title")} </PageTitle>
        </TopToolbar>
    );
};



export const ApplicationEdit = (props: EditProps) => {
    const { record, save } = useEditController(props);

    const ApplicationEditToolbar = (props: any) => {    
        return (
            <Toolbar className={styles.bottomToolbar} {...props}>
                <SaveButton handleSubmitWithRedirect={(values: any) => save(values)} {...props}/>
                <DeleteWithConfirmButton confirmTitle="Remover Aplicação" record={record}/>
             </Toolbar>
        );
    };

    return (
        <Edit mutationMode="pessimistic" {...props} className={"mid-container"} actions={<ApplicationEditActions />}>
            <SimpleForm validate={validateApplicationEdit} redirect={false} toolbar={<ApplicationEditToolbar props={props}/>} >
                <TextInput source="title" label={i18n.t("application.edit.title")} fullWidth={true} required={true} />
                <TextInput source="link" label={i18n.t("application.edit.link")} fullWidth={true} required={true} />
                <div className={styles.coverImageContainer}>
                    <ImageInput
                        source="cover"
                        accept="image/*"
                        label={`${i18n.t("application.edit.cover")}`}
                        isRequired={true}
                    >
                        <ImageField source="src" className={styles.cover} />
                    </ImageInput>
                </div>
            </SimpleForm>
        </Edit>
    );
};
