import { Box } from "@material-ui/core";
import { BooleanInput, ImageField, ImageInput, NumberInput, SelectInput, TextInput } from "ra-ui-materialui";
import { useFormState } from 'react-final-form';
import RichTextInput from "../../../../components/richTextInput";
import i18n from '../../../../language/i18n';
import { Title } from '../../../../sections/Title';

export const StructureModule = ({ id }: any) => {
    const { values } = useFormState();
    const sectionId = id.substring(id.indexOf("[") + 1, id.lastIndexOf("]"));
    const sectionType = values && values.sections && values.sections[sectionId].type;
    const photoAlignmentOptions = [
        { id: 'flex-end', name: i18n.t("module.pageSection.structure.image.align.end") },
        { id: 'flex-start', name: i18n.t("module.pageSection.structure.image.align.start") },
        { id: 'center', name: i18n.t("module.pageSection.structure.image.align.center") }
    ];

    function renderSeparatorInput() {
        return (<>
            <Title> {i18n.t(`module.pageSection.structure.title.separator`)} </Title>
            <BooleanInput style={{ display: 'none' }} source={`${id}.separator[0].exists`} label={""} defaultValue={true} />
        </>)
    }

    function renderSpacingInput() {
        return (<>
            <Title> {i18n.t(`module.pageSection.structure.title.spacing`)} </Title>
            <NumberInput source={`${id}.spacing[0].value`} label={i18n.t("module.pageSection.structure.spacing.value")} min={1} validate={value => value >= 1 ? undefined : "Valor tem de ser maior do que 0"} />
        </>)
    }

    function renderTextInput(sourceName?: string, index?: number) {
        return (<>
            <Title> {i18n.t(`module.pageSection.structure.title.text`)} </Title>
            <RichTextInput
                source={`${id}.${sourceName}[${index}].body`}
                initialValue={null}
                label={""}
                toolbar={[
                    [{ 'header': [1, 2, 3, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['link'],
                    [{ 'align': [] }]
                ]}
            />
        </>)
    }

    function renderImageInput(sourceName?: string, index?: number) {
        return (<>
            <Title> {i18n.t(`module.pageSection.structure.title.image`)} </Title>
            <ImageInput
                source={`${id}.${sourceName}[${index}].src`}
                accept="image/*"
                multiple={false}
                label=""
                labelMultiple=""
            >
                <ImageField source={"src"} />
            </ImageInput>
            <TextInput source={`${id}.${sourceName}[${index}].link`} label={`${i18n.t("module.pageSection.structure.link")}`} />
            {sourceName === 'image' &&
                <>
                    <NumberInput
                        source={`${id}.${sourceName}[${index}].width`}
                        label={i18n.t("module.pageSection.structure.image.width")}
                        max={100}
                        min={1}
                        validate={value => value >= 1 && value <= 100 ? undefined : i18n.t("module.pageSection.structure.image.error")}
                    />
                    <SelectInput
                        source={`${id}.${sourceName}[${index}].align`}
                        label={i18n.t("module.pageSection.structure.image.alignment")}
                        choices={photoAlignmentOptions}
                        defaultValue={"center"}
                    />
                </>
            }
        </>)
    }

    const inputs: any[] = []
    switch (sectionType) {
        case "structure_image":
            inputs.push(renderImageInput("image", 0))
            break;
        case "structure_text_text":
            inputs.push(renderTextInput("textText", 0))
            inputs.push(renderTextInput("textText", 1))
            break;
        case "structure_image_text":
            inputs.push(renderImageInput("imageText", 0))
            inputs.push(renderTextInput("imageText", 1))
            break;
        case "structure_text_image":
            inputs.push(renderTextInput("textImage", 0))
            inputs.push(renderImageInput("textImage", 1))
            break;
        case "structure_image_image":
            inputs.push(renderImageInput("imageImage", 0))
            inputs.push(renderImageInput("imageImage", 1))
            break;
        case "structure_separator":
            inputs.push(renderSeparatorInput())
            break;
        case "structure_spacing":
            inputs.push(renderSpacingInput())
            break;
        default:
            break;
    }

    return (
        <>
            <Box display={"flex"} flexDirection={"column"}>
                {inputs}
            </Box>
        </>
    )
}