import { Entity } from "../core/Entity";
import { PostStatus } from "../posts/PostStatus";

export class Event extends Entity {
    public title?: string
    public subTitle?: string
    public body?: string
    public instructions?: string
    public images?: Array<string>;
    public status?: PostStatus;
    public tags?: Array<any>;
    public lead?: string
    public usefulLinks?: Array<string>
    public type?: "event"
    public startDate?: Date
    public endDate?: Date
    public location?: EventLocation
    public phone?: string
    public email?: string
    public isPrivate?: boolean
    public showOrganizers?: boolean
    public organizers?: Array<EventOrganizer>
    public attendees?: Array<string>

    static templateEvent() {
        return Object.assign(
            {},
            { title: 'Título do Evento' },
            {},
            {},
            { isPrivate: false },
            { showOrganizers: false },
            { startDate: new Date() },
            { endDate: new Date() },
            { type: 'event' }
        ) as Event
    }

}

export type EventLocation = {
    address?: string
    name?: string
}

export type EventOrganizer = {
    name?: string
    phone?: string
    email?: string
    photo?: string
    id?: string
}
export const EVENT_KIND = "event"