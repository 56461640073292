import { Card } from 'antd';
import React from 'react'

import styles from './index.module.css';
import { Post, DEFAULT_MS_THUMB } from '../../../../model/post/Post';
import moment from 'moment';
import CategoryTitle from '../../../base/categoryTitle';
import genericThumbnailPodcast from '../../../../assets/images/jpeg/broadcast/thumbnail_podcast.jpg'
import genericThumbnailVideo from '../../../../assets/images/jpeg/broadcast/thumbnail_video.png'
import Meta from 'antd/lib/card/Meta';
import i18n from '../../../../../../language/i18n';

export interface PostCardProps {
    post: Post;
    onPostClick?: () => void;
    className?: string;
}

function PostCard({ post, onPostClick, className }: PostCardProps) {
    const translateType = (type?: string) => {
        return i18n.t(`post.type.${type}`);
    }

    const tag = post?.tags?.length && post?.tags[0].name

    const getBackground = () => {
        if (post.type === "video")  {
            return getThumbnail(genericThumbnailVideo, post.video?.thumbnail);
        }

        if (post.type === "podcast") {
            return getThumbnail(genericThumbnailPodcast, post.podcast?.thumbnail);
        }

        return post.images && post.images[0];
    }

    const getThumbnail = (defaultThumbnail: string, thumbnail?: string ) => {
        if (thumbnail === undefined || thumbnail === null) {
            return defaultThumbnail;
        }

        if (thumbnail === DEFAULT_MS_THUMB) {
            return defaultThumbnail;
        }
        
        return thumbnail;
    }

    const isVideoOrPodcast = () => {
        return post.type === "video" || post.type === "podcast"
    }

    return (
        <Card
            className={[styles.cardContainer, ...(isVideoOrPodcast() ? [styles.cardThumb] : []) ,className].join(' ')}
            cover={
                <img
                    alt={post.title}
                    className={styles.cover}
                    src={getBackground()}
                />
            }
            bordered={false}
            onClick={() => onPostClick && onPostClick()}
        >
            <CategoryTitle className={styles.tagTitle}>
                {post.type === undefined ? tag : `${translateType(post.type)} | ${tag}`}
            </CategoryTitle>
            <Meta title={post.title} />
            <CategoryTitle className={styles.lastUpdated}>
                {moment(post?.updatedDate).format("DD/MM/yyyy")}
            </CategoryTitle>
        </Card>
    );
}

export default PostCard;