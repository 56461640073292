import { List, ListProps } from 'react-admin';
import { KioskItemListSection } from '../../../sections/KioskItemList';
import { KioskItemActions } from '../actions';
import { KioskItemFilter } from '../filter';


import styles from './index.module.css';

export const KioskItemList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<KioskItemActions />}
            filters={<KioskItemFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <KioskItemListSection />
        </List>
    )
}
