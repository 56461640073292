import { TextProps, Text } from "../text";
import styles from "./index.module.css";

export type PopperFormHeaderProps = {
    children: React.ReactNode
} & TextProps;

export const PopperFormHeader: React.FC<PopperFormHeaderProps> = (props: PopperFormHeaderProps) => {
    return (
        <Text className={styles.formHeader}>
            <p>{props.children}</p>
        </Text>
    );
}

