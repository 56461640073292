import { UpdateParams } from "ra-core";
import { Schemas } from "../../model/core/Schemas";
import { Homepage } from "../../model/pagesCustom/Homepage";
import { DocumentData, DocumentSnapshot, firestore } from "../../services/persistence";
import { ResourceService } from "../../services/resource";
import { StorageService } from "../../services/storage/StorageService";
import { EntityMapper } from "../core/EntityMapper";

export class HomepageMapper extends EntityMapper<Homepage>{

    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data, id } = params
        const service = ResourceService.pages_plmj_homepage;


        const startDate = new Date(data.startDate)
        const endDate = new Date(data.endDate)

        if (data.songLink === null) {
            data.songLink = "";
        }

        const res = Object.assign(
            {},
            super.toFirestore(params),
            this.toFirestoreField(data, "myKMHubLink"),
            this.toFirestoreField(data, "menu"),
            data.startDate ? { startDate } : {},
            data.endDate ? { endDate } : {},
            this.toFirestoreField(data, "songName"),
            this.toFirestoreField(data, "songArtist"),
            this.toFirestoreField(data, "songSuggestedBy"),
            this.toFirestoreField(data, "songLink"),
            this.toFirestoreField(data, "knowledgeSection"),
            data.homepageApps ? { homepageApps: data.homepageApps.map((ref: any) => firestore.doc(`${Schemas.APPLICATIONS.collection}/${ref}`)) } : {},
            data.headerApps ? { headerApps: data.headerApps.map((ref: any) => firestore.doc(`${Schemas.APPLICATIONS.collection}/${ref}`)) } : {},
            data.kioskItems ? { kioskItems: data.kioskItems.map((ref: kioskItem) => firestore.doc(`${Schemas.KIOSK_ITEMS.collection}/${ref}`)) } : {},
            this.toFirestoreField(data, "background"),
            this.toFirestoreField(data, "autoRotate"),
        ) as DocumentData

        if (data.background && data.background.src && !data.background.rawFile) {
            res.background = data.background.src
        }

        if (
            (data.background !== undefined && this.isNewImage(data.background)) ||
            data.background === null
        ) {
            // If the image is changed, the current one must be deleted
            // Either if it's an actual deletion, or a change of photo
            await StorageService.deleteFolder(
                id as string,
                service,
                "backgroundImage"
            );

            let imageLink = null;
            if (data.background !== null) {
                // If image is not null, photo was changed
                imageLink = await this.uploadbackgroundImage(
                    data.background,
                    id as string
                );
            }

            res.background = imageLink;
        }
        if (data.menu && data.menu.src && !data.menu.rawFile) {
            res.menu = data.menu.src
        }

        if (
            (data.menu !== undefined && this.isNewImage(data.menu)) ||
            data.menu === null
        ) {
            // If the image is changed, the current one must be deleted
            // Either if it's an actual deletion, or a change of photo
            await StorageService.deleteFolder(
                id as string,
                service,
                "menu"
            );

            let imageLink = null;
            if (data.menu !== null) {
                // If image is not null, photo was changed
                imageLink = await this.uploadMenuImage(
                    data.menu,
                    id as string
                );
            }

            res.menu = imageLink;
        }

        return res
    }

    fromFirestore(snapshot: DocumentSnapshot): Homepage {
        const data = snapshot.data()

        const res = Object.assign(
            new Homepage(),
            super.fromFirestore(snapshot),
            this.fromFirestoreField(data, "myKMHubLink"),
            this.fromFirestoreField(data, "menu"),
            data?.startDate ? { startDate: data?.startDate.toDate() } : {},
            data?.endDate ? { endDate: data?.endDate.toDate() } : {},
            this.fromFirestoreField(data, "songName"),
            this.fromFirestoreField(data, "songArtist"),
            this.fromFirestoreField(data, "songSuggestedBy"),
            this.fromFirestoreField(data, "songLink"),
            this.fromFirestoreField(data, "knowledgeSection"),
            data!.homepageApps ? { homepageApps: data!.homepageApps.map((ref: any) => ref.id) } : {},
            data!.headerApps ? { headerApps: data!.headerApps.map((ref: any) => ref.id) } : {},
            data!.kioskItems ? { kioskItems: data!.kioskItems.map((ref: kioskItem) => ref.id) } : {},
            this.fromFirestoreField(data, "background"),
            this.fromFirestoreField(data, "autoRotate"),
        );
        if (data!.background) {
            res.background = { src: data!.background } as any;
        }
        if (data!.menu) {
            res.menu = { src: data!.menu } as any;
        }
        return res
    }

    private isNewImage(image: any) {
        return image && image.rawFile && image.src;
    }

    private async uploadbackgroundImage(file: any, id: string) {
        let link;

        if (file !== undefined && file && file.rawFile) {
            link = await StorageService.uploadCustomFolderImage(
                file.rawFile,
                id as string,
                "backgroundImage",
                ResourceService["pages_plmj_homepage"]
            );
        }

        return link;
    }

    private async uploadMenuImage(file: any, id: string) {
        let link;

        if (file !== undefined && file && file.rawFile) {
            link = await StorageService.uploadCustomFolderImage(
                file.rawFile,
                id as string,
                "menuImage",
                ResourceService["pages_plmj_homepage"]
            );
        }

        return link;
    }
}



export type kioskItem = {
    id: string
}