import { UpdateParams } from "react-admin";
import { KioskItem } from "../../model/kiosk_item/KioskItem";
import { DocumentData, DocumentSnapshot } from "../../services/persistence";
import { ResourceService } from "../../services/resource";
import { StorageService } from "../../services/storage/StorageService";
import { EntityMapper } from "../core/EntityMapper";

export class KioskItemMapper extends EntityMapper<KioskItem> {
    
    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data, id } = params
        const obj = Object.assign(
            {},
            super.toFirestore(params),
            this.toFirestoreField(data, 'name'),
            this.toFirestoreField(data, 'link'),
            this.toFirestoreField(data, "recurrency"),
            this.toFirestoreField(data, "emailAccount"),
            this.toFirestoreField(data, "passwordAccount")
        ) as DocumentData;

        if ((data.cover !== undefined && this.isNewImage(data.cover)) || data.cover === null) {
            // If the image is changed, the current one must be deleted
            // Either if it's an actual deletion, or a change of photo
            await StorageService.deleteFolder(id as string, ResourceService.kiosk_item);

            if (data.image !== null) { // If image is not null, photo was changed
                await StorageService.uploadImage(data.cover, id as string, ResourceService.kiosk_item);
            }

            delete data.cover;
        }

        return obj;
    }

    fromFirestore(snapshot: DocumentSnapshot): KioskItem {
        const data = snapshot.data();

        const obj = Object.assign(
            new KioskItem(),
            super.fromFirestore(snapshot),
            this.fromFirestoreField(data, 'name'),
            this.fromFirestoreField(data, 'link'),
            this.fromFirestoreField(data, "recurrency"),
            this.fromFirestoreField(data, "emailAccount"),
            this.fromFirestoreField(data, "passwordAccount"),
        );

        if (data!.cover) {
            obj.cover = { src: data!.cover };
        }

        return obj;
    }

    private isNewImage(image: any) {
        return image && image.rawFile && image.src
    }
}