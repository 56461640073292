import { DateTime } from "luxon";

export const DateService = {
    toInterval: (date: Date) => {
        const isoDate = DateTime.fromISO(date.toISOString());
        const interval = isoDate.until(DateTime.now());
        const duration = interval.toDuration(['days','hours', 'minutes', 'seconds']).toObject();

        switch(true) {
            case DateService.isValidInterval(duration.days) === true:
                return [Math.trunc(duration.days!), 'days'];
            case DateService.isValidInterval(duration.hours) === true:
                return [Math.trunc(duration.hours!), 'hours'];
            case DateService.isValidInterval(duration.minutes) === true:
                return [Math.ceil(duration.minutes!), 'minutes'];
            case DateService.isValidInterval(duration.seconds) === true:
                return [Math.ceil(duration.seconds!), 'seconds'];
            default:
                return [];
        }
    },
    isValidInterval: (interval?: number) => {
        if (interval && interval > 0) {
            return true;
        }

        return false;
    }

}
