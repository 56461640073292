import { TextProps, Text } from "../../components/base/text";
import styles from "./index.module.css";

export type StatusTextProps = TextProps & {status: string}

export const StatusText = (props: StatusTextProps) => {
    const classes = [...[styles.status], ...[styles[props.status]]].join(' ');

    return (
        <Text className={classes}>
            {props.children}
        </Text>
    )
}
