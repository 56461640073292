import {
    Edit,
    EditProps,
    ImageField,
    ImageInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    TopToolbar,
} from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";

import styles from "./index.module.css";

const AgendaToolbar = (props: EditProps) => (
    <Toolbar {...(props as any)}>
        <SaveButton redirect={false} />
    </Toolbar>
);

const AgendaActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.agenda")} </PageTitle>
        </TopToolbar>
    );
};

export const SetupAgenda = (props: EditProps) => {
    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            actions={<AgendaActions />}
            className={"mid-container"}
        >
            <SimpleForm toolbar={<AgendaToolbar {...props} />}>
                <TextInput source={"title"} label={i18n.t("agenda.title")} />
                <TextInput source={"subtitle"} label={i18n.t("agenda.subtitle")} />
                <ImageInput
                    source="background"
                    accept="image/*"
                    multiple={false}
                    label={i18n.t("agenda.background")}
                    labelMultiple=""
                >
                    <ImageField source="src" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    );
};
