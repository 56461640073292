import { TeamsMapper } from "../../mappers/teams/TeamsMapper";
import { CoreError } from "../../model/core/CoreError";
import { ErrorCode } from "../../model/core/ErrorCode";
import { AuthContainer } from "../../services/authentication/AuthContainer";
import { FirestoreService } from "../../services/firestore/FirestoreService";
import { firestore } from "../../services/persistence";
import { ResourceItemInterface } from "../../services/resource";
import { CoreRepositoryInterface } from "../core";
import { EntityRepository } from "../entity";
import { Request } from "../../model/request/Request";
import { Schemas } from "../../model/core/Schemas";

export const TeamsRepository: CoreRepositoryInterface = {
    ...EntityRepository,
    getOne: async (id: string, collection: string, mapper: TeamsMapper) => {
        const teamData = await getTeams(id);

        if (teamData.data === undefined) {
            throw new CoreError(ErrorCode.NOT_EXISTS);
        }

        const object = mapper.fromFirestore(teamData.data);

        return {
            data: {
                id: id,
                ...object
            } as any
        };
    },
    update: async (params: any, service: ResourceItemInterface) => {
        const currentUser = AuthContainer.getUser()

        if (currentUser === undefined) {
            throw new CoreError(ErrorCode.UNAUTHENTICATED)
        }

        params.data = await service.mapper.toFirestore(params);
        const request = Request.fromDataProviderUpdate(service.requestKind, params, service)

        request.createdByRef = firestore.collection(Schemas.USERS.collection).doc(currentUser.id as string)
        await FirestoreService.onRequestSnapshot(request)

        const teamData = await getTeams(params.id);
        // const latestSnapshot = await firestore.collection(service.collection).doc(params.id as string).get()
        // This gives time for the update 
        // to be indexed in elastic search
        await timeout(4000);

        return teamData.data
    },
    delete: async (params: any, service: ResourceItemInterface) => {
        const currentUser = AuthContainer.getUser()

        if (currentUser === undefined) {
            throw new CoreError(ErrorCode.UNAUTHENTICATED)
        }

        const request = Request.fromDataProviderDelete(service.requestKind, params, service);
        request.createdByRef = firestore.collection(Schemas.USERS.collection).doc(currentUser.id as string)
        request.data = params.previousData;

        await FirestoreService.onRequestSnapshot(request)

        //This gives time for the deletion 
        //to be indexed in elastic search 
        //before redirecting to the listing page
        await timeout(2000);

        return;
    }
}

const getTeams = async (id: string) => {
    const collections = ["departmentsGovernance", "departmentsManagement", "departmentsPractice"];

    const teamsData = await Promise.all(collections.map(async (collection: string) => {
        const documentSnapshot = await firestore.collection(collection).doc(id).get();
        
        if (!documentSnapshot.exists) {
            return undefined;
        }

        return { data: documentSnapshot, collection: collection };
    }));

    const teamsResponse = teamsData.filter((data: any) => data !== undefined);
    return { data: teamsResponse[0]?.data, collection: teamsResponse[0]?.collection };
}

const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
        