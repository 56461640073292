import {
    Edit,
    SimpleForm,
    TextInput,
    TopToolbar,
} from "ra-ui-materialui";
import { ArrayInput, SimpleFormIterator, EditProps, DeleteWithConfirmButton, SaveButton, Toolbar, useEditController } from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import styles from "./index.module.css";

type ValidationProps={name: string, values: string[]}
type jobObject={job:string}

const validateJobTitleEdit = (values: ValidationProps) => {
    const errors: any = {};

    if (values.name === undefined) {
        errors.name = i18n.t("job_titles.create.error.name");
    }
    
    if (values.values === undefined||values.values.length < 1) {
        errors.values = i18n.t("job_titles.create.error.values");
    }

    return errors;
};

const validateArrayInput = (values: jobObject[]) => {
    if (values === undefined) {
        return undefined
    }
    const result = values.some((value: jobObject) => {
        return value === undefined || (value.job === undefined || value.job === "")
    })
    if (result) {
        return i18n.t("job_titles.create.error.values");
    }
    return undefined
}

const JobTitleActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle>
                {i18n.t("sidebar.job_titles")}
            </PageTitle>
        </TopToolbar>
    );
};

export const JobTitleEdit = (props: EditProps) => {
    const { record, save } = useEditController(props);

    const JobTitleToolbar = (props: any) => {
        if (record === undefined) {
            return null
        }
    
        return (
            <Toolbar className={styles.bottomToolbar} {...props}>
                <SaveButton handleSubmitWithRedirect={(values: any) => save(values)} {...props}/>
                <DeleteWithConfirmButton confirmTitle="Remover Estatuto" record={record} />
             </Toolbar>
        );
    };

    return (
        <Edit
            mutationMode="pessimistic"
            className={"mid-container"}
            actions={<JobTitleActions />}
            {...props}
        >
            <SimpleForm validate={validateJobTitleEdit} toolbar={<JobTitleToolbar props={props} />} >
                <TextInput
                    source={"name"}
                    label={i18n.t("job_title.create.name")}
                    style={{ width: "50%" }}
                    required
                />
                <ArrayInput
                    source={"values"}
                    label={i18n.t("module.values.input")}
                    validate={validateArrayInput}
                    required
                >
                    <SimpleFormIterator>
                        <TextInput
                            source={"job"}
                            label={i18n.t("job_title.create.name")}
                            required
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
};