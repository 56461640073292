import React from 'react';
import { List, ListProps } from 'react-admin';
import { EventListSection } from '../../../sections/EventList';
import { EventActions } from '../actions';
import { EventFilter } from '../filter';
import styles from './index.module.css';


export const EventList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<EventActions />}
            filters={<EventFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <EventListSection />
        </List>
    )
}

