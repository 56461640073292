import { Entity } from "../core/Entity"
import { PartnershipCategory } from "../partnership_category/PartnershipCategory"

export class Partnership extends Entity {
    public title?: string
    public description?: string
    public disabled?: boolean
    public image?: string
    public categories?: Array<PartnershipCategory>
    public link?: string
    public location?: string
    public discount?: number
    public email?: string
    public email_body?: string
    public instructions?: string
    public phone?: string

    static templatePartnership() {
        return Object.assign(
            {},
            { title: 'Título da Parceria' },
            { disabled: true }
        ) as Partnership
    }

}
export const PARTNERSHIP_KIND = 'partnership'