import { HighlightedEventCreate } from "./create";
import { HighlightedEventEdit } from "./edit";
import { HighlightedEventList } from "./list";

// eslint-disable-next-line
export default {
    list: HighlightedEventList,
    create: HighlightedEventCreate,
    edit: HighlightedEventEdit
};
