import React from 'react';
import { List, ListProps } from 'react-admin';
import { PartnershipListSection } from '../../../sections/PartnershipList';
import { PartnershipActions } from '../actions';
import { PartnershipFilter } from '../filter';
import styles from './index.module.css';

export const PartnershipList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<PartnershipActions />}
            filters={<PartnershipFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <PartnershipListSection />
        </List>
    )
}

