import styles from "./index.module.css";

export type ColorTextProps = { color: string }

export const ColorText = (props: ColorTextProps) => {

    return (
        <div className={styles.color} style={{ background: props.color }} />
    )
}
