import { Edit, SelectInput, SimpleForm, TextInput, TopToolbar } from "ra-ui-materialui";
import { useCallback, useEffect, useState } from 'react';
import { DeleteWithConfirmButton, FormDataConsumer, Loading, Record, SaveButton, Toolbar, useDataProvider, useEditController, useNotify, useRefresh } from 'react-admin';
import { Route, useHistory } from "react-router";
import { PageTitle } from '../../../components/pageTitle';
import RichTextInput from "../../../components/richTextInput";
import i18n from '../../../language/i18n';
import { PostStatus } from '../../../model/posts/PostStatus';
import { PostPublishButton } from "../../../sections/PostPublishButton";
import { PostPublishForm } from "../../../sections/PostPublishForm";
import { PostScheduleButton } from '../../../sections/PostScheduleButton';
import { PostScheduleForm } from '../../../sections/PostScheduleForm';
import { ResourceService } from '../../../services/resource';
import { StorageService } from '../../../services/storage/StorageService';
import { getPostTypes } from '../../../utils';
import { ImageModule } from '../modules/image';
import { RelatedPostsModule } from '../modules/relatedPosts';
import { UsefulLinksModule } from '../modules/usefulLinks';
import styles from './index.module.css';

const validatePostEdit = (values: any) => {
    const errors: any = {};
    if (!values.title) {
        errors.title = i18n.t("posts.edit.errors.title");
    }

    if (!values.body) {
        errors.body = i18n.t("posts.edit.errors.body");
    }

    return errors
};

const PostEditActions = ({ data, resource }: any) => {
    if (data === undefined) {
        return null
    }

    const { status } = data

    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.posts")} </PageTitle>
            <div>
                <PostPublishButton data={data} resource={resource} />
                {status === PostStatus.DRAFT && <PostScheduleButton data={data} />}
            </div>
        </TopToolbar>
    );
};

const TagInput = (props: any) => {
    return (
        <FormDataConsumer>
            {() => {
                const choices = props.choices ? props.choices.map((choice: any) => ({ id: choice.id, name: choice.name })) : []
                return (
                    <SelectInput
                        source="tags"
                        optionText="name"
                        choices={choices}
                    />
                );
            }}
        </FormDataConsumer >
    );
};

const TypesInput = () => {
    return (
        <SelectInput
            source="type"
            label={i18n.t("module.pageSection.carousel.postsType")}
            className={styles.input}
            choices={getPostTypes()}
        />
    )
}

const configureQuill = (quill: any, resource: string, id: any) => quill.getModule('toolbar')
    .addHandler('image', async function (_: any) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async function () {
            const file = input.files && input.files[0];

            if (file === null) {
                return;
            }

            const link = await StorageService.uploadBodyImage(file, id, ResourceService[resource]);
            const range = quill.getSelection()

            quill.insertEmbed(range.index, 'image', link);
            // eslint-disable-next-line
        }.bind(quill)
    });



export const PostEdit = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [post, setPost] = useState<Record | null>(null);
    const [postList, setPostList] = useState<object[] | null>(null);
    const [tags, setTags] = useState<object[] | undefined>(undefined);
    const dataProvider = useDataProvider();
    const history = useHistory();
    const notify = useNotify();
    const refresh = useRefresh();
    const { record, save } = useEditController(props);

    const PostEditToolbar = (props: any) => {
        if (record === undefined) {
            return null
        }
    
        return (
            <Toolbar className={styles.toolbar} {...props}>
                <SaveButton handleSubmitWithRedirect={(values: any) => save(values)} {...props}/>
                <DeleteWithConfirmButton confirmTitle="Remover Publicação" record={record} />
             </Toolbar>
        );
    };

    const fetchData = useCallback(async () => {
        try {
            const postRes = await dataProvider.getOne('post', { id: props.id });
            const tagsRes = await dataProvider.getList('tag', { filter: { searchText: "" }, pagination: { perPage: 1000, page: 1 } } as any);
            const postsRes = await dataProvider.getList('post', { filter: { searchText: "" }, pagination: { perPage: 1000, page: 1 } } as any);

            setPost(postRes.data);
            setTags(tagsRes.data);
            setPostList(postsRes.data.map((p: any) => { return Object.create({ id: p.id, name: p.title }) }));
            setLoading(false);
        } catch (e) {
            setPost(null);
            setLoading(false);
        }
    }, [dataProvider, props.id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    function onActionError() {
        history.push(`/post/${post!.id}`);
        notify(i18n.t("default.errors.post"), 'error')
    }

    async function onActionSuccess() {
        history.push(`/post/${post!.id}`);
        await fetchData()
        refresh(true)
    }

    function onActionCancel() {
        history.push(`/post/${post!.id}`);
    }

    if (loading === true) {
        return <Loading />
    };

    return (
        <Edit mutationMode="pessimistic" {...props} actions={<PostEditActions />} className={"mid-container"}>
            <SimpleForm
                validate={validatePostEdit}
                redirect={false}
                className={styles.container}
                toolbar={<PostEditToolbar props={props} />}
            >
                <TypesInput />
                <TagInput source="tags" choices={tags} />
                <TextInput
                    source="title"
                    label={i18n.t("posts.edit.title")}
                    fullWidth={true}
                />
                <TextInput
                    source="lead"
                    label={i18n.t("posts.edit.lead")}
                    fullWidth={true}
                    multiline={true}
                    rows={3}
                />
                <TextInput
                    multiline
                    source="subTitle"
                    label={i18n.t("posts.edit.subtitle")}
                    fullWidth={true}
                    rows={3}
                />
                <RichTextInput
                    source="body"
                    label={`${i18n.t("posts.edit.body")}`}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link', 'image']
                    ]}
                    configureQuill={(quill: any) => configureQuill(quill, "post", post!.id)}
                />
                <ImageModule />
                <RelatedPostsModule choices={postList} />
                <UsefulLinksModule />
                <Route path="/post/:id/publish">
                    {({ match }) => {
                        return (
                            <PostPublishForm
                                open={!!match}
                                data={post}
                                onConfirm={onActionSuccess}
                                onCancel={onActionCancel}
                                onError={onActionError}
                            />
                        );
                    }}
                </Route>
                <Route path="/post/:id/schedule">
                    {({ match }) => (
                        <PostScheduleForm
                            open={!!match}
                            data={post!}
                            onConfirm={onActionSuccess}
                            onCancel={onActionCancel}
                            onError={onActionError}
                        />
                    )}
                </Route>
            </SimpleForm>
        </Edit>
    )
};