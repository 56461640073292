import { List, ListProps } from 'react-admin';
import { CityListSection } from '../../../sections/CityList';
import { CityActions } from '../actions';
import { CityFilter } from '../filter';

import styles from './index.module.css';

export const CityList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<CityActions />}
            filters={<CityFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <CityListSection />
        </List>
    )
}

