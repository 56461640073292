
import { List, ListProps } from 'react-admin';
import { HighlightedEventListSection } from '../../../sections/HighlightedEventList';
import { HighlightedEventActions } from '../actions';
import { HighlightedEventFilter } from '../filter';
import styles from './index.module.css';

export const HighlightedEventList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<HighlightedEventActions />}
            filters={<HighlightedEventFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <HighlightedEventListSection />
        </List>
    )
}

