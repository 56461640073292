import { CoreError } from "../../model/core/CoreError";
import { ErrorCode } from "../../model/core/ErrorCode";
import { firestore, storage } from "../persistence";
import { ResourceItemInterface } from "../resource";

export class StorageService {

    public static async deleteFolder(resourceId: string, service: ResourceItemInterface, folder?: string) {
        const { storagePath } = service

        if (storagePath === undefined) {
            return
        }

        return new Promise<void>(async (resolve) => {

            const listResult = await storage.ref(folder ? `${storagePath}/${resourceId}/${folder}` : `${storagePath}/${resourceId}`).listAll()
            await Promise.all(listResult.items.map(item => {
                return item.delete()
            }))
            resolve()

        })

    }

    public static async uploadBodyImage(image: File, resourceId: string, service: ResourceItemInterface) {
        try {
            const { storagePath } = service

            if (storagePath === undefined) {
                return
            }

            const storageRef = storage.ref(`${storagePath}/${resourceId}/bodyImages/${image.name}`);
            await storageRef.put(image);

            return await storage.ref().child(`${storagePath}/${resourceId}/bodyImages/${image.name}`).getDownloadURL();
        } catch (e) {
            throw new CoreError(ErrorCode.INTERNAL);
        }
    }

    public static async uploadCustomFolderImage(image: File, resourceId: string, folder: string, service: ResourceItemInterface) {
        try {
            const { storagePath } = service

            if (storagePath === undefined) {
                return
            }

            const storageRef = storage.ref(`${storagePath}/${resourceId}/${folder}/${image.name}`);
            await storageRef.put(image);

            return await storage.ref().child(`${storagePath}/${resourceId}/${folder}/${image.name}`).getDownloadURL();
        } catch (e) {
            throw new CoreError(ErrorCode.INTERNAL);
        }
    }

    public static async uploadImage(newImage: any, resourceId: string, service: ResourceItemInterface) {
        const { storagePath } = service

        if (storagePath === undefined) {
            return
        }

        return new Promise<void>(async (resolve) => {

            if (newImage.rawFile !== undefined && newImage.rawFile instanceof File) {
                const storageRef = storage.ref(`${storagePath}/${resourceId}/${newImage.rawFile.name}`)
                await storageRef.put(newImage.rawFile)
            } else {
                return
            }

            // Wait 3.5 seconds before continuing to give time for image compressor to do his thing
            setTimeout(() => {
                resolve()
            }, 3500)
        })

    }

    public static async uploadImages(newImages: any[], resourceId: string, service: ResourceItemInterface) {
        const { storagePath, collection } = service

        if (storagePath === undefined) {
            return
        }

        const documentData = (await firestore.collection(collection).doc(resourceId).get()).data()

        if (documentData === undefined) {
            throw new CoreError(ErrorCode.NOT_EXISTS)
        }

        const prevImages = Array.isArray(documentData.images) ? documentData.images : []
        const actions = StorageService.extractDifference(newImages, prevImages, storagePath, resourceId)

        return new Promise<void>(async (resolve) => {
            await Promise.all(actions.map(action => {
                const { method, path, file } = action
                let storageRef
                switch (method) {
                    case 'put':
                        storageRef = storage.ref(path)
                        return storageRef.put(file!);
                    case 'delete':
                        storageRef = storage.refFromURL(path)
                        return storageRef.delete();
                    default:
                        return Promise.resolve()
                }
            }))

            // Wait 3.5 seconds before continuing to give time for image compressor to do his thing
            setTimeout(() => {
                resolve()
            }, 3500)
        })

    }

    /**
     * Iterate over old and new images array and extract the difference between the two
     * @param newImages 
     * @param prevImages 
     * @param storagePath 
     * @param resourceId 
     */
    private static extractDifference(newImages: any[], prevImages: string[], storagePath: string, resourceId: string) {
        const actions: { method: 'put' | 'delete', path: string, file?: File }[] = []

        newImages && newImages.forEach((newImage: any) => {
            if (newImage.rawFile !== undefined && newImage.rawFile instanceof File) {
                actions.push({ method: 'put', path: `${storagePath}/${resourceId}/${newImage.rawFile.name}`, file: newImage.rawFile })
            }
        })

        prevImages && prevImages.forEach((prevImage: string) => {
            if (!newImages.some(newImg => newImg.src === prevImage)) {
                actions.push({ method: 'delete', path: prevImage })
            }
        })

        return actions
    }

}