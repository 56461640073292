import { AppBar } from 'react-admin';
import { Logo } from '../../components/logo';
import styles from './index.module.css';

export const Header = (props:any) => {
    return (
        <AppBar {...props} position={"absolute"} elevation={1} className={styles.header}>
            <Logo />
            <span className={styles.spacer} />
        </AppBar>
    );
};