import { RouteWithoutLayout, useAuthState } from 'ra-core';
import { Redirect } from 'react-router';

/**
 * The AnonymousRoute is one type of Route that checks if the user is not authenticated. Only
 * in that specific situation this route will allow the processing to continue. If the user IS
 * already authenticated, then a redirect to the route path will have to be performed.
 */
export default function AnonymousRoute(props: any) {
    const { loading, authenticated } = useAuthState();

    if (loading) {
        return null
    }

    if (authenticated) {
        return <Redirect to={{ pathname: "/" }} />
    }

    return (
        <RouteWithoutLayout {...props} />
    );
}