export enum ErrorCode {
    METHOD_NOT_ALLOWED = 'error.method-not-allowed',
    ALREADY_EXISTS = 'error.already-exists',
    NOT_EXISTS = 'error.not-exists',
    UNKNOWN = 'error.unknown',
    UNAUTHORIZED = 'error.unauthorized',
    UNAUTHENTICATED = 'error.unauthenticated',
    FORBIDDEN = 'error.forbidden',
    ENTITY_WITHOUT_ID = 'error.entity-without-id',
    DIRECTORY_ID_NOT_AVAILABLE = 'error.directory-id-not-available',
    DIRECTORY_NON_UNIQUE_RESULT = 'error.directory-non-unique-result',
    INSUFFICIENT_CHARACTERS = 'error.insufficient_characters',
    TYPE_ERROR = 'error.type_error',
    INTERNAL = 'error.internal',
    INVALID_DATA = 'error.invalid_data',
    INVALID_COLLECTION_ID = 'error.invalid_collection_id',
    INVALID_PARAMS = 'error.invalid-params',
    REQUEST_WITHOUT_REF = 'error.request_without_ref',
    INVALID_FOLDER = 'error.invalid-folder',
    INVALID_FOLDER_NAME = 'error.invalid-folder-name',
    INITIALIZE_DI = 'error.initialize_di',
    TIMEOUT = 'error.timeout'
}
