import { default as MaterialPopper } from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import styles from './index.module.css';

export type PopperProps = {
    children: any;
    open: boolean;
    anchor: HTMLElement | null;
}

export const Popper: React.FC<PopperProps> = (props: PopperProps) => {
    const { open, anchor } = props;

    return (
        <MaterialPopper
            placement="bottom"
            open={open && open === true}
            modifiers={{
                flip: {
                    enabled: false,
                },
                preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                },
            }}
            style={{ zIndex: (props.open === true ? 10 : 0) }}
            anchorEl={anchor}
        >
            <Paper elevation={3} className={styles.root}>
                {props.children}
            </Paper>
        </MaterialPopper>
    );
}
