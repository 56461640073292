import { UpdateParams } from "react-admin";
import { PartnershipCategory } from "../../model/partnership_category/PartnershipCategory";
import { DocumentData, DocumentSnapshot } from "../../services/persistence";
import { EntityMapper } from "../core/EntityMapper";

export class PartnershipCategoryMapper extends EntityMapper<PartnershipCategory> {

    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data } = params
        return Object.assign(
            {},
            super.toFirestore(params),
            this.toFirestoreField(data, 'name'),
            this.toFirestoreField(data, 'description'),
        ) as DocumentData;
    }

    fromFirestore(snapshot: DocumentSnapshot): PartnershipCategory {
        const data = snapshot.data();

        return Object.assign(
            new PartnershipCategory(),
            super.fromFirestore(snapshot),
            this.fromFirestoreField(data, 'name'),
            this.fromFirestoreField(data, 'description'),
        );
    }
}