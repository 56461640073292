import { Button, useCreate, useRedirect } from 'react-admin';
import i18n from '../../language/i18n';

export function TagCreationButton() {
    const [, { loading }] = useCreate();
    const redirect = useRedirect()

    async function onClick(event: any) {
        event.preventDefault()
        redirect('create', '/tag')
    }

    return (
        <Button
            label={i18n.t("default.create")}
            onClick={onClick}
            disabled={loading}
            color="primary"
            variant="outlined"
        />
    )
}
