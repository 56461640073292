
import { UserProfileEdit } from './edit'
import { UserProfileCreate } from './create'
import { UserProfileList } from './list'

// eslint-disable-next-line
export default {
    edit: UserProfileEdit,
    create: UserProfileCreate,
    list: UserProfileList
};
