
export const DEFAULT_MS_THUMB = "https://amsglob0cdnstream13.azureedge.net/streamlogo/Light_640x360.jpg";

export interface Post {
    id: string;
    title: string;
    lead?: string;
    subTitle?: string;
    body?: string;
    type?: string;
    tags?: Array<any>;
    status?: string;
    usefulLinks?: Array<string>;
    images: Array<string>;
    creationDate: string;
    updatedDate: string;
    video?: { link: string, type: string, thumbnail?: string };
    podcast?: { link: string, type: string, thumbnail?: string };
}