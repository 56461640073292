import React from 'react';
import { List, ListProps } from 'react-admin';
import { BroadcastListSection } from '../../../sections/BroadcastList';
import { BroadcastActions } from '../actions';
import { BroadcastFilter } from '../filter';
import styles from './index.module.css';


export const BroadcastList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<BroadcastActions />}
            filters={<BroadcastFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <BroadcastListSection />
        </List>
    )
}