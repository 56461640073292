import { Edit, ImageField, ImageInput, SelectInput, SimpleForm, TextInput } from "ra-ui-materialui";
import { useCallback, useEffect, useState } from 'react';
import { DeleteWithConfirmButton, FormDataConsumer, Loading, NumberInput, SaveButton, Toolbar, TopToolbar, useDataProvider, useEditController, useNotify, useRefresh } from 'react-admin';
import { Route, useHistory } from "react-router";
import { PageTitle } from '../../../components/pageTitle';
import RichTextInput from "../../../components/richTextInput";
import i18n from '../../../language/i18n';
import { PARTNERSHIP_KIND } from '../../../model/partnership/Partnership';
import { PARTNERSHIP_CATEGORY_KIND } from '../../../model/partnership_category/PartnershipCategory';
import { PartnershipEnableButton } from "../../../sections/PartnershipEnableButton";
import { PartnershipEnableForm } from "../../../sections/PartnershipEnableForm";
import { countDecimals } from '../../../utils';
import styles from './index.module.css';

const validatePartnershipEdit = (values: any) => {
    const errors: any = {};
    if (!values.title) {
        errors.title = i18n.t("partnerships.edit.errors.title");
    }

    if (values.discount) {

        if (values.discount < 0 || values.discount > 100) {
            errors.discount = i18n.t("partnerships.edit.errors.discount.bad_range");
        }

        if (countDecimals(values.discount) > 2) {
            errors.discount = i18n.t("partnerships.edit.errors.discount.invalid-decimals");
        }

    }

    return errors
};

const PartnershipCategoriesInput = (props: any) => {
    return (
        <FormDataConsumer>
            {() => {
                const choices = props.choices ? props.choices.map((choice: any) => ({ id: choice.id, name: choice.name })) : []
                return (
                    <SelectInput
                        source="categories"
                        optionText="name"
                        choices={choices}
                    />
                )
            }}
        </FormDataConsumer >
    );
};

const PartnershipEditActions = ({ data, resource }: any) => {
    if (data === undefined) {
        return null
    }

    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.partnership")} </PageTitle>
            <PartnershipEnableButton data={data} resource={resource} />
        </TopToolbar>
    );
};



export const PartnershipEdit = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [partnershipCategories, setParnershipCategories] = useState<object[] | undefined>(undefined);
    const [partnership, setPartnership] = useState<any | undefined>(undefined);
    const dataProvider = useDataProvider();
    const history = useHistory()
    const notify = useNotify()
    const refresh = useRefresh()
    const { record, save } = useEditController(props);

    const PartnershipEditToolbar = (props: any) => {
        if (record === undefined) {
            return null
        }

        return (
            <Toolbar className={styles.toolbar} {...props}>
                <SaveButton handleSubmitWithRedirect={(values: any) => save(values)} {...props}/>
                <DeleteWithConfirmButton confirmTitle="Remover Parceria" record={record} />
            </Toolbar>
        );
    };

    const fetchData = useCallback(async () => {
        try {

            const partnershipRes = await dataProvider.getOne(PARTNERSHIP_KIND, { id: props.id });
            const partnershipCategoriesRes = await dataProvider.getList(PARTNERSHIP_CATEGORY_KIND, { filter: { searchText: "" }, pagination: { perPage: 1000, page: 1 } } as any);

            setParnershipCategories(partnershipCategoriesRes.data);
            setPartnership(partnershipRes.data);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }, [dataProvider, props.id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    function onActionError() {
        history.push(`/partnership/${partnership!.id}`);
        notify(i18n.t("default.errors.partnership"), 'error')
    }

    async function onActionSuccess() {
        history.push(`/partnership/${partnership!.id}`);
        await fetchData()
        refresh(true)
    }

    function onActionCancel() {
        history.push(`/partnership/${partnership!.id}`);
    }

    if (loading === true) {
        return <Loading />
    };

    return (
        <Edit mutationMode="pessimistic" {...props} actions={<PartnershipEditActions />} className={"mid-container"} >
            <SimpleForm
                validate={validatePartnershipEdit}
                redirect={false}
                toolbar={<PartnershipEditToolbar record={partnership} />}
            >
                <TextInput source="title" label={i18n.t("partnerships.edit.title")} />
                <TextInput source="description" label={i18n.t("partnerships.edit.description")} />
                <TextInput source="link" label={i18n.t("partnerships.edit.link")} />
                <TextInput source="location" label={i18n.t("partnerships.edit.location")} />
                <TextInput source="phone" label={i18n.t("partnerships.edit.phone")} />
                <TextInput source="email" label={i18n.t("partnerships.edit.email")} />
                <TextInput multiline source="email_body" label={i18n.t("partnerships.edit.email_body")} />
                <RichTextInput source="instructions" label={`${i18n.t("partnerships.edit.instructions")}`} />
                <NumberInput source="discount" label={i18n.t("partnerships.edit.discount")} />
                <PartnershipCategoriesInput source="categories" choices={partnershipCategories} />
                <ImageInput
                    source="image"
                    accept="image/*"
                    label={i18n.t("partnerships.edit.image.label")}
                    labelSingle={i18n.t("partnerships.edit.image.description")}
                >
                    <ImageField source="src" />
                </ImageInput>
                <Route path="/partnership/:id/enable">
                    {({ match }) => {
                        const open = !!match
                        const form = open ? (
                            <PartnershipEnableForm
                                open={open}
                                data={partnership}
                                onConfirm={onActionSuccess}
                                onCancel={onActionCancel}
                                onError={onActionError}
                            />
                        ) : undefined
                        return form;
                    }}
                </Route>
            </SimpleForm>
        </Edit>
    )
};
