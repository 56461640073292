import { Edit, SelectInput, SimpleForm, TextInput, TopToolbar } from "ra-ui-materialui";
import { PageTitle } from "../../../components/pageTitle";
import RichTextInput from '../../../components/richTextInput';
import i18n from "../../../language/i18n";
import styles from "./index.module.css";
import { Toolbar, SaveButton, DeleteWithConfirmButton, useEditController } from "react-admin";

const validateTeamsEdit = (values: any) => {
    const errors: any = {};

    if (!values.name) {
        errors.name = i18n.t("teams.errors.name");
    }
    
    return errors
};

const TeamsEditActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.teams")} </PageTitle>
        </TopToolbar>
    );
};




export const TeamsEdit = (props: any) => {
    const { record, save } = useEditController(props);

    const TeamsEditToolbar = (props: any) => {
        if (record === undefined) {
            return null
        }
    
        return (
            <Toolbar className={styles.bottomToolbar} {...props}>
                <SaveButton handleSubmitWithRedirect={(values: any) => save(values)} {...props}/>
                <DeleteWithConfirmButton confirmTitle="Remover Equipa" record={record} />
             </Toolbar>
        );
    };
    
    
    return (
        <Edit mutationMode="pessimistic" {...props} className={"mid-container"} actions={<TeamsEditActions />}>
            <SimpleForm validate={validateTeamsEdit} redirect={false} toolbar={<TeamsEditToolbar props={props} />}>
                <SelectInput
                    source="type"
                    choices={[
                        { id: 'practice', name: i18n.t("teams.practice") },
                        { id: 'management', name: i18n.t("teams.management") },
                        { id: 'governance', name: i18n.t("teams.governance") },
                    ]}
                    style={{ width: 400, display: "none" }}
                    label={i18n.t("sidebar.event_category")}
                    disabled
                />
                <TextInput source="name" label={i18n.t("teams.name")} style={{ width: 400 }} />
                <RichTextInput
                    source="description"
                    label={`${i18n.t("teams.description")}`}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link']
                    ]}
                    initialValue={null}
                />
                <RichTextInput
                    source="details"
                    label={`${i18n.t("teams.details")}`}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link']
                    ]}
                    initialValue={null}
                />
            </SimpleForm>
        </Edit>
    )
};
