import { useFormState } from 'react-final-form';
import { ArrayInput, TextInput, SimpleFormIterator } from 'react-admin';
import i18n from '../../../../language/i18n';

export const UsefulLinksModule = (props: any) => {
    const { values } = useFormState();

    if (["video", "podcast"].includes(values.type)) {
        return null
    }

    return (
        <ArrayInput 
            source={"usefulLinks"}
            label={i18n.t("module.usefulLinks.input")}
        >
            <SimpleFormIterator>
                <TextInput 
                    source={"link"} 
                    fullWidth={true} 
                    label={i18n.t("module.usefulLinks.link")}
                />
            </SimpleFormIterator>
        </ArrayInput>
    );
}