import { TextField, SimpleList, DatagridBodyProps, Record, useRedirect } from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Table } from "../Table";
import { Title } from "../Title";
import i18n from '../../language/i18n';
import { useEffect, useState } from 'react';
import SetupTeamsService from '../../services/setupTeams';
import styles from "./index.module.css"

export const TeamsListSection = () => {
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ config, setConfig ] = useState<Array<{ value: string, type: string}>|undefined>(undefined);

    useEffect(() => {
        getPageData()
        .then((data: any) => {
            console.log(data.tabs)
            setConfig(data.tabs);
            setLoading(false);
        })
        .catch((e) => {
            setLoading(false);
        });
    }, []);

    async function getPageData() {
        try {
            return await SetupTeamsService.getConfig()
        } catch (error) {
            console.log("error", error)
        }
    }


    const getTitle = (name: string) => {
        return (
            <Title>
                {name}
            </Title>
        );
    }

    const getType = (name: string) => {
        const type = config?.filter((value: { value: string, type: string}) => value.type === name);

        if (type === undefined || type.length === 0) {
            return ""
        }

        return type[0].value;
    }


    const RowConfig = ({ record, id }: DatagridBodyProps) => {
        const redirect = useRedirect();

        if (record === undefined) {
            return null;
        }

        return (
            <TableRow key={id} onClick={() => redirect('edit', '/teams', id)}>
                <TableCell
                    key={`${id}-name`}
                    size={'small'}
                >
                    {getTitle(record.name)}
                </TableCell>
                <TableCell
                    key={`${id}-type`}
                    size={'medium'}
                >
                    {getType(record.type)}
                </TableCell>
            </TableRow>
        );
    };

    const ResponsiveTable = () => {
        return (
            <SimpleList
                primaryText={(record: Record) => getTitle(record.name)}
                secondaryText={(record: Record) => getType(record.type)}
                linkType={record => "edit"}
            />
        );
    }

    if (loading === true) {
        return null;
    }

    return (
        <div className={styles.table}>
            <Table rowConfig={<RowConfig />} responsiveList={<ResponsiveTable />}>
                <TextField source="name" label={i18n.t("teams.list.description")} sortable={false} />
                <TextField source="type" label={i18n.t("teams.list.label")} sortable={false} />
            </Table>    
        </div>
        
    );
}
