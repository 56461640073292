import { ColorInput as ReactAdminColorInput } from 'react-admin-color-input';
import styles from "./index.module.css";

export default function ColorInput(props: any) {

    return (
        <div className={styles.customColorInput}>
            <ReactAdminColorInput
                {...props}
            />
        </div>
    );
}