import { UpdateParams } from "ra-core";
import { WhoIsWho } from "../../model/pagesCustom/WhoIsWho";
import { DocumentData, DocumentSnapshot } from "../../services/persistence";
import { EntityMapper } from "../core/EntityMapper";

export class WhoIsWhoMapper extends EntityMapper<WhoIsWho> {
  async toFirestore(params: UpdateParams): Promise<DocumentData> {
    const { data } = params;

    const res = Object.assign(
      {},
      super.toFirestore(params),
      this.toFirestoreField(data, "title"),
      this.toFirestoreField(data, "subtitle")
    ) as DocumentData;

    return res;
  }

  fromFirestore(snapshot: DocumentSnapshot): WhoIsWho {
    const data = snapshot.data();

    const res = Object.assign(
      new WhoIsWho(),
      super.fromFirestore(snapshot),
      this.fromFirestoreField(data, "title"),
      this.fromFirestoreField(data, "subtitle")
    );
    return res;
  }

}
