import { Box, Grid } from "@material-ui/core";
import { Title } from '../../../../sections/Title';
import i18n from '../../../../language/i18n';
import { TextInput } from 'ra-ui-materialui';

export const ButtonWithLinkModule = ({ id }: any) => {
    const sectionId = id && id.substring(id.indexOf("[") + 1, id.lastIndexOf("]"));
    return (
        <>
            <Box display={"flex"} flexDirection={"column"}>
                <Grid container spacing={2} style={{ flexGrow: 1 }}>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <Title> {i18n.t("module.pageSection.button.title")} </Title>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} xl={6}>
                        <Title> {i18n.t("module.pageSection.button.titleFirst")} </Title>
                        <TextInput 
                            source={`sections[${sectionId}].button[0].text`}
                            label={i18n.t("module.pageSection.button.input")}
                            fullWidth={true}
                        />
                        <TextInput 
                            source={`sections[${sectionId}].button[0].link`}
                            label={i18n.t("module.pageSection.button.link")}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} xl={6}>
                        <Title> {i18n.t("module.pageSection.button.titleSecond")} </Title>
                        <TextInput 
                            source={`sections[${sectionId}].button[1].text`}
                            label={i18n.t("module.pageSection.button.input")}
                            fullWidth={true}
                        />
                        <TextInput 
                            source={`sections[${sectionId}].button[1].link`}
                            label={i18n.t("module.pageSection.button.link")}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}