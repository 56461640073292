import { List, ListProps } from 'react-admin';
import { PartnershipCategoryListSection } from '../../sections/PartnershipCategoryList';
import { PartnershipCategoryActions } from './actions';
import { PartnershipCategoryFilter } from './filter';
import styles from './index.module.css';

export const PartnershipCategoryList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<PartnershipCategoryActions />}
            filters={<PartnershipCategoryFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <PartnershipCategoryListSection />
        </List>
    )
}

