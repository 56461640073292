import { TopToolbar, sanitizeListRestProps } from 'react-admin';
import { JobsCreationButton } from '../../sections/JobsCreationButton';
import { JobsOrderButton } from '../../sections/JobsOrderButton';

export const JobActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;

    return (
        <TopToolbar {...sanitizeListRestProps(rest)}>
            <JobsOrderButton />
            <JobsCreationButton />
        </TopToolbar>
    );
};
