import { useEffect } from 'react';
import { Button, useCreate, useNotify, useRedirect } from 'react-admin';
import i18n from '../../language/i18n';
import { KioskItem, KIOSK_ITEM_KIND } from '../../model/kiosk_item/KioskItem';

export function KioskItemCreationButton() {
    const [create, { data, loaded, loading, error }] = useCreate();
    const redirect = useRedirect()
    const notify = useNotify();


    useEffect(() => {
        if (error) {
            notify(i18n.t("default.errors.kiosk_item"), 'error')
            return
        }

        if (loaded === false) {
            return
        }

        if (data === null || data === undefined) {
            notify(i18n.t("default.errors.kiosk_item"), 'error')
            return
        }

        redirect('edit', '/kiosk_item', data.id)
    }, [loaded, data, error, notify, redirect])

    async function onClick(event: any) {
        event.preventDefault()
        create(KIOSK_ITEM_KIND, KioskItem.template())
    }

    return (
        <Button
            label={i18n.t("default.create")}
            onClick={onClick}
            disabled={loading}
            color="primary"
            variant="outlined"
        />
    )
}
