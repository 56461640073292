import * as React from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Layout, LayoutProps, MenuItemLink, MenuProps } from "react-admin";

import { CustomSidebar } from "../CustomSidebar";
import { Header } from "../Header";

import PostAddIcon from "@material-ui/icons/PostAdd";
import DescriptionIcon from "@material-ui/icons/Description";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import EventIcon from "@material-ui/icons/Event";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import AppsIcon from '@material-ui/icons/Apps';
import Inbox from '@material-ui/icons/Inbox';
import i18n from "../../language/i18n";
import SubMenu from "../SubMenu";

type MenuName =
    | "menuPosts"
    | "menuPartnerships"
    | "menuEvents"
    | "menuPages"
    | "menuPagesWho"
    | "menuBroadcasts";

export const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuPosts: false,
        menuPartnerships: false,
        menuEvents: false,
        menuPages: false,
        menuBroadcasts: false,
        menuPagesWho: false,
    });

    const handleToggle = (menu: MenuName) => {
        setState((state) => ({ ...state, [menu]: !state[menu] }));
    };

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <SubMenu
                handleToggle={() => handleToggle("menuEvents")}
                isOpen={state.menuEvents}
                name={i18n.t("sidebar.event")}
                icon={<EventIcon />}
                dense={dense}
            >
                <MenuItemLink
                    key={"event"}
                    to={`/event`}
                    primaryText={i18n.t("sidebar.all_events")}
                />
                <MenuItemLink
                    key={"highlighted_event"}
                    to={`/highlighted_event`}
                    primaryText={i18n.t("sidebar.highlighted_event")}
                />
                <MenuItemLink
                    key={"event_category"}
                    to={`/event_category`}
                    primaryText={i18n.t("sidebar.event_category")}
                />
            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle("menuPages")}
                isOpen={state.menuPages}
                name={i18n.t("sidebar.pages")}
                icon={<DescriptionIcon />}
                dense={dense}
            >
                <MenuItemLink
                    key={"page"}
                    to={`/page`}
                    primaryText={i18n.t("sidebar.pages_custom")}
                />
                <MenuItemLink
                    key={"homepage"}
                    to={`/pages_plmj_homepage/homepage`}
                    primaryText={i18n.t("sidebar.homepage")}
                />
                <MenuItemLink
                    key={"agenda"}
                    to={`/pages_plmj_agenda/agenda`}
                    primaryText={i18n.t("sidebar.agenda")}
                />
                <MenuItemLink
                    key={"broadcast-setup"}
                    to={`/pages_plmj_broadcast/broadcast`}
                    primaryText={i18n.t("sidebar.broadcast_config")}
                />

                <MenuItemLink
                    key={"teams"}
                    to={`/teams`}
                    primaryText={i18n.t("sidebar.teams")}
                />
                <MenuItemLink
                    key={"teams-setup"}
                    to={`/pages_plmj_teams/teams`}
                    primaryText={i18n.t("sidebar.teams_config")}
                />
                <MenuItemLink
                    key={"whoiswho-jobs"}
                    to={`/job_title`}
                    primaryText={i18n.t("sidebar.job_titles")}
                />
                <MenuItemLink
                    key={"plmjplus"}
                    to={`/pages_plmj_plus/plmjplus`}
                    primaryText={i18n.t("sidebar.plmjplus")}
                />
                <MenuItemLink
                    key={"whoiswho-setup"}
                    to={`/pages_plmj_whoiswho/whoiswho`}
                    primaryText={i18n.t("sidebar.whoiswho_setup")}
                />

                <MenuItemLink
                    key={"whoiswho-profiles"}
                    to={`/user_profile`}
                    primaryText={i18n.t("sidebar.whoiswho_profiles")}
                />
                <MenuItemLink
                    key={"whoiswho-cities"}
                    to={`/city`}
                    primaryText={i18n.t("sidebar.cities")}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuPartnerships")}
                isOpen={state.menuPartnerships}
                name={i18n.t("sidebar.partnership")}
                icon={<SupervisorAccountIcon />}
                dense={dense}
            >
                <MenuItemLink
                    key={"partnership"}
                    to={`/partnership`}
                    primaryText={i18n.t("sidebar.partnership_list")}
                />
                <MenuItemLink
                    key={"partnership_category"}
                    to={`/partnership_category`}
                    primaryText={i18n.t("sidebar.tags")}
                />
            </SubMenu>
            <MenuItemLink
                key={"application"}
                to={`/application`}
                primaryText={i18n.t("sidebar.applications.title")}
                leftIcon={<AppsIcon />}
            />
            <SubMenu
                handleToggle={() => handleToggle("menuPosts")}
                isOpen={state.menuPosts}
                name={i18n.t("sidebar.posts")}
                icon={<PostAddIcon />}
                dense={dense}
            >
                <MenuItemLink
                    key={"post"}
                    to={`/post`}
                    primaryText={i18n.t("sidebar.posts_list")}
                />
                <MenuItemLink
                    key={"tag"}
                    to={`/tag`}
                    primaryText={i18n.t("sidebar.tags")}
                />
            </SubMenu>
            <MenuItemLink
                key={"kiosk_item"}
                to={`/kiosk_item`}
                primaryText={i18n.t("sidebar.kiosk_items.title")}
                leftIcon={<Inbox />}
            />
            <MenuItemLink
                key={"sitemap"}
                to={`/sitemap`}
                primaryText={i18n.t("sidebar.sitemap.title")}
                leftIcon={<DescriptionIcon />}
            />
            <SubMenu
                handleToggle={() => handleToggle("menuBroadcasts")}
                isOpen={state.menuBroadcasts}
                name={i18n.t("sidebar.broadcast")}
                icon={<OndemandVideoIcon />}
                dense={dense}
            >
                <MenuItemLink
                    key={"broadcast"}
                    to={`/broadcast`}
                    primaryText={i18n.t("sidebar.all_broadcasts")}
                />
                <MenuItemLink
                    key={"broadcast"}
                    to={`/broadcast_category`}
                    primaryText={i18n.t("sidebar.tags")}
                />
            </SubMenu>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
    },
}));

export function CustomLayout(props: LayoutProps) {
    return (
        <Layout {...props} appBar={Header} menu={Menu} sidebar={CustomSidebar} />
    );
}
