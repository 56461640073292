import { Layout } from "antd";
import PageTemplate from "..";
import styles from './index.module.css';


export default function PageLayout() {
    return (
        <Layout className={[styles.main].join(" ")} id="mainSection">
            <PageTemplate/>
        </Layout>
    )
}