import {
    Edit,
    SimpleForm,
    TextInput,
    TopToolbar, ImageField, ImageInput
} from "ra-ui-materialui";
import { DeleteWithConfirmButton, SaveButton, Toolbar, useEditController, useNotify } from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import styles from "./index.module.css";

const validateProfileEdit = (values: any) => {
    const errors: any = {};

    if (values.filename === undefined) {
        errors.filename = i18n.t("whoiswho_profiles.file.error");
    }

    if (values.image === undefined || values.image === null) {
        errors.image = i18n.t("whoiswho_profiles.image.error");
    }

    return errors;
};

const UsersProfileEditActions = ({ data }: any) => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle>
                {i18n.t("sidebar.whoiswho_profiles")}
            </PageTitle>
        </TopToolbar>
    );
};




export const UserProfileEdit = (props: any) => {
    const notify = useNotify();
    const { record, save } = useEditController(props);

    const UsersProfileEditToolbar = (props: any) => {
        if (record === undefined) {
            return null
        }
    
        return (
            <Toolbar className={styles.bottomToolbar} {...props}>
                <SaveButton handleSubmitWithRedirect={(values: any) => save(values)} {...props}/>
                <DeleteWithConfirmButton confirmTitle="Remover Colaborador" record={record} />
             </Toolbar>
        );
    };

    const onSuccess = () => {
        notify(`${i18n.t("whoiswho_profiles.edit.success")}`);
    };

    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            className={"mid-container"}
            onSuccess={onSuccess}
            actions={<UsersProfileEditActions />}
        >
            <SimpleForm validate={validateProfileEdit} redirect={false} toolbar={<UsersProfileEditToolbar props={props} />}>
                <TextInput 
                    source="filename" 
                    label={i18n.t("whoiswho_profiles.file.name")} 
                    helperText={i18n.t("whoiswho_profiles.file.helper")}
                    required
                    style={{ width: "50%" }}
                />
                <ImageInput
                    source="image"
                    accept="image/jpg,image/jpeg,image/png"
                    label={`${i18n.t("whoiswho_profiles.image.label")}`}
                    isRequired
                >
                    <ImageField source="src" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    );
};
