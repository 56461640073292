import { useEffect } from 'react';
import {
    Button, useCreate, useNotify, useRedirect
} from 'react-admin';
import { Page, PAGE_KIND } from '../../model/page/Page';
import i18n from '../../language/i18n';

export function PageCreationButton() {
    const [create, { data, loaded, loading, error }] = useCreate();
    const redirect = useRedirect()
    const notify = useNotify()

    useEffect(() => {

        if (error) {
            notify(i18n.t("default.errors.page"), 'error')
            return
        }

        if (loaded === false) {
            return
        }

        if (data === null || data === undefined) {
            notify(i18n.t("default.errors.page"), 'error')
            return
        }

        redirect('edit', '/page', data.id)
    }, [loaded, data, error, notify, redirect])

    async function onClick(event: any) {
        event.preventDefault()
        create(PAGE_KIND, Page.templatePage())
    }

    return (
        <Button
            label={i18n.t("default.create")}
            onClick={onClick}
            disabled={loading}
            color="primary"
            variant="outlined"
        />
    )
}
