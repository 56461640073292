import React from 'react';
import { ListBase, ListProps, Pagination, useListContext, DatagridProps, linkToRecord, Identifier, FilterLiveSearch, TopToolbar } from 'react-admin';
import { Box, useMediaQuery, Theme } from '@material-ui/core';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { UserProfileCreationButton } from '../../../sections/UserProfileCreationButton';

interface GridProps extends Omit<DatagridProps, 'width'>, WithWidth {}

const useStyles = makeStyles(theme => ({
    gridList: {
        margin: 5,
    },
    tileBar: {
        background:
            'rgba(0, 0, 0, 0.5)',
    },
    placeholder: {
        backgroundColor: theme.palette.grey[300],
        height: '100%',
    },
    price: {
        display: 'inline',
        fontSize: '1em',
    },
    link: {
        color: '#fff',
    },
}));

const times = (nbChildren: number, fn: (key: number) => any) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

const getColsForWidth = (width: Breakpoint) => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 3;
    if (width === 'md') return 3;
    if (width === 'lg') return 5;
    return 6;
};

const ImageList = withWidth()((props: WithWidth) => {
    const { loaded } = useListContext();
    const { width } = props;
    return loaded ? (
        <LoadedGridList width={width} />
    ) : (
        <LoadingGridList width={width} />
    );
});

const LoadingGridList = (props: GridProps & { nbItems?: number }) => {
    const { width, nbItems = 10 } = props;
    const classes = useStyles();

    return (
        <MuiGridList
            cellHeight={180}
            cols={getColsForWidth(width)}
            className={classes.gridList}
        >
            {' '}
            {times(nbItems, key => (
                <GridListTile key={key}>
                    <div className={classes.placeholder} />
                </GridListTile>
            ))}
        </MuiGridList>
    );
};

const ListActions = () => (
    <TopToolbar>
        <UserProfileCreationButton />
    </TopToolbar>
);

const LoadedGridList = (props: GridProps) => {
    const { width } = props;
    const { ids, data, basePath } = useListContext();
    const classes = useStyles();

    if (!ids || !data) return null;

    return (
        <>
            <ListActions />
            <FilterLiveSearch source='searchText' />
            <MuiGridList
                cellHeight={300}
                cols={getColsForWidth(width)}
                className={classes.gridList}
            >
                {ids.map((id: Identifier) => (
                    <GridListTile
                        // @ts-ignore
                        component={Link}
                        key={id}
                        to={linkToRecord(basePath, data[id].id)}
                    >
                        <img src={data[id].image} alt="" style={{ maxHeight: 300, objectFit: "contain" }} />
                        <GridListTileBar
                            className={classes.tileBar}
                            title={data[id].reference}
                            subtitle={
                                <span>
                                    {data[id].filename}
                                </span>
                            }
                        />
                    </GridListTile>
                ))}
            </MuiGridList>
        </>
    );
};

export const UserProfileList = (props: ListProps) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <ListBase perPage={10} {...props}>
            <Box display="flex">
                <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>
                    <ImageList />
                    <Pagination rowsPerPageOptions={[10, 20, 50, 80]} />
                </Box>
            </Box>
        </ListBase>
    );
}
