import {
    Create,
    SimpleForm,
    TextInput,
    TopToolbar,
} from "ra-ui-materialui";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import styles from "./index.module.css";

const validateCityCreate = (values: any) => {
    const errors: any = {};

    if (values.name === undefined) {
        errors.name = i18n.t("cities.create.error");
    }

    return errors;
};

const CityActions = ({ data, resource }: any) => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle>
                {i18n.t("sidebar.cities")}
            </PageTitle>
        </TopToolbar>
    );
};

export const CityCreate = (props: any) => {
    const redirect = (_: string, id: string) => `/city/${id}`;

    return (
        <Create
            mutationMode="pessimistic"
            className={"mid-container"}
            actions={<CityActions />}
            {...props}
        >
            <SimpleForm validate={validateCityCreate} redirect={redirect}>
                <TextInput
                    source="name"
                    label={i18n.t("cities.create.name")}
                    helperText={i18n.t("cities.form.helper")}
                    style={{ width: "50%" }}
                    required
                />
            </SimpleForm>
        </Create>
    );
};