import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useFormState } from 'react-final-form';
import i18n from '../../../../language/i18n';
import { Title } from '../../../../sections/Title';
import { LinkInput } from '../../../../sections/LinkInput';
import { SubTitle } from '../../../../sections/SubTitle';

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    }),
);

export const PodcastModule = (props: any) => {
    const { values } = useFormState();
    const classes = useStyles();

    if (values.type === "podcast") {
        return (
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Grid container direction="column">
                    <Title> {i18n.t("module.podcast.title")} </Title>
                    <SubTitle>{i18n.t("module.podcast.subtitle")}</SubTitle>
                    </Grid>
                    <LinkInput source={"podcast.link"} label={i18n.t("module.podcast.input")} />
                </Grid>
            </Grid>
        );
    }

    return null;
}