import { Box } from "@material-ui/core";
import { TextInput } from "ra-ui-materialui";
import i18n from '../../../../language/i18n';
import { Title } from '../../../../sections/Title';
import styles from './index.module.css';

export const HtmlModule = ({ id }: any) => {
    return (
        <>
            <Title> {i18n.t(`module.pageSection.html.title`)} </Title>
            <div className={styles.textInputContainer}>
                <Box display={"flex"} flexDirection={"column"}>
                    <TextInput
                        source={`${id}.html[0].body`}
                        label=""
                        multiline
                    />
                </Box>
            </div>
        </>
    )
}