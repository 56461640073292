import { CoreError } from '../../model/core/CoreError';
import { ErrorCode } from '../../model/core/ErrorCode';
import { DocumentData, DocumentSnapshot } from '../../services/persistence'
import { Mapper } from './Mapper';
import { Entity } from '../../model/core/Entity';
import { CreateParams, GetListParams, UpdateParams } from 'react-admin';

export abstract class EntityMapper<T extends Entity> extends Mapper<T> {

    toFirestore({ data }: UpdateParams | CreateParams): Promise<DocumentData> | DocumentData {
        return {
            ...(data.creationDate ? { creationDate: data.creationDate } : {}),
            ...(data.updatedDate ? { updatedDate: data.updatedDate } : {})
        };
    }

    fromFirestore(snapshot: DocumentSnapshot): Promise<T> | T {

        if (!snapshot.exists) {
            throw new CoreError(ErrorCode.NOT_EXISTS)
        }

        const data = snapshot.data()

        return {
            id: snapshot.id,
            ...(data!.creationDate ? { creationDate: data!.creationDate.toDate() } : {}),
            ...(data!.updatedDate ? { updatedDate: data!.updatedDate.toDate() } : {})
        } as T;
    }

    toFirestoreCriteria(params: GetListParams): any {
        return {}
    }

}
