import { DataProvider, GetListParams, GetOneParams, CreateParams, Identifier } from "ra-core/esm/types";
import { CoreError } from "../../model/core/CoreError";
import { ErrorCode } from "../../model/core/ErrorCode";
import { Schemas } from "../../model/core/Schemas";
import { isRequest, Request } from "../../model/request/Request";
import { AuthContainer } from "../authentication/AuthContainer";
import { FieldPath, firestore, functions } from "../persistence";
import { ResourceService } from "../resource";
import { FirestoreService } from "../firestore/FirestoreService";
import { GetManyParams, GetManyReferenceParams } from "react-admin";
import i18n from "../../language/i18n";

export const RequestProvider: DataProvider = {
    getList: async (resource: string, params: GetListParams) => {
        const from = params.pagination.page === 1 ? 0 : (params.pagination.page - 1) * params.pagination.perPage;

        const service = ResourceService[resource];

        if (service === undefined) {
            return {
                data: [],
                total: 0
            }
        }

        const sortProvided = params.sort && params.sort.field !== 'id'
        const criteria = {
            size: params.pagination.perPage,
            ...(params.filter.searchText && params.filter.searchText.trim() !== "" ? { query: params.filter.searchText } : {}),
            ...(params.filter.type && params.filter.type.trim() !== "" ? { type: params.filter.type } : {}),
            ...service.mapper.toFirestoreCriteria(params),
            sort: [sortProvided ? { field: params.sort.field, order: params.sort.order.toLowerCase() } : service.defaultListSort ],
            from
        };

        const callableResult = await functions.httpsCallable(service.search)(criteria);

        return {
            data: callableResult.data.response,
            total: callableResult.data.total,
        };
    },
    getOne: async (resource: string, params: GetOneParams) => {
        const { collection, mapper, repository } = ResourceService[resource]
        
        if (collection === undefined) {
            throw new CoreError(ErrorCode.INVALID_COLLECTION_ID)
        }

        if (params.id === undefined || typeof params.id !== 'string') {
            throw new CoreError(ErrorCode.INVALID_PARAMS)
        }

        return repository.getOne(params.id, collection, mapper);
    },
    getMany: async (resource: string, params: GetManyParams) => {
        const { collection, mapper } = ResourceService[resource]
        if (collection === undefined) {
            throw new CoreError(ErrorCode.INVALID_COLLECTION_ID)
        }

        const ids = params.ids.map((obj: any) => typeof obj === 'string' ? obj : obj.id)
        const result = await firestore.collection(collection).where(FieldPath.documentId(), 'in', ids).get()

        return {
            data: await Promise.all(result.docs.map(async snap => await mapper.fromFirestore(snap))) as any
        };
    },
    getManyReference: async (resource: string, params: GetManyReferenceParams) => {
        const entity = await RequestProvider.getOne(params.target, { id: params.id })

        const referenceArray = entity.data[resource].map((t: any) => t.data);

        return {
            data: referenceArray,
            total: referenceArray.length
        };
    },
    create: async (resource: string, params: CreateParams) => {
        const service = ResourceService[resource]

        if (service === undefined) {
            throw new CoreError(ErrorCode.INVALID_COLLECTION_ID)
        }

        const responseData = await service.repository.create(params, service.requestKind, service.mapper);

        return {
            data: {
                id: responseData.response[service.responseRef].id as Identifier,
                ref: responseData.response[service.responseRef],
                ...responseData.response
            }
        }
    },
    update: async (resource, params) => {
        try{
            const service = ResourceService[resource];
            
            if (service === undefined) {
                throw new CoreError(ErrorCode.INVALID_COLLECTION_ID)
            }
            
            
            const responseData = await service.repository.update(params, service);

            return {
                data: {
                    id: params.id,
                    ...(await service.mapper.fromFirestore(responseData))
                } as any
            }
        } catch(error){
        console.log("RequestProvider ::: update", error);
        throw new CoreError(i18n.t("default.errors.edit"));
        }
    },
    updateMany: async (resource, params) => {
        return {};
    },
    delete: async (resource, params) => {
        const service = ResourceService[resource]
        
        if (service === undefined) {
            throw new CoreError(ErrorCode.INVALID_COLLECTION_ID)
        }

        await service.repository.delete(params, service);

        return {
            data: params.previousData as any
        };
    },
    deleteMany: async (resource, params) => {
        return {};
    },
    requestAction: async (params: RequestActionParameters) => {
        const { resource, request } = params
        const service = ResourceService[resource]

        if (service === undefined) {
            throw new CoreError(ErrorCode.INVALID_COLLECTION_ID)
        }

        const currentUser = AuthContainer.getUser()

        if (currentUser === undefined) {
            throw new CoreError(ErrorCode.UNAUTHENTICATED)
        }

        if (!isRequest(request)) {
            throw new CoreError(ErrorCode.INVALID_DATA)
        }

        request.createdByRef = firestore.collection(Schemas.USERS.collection).doc(currentUser.id as string)
        const requestResult = await FirestoreService.onRequestSnapshot(request)

        return {
            data: {
                id: requestResult.response ? requestResult.response[service.responseRef].id : request[service.requestRef].id,
                ref: requestResult.response ? requestResult.response[service.responseRef] : request[service.requestRef],
            }
        }
    }
}

type RequestActionParameters = {
    resource: string
    request: Request
}
