import {
    Edit,
    SimpleForm,
    TextInput,
    TopToolbar,
} from "ra-ui-materialui";
import { DeleteWithConfirmButton, EditProps, ImageField, ImageInput, PasswordInput, SaveButton, SelectInput, Toolbar, useEditController } from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import styles from "./index.module.css";

type ValidationProps = { name: string, link: string, recurrency: string, emailAccount: string, passwordAccount: string, cover: string }

const validateKioskItemEdit = (values: ValidationProps) => {
    const errors: any = {};

    if (values.name === undefined) {
        errors.name = i18n.t("kiosk_item.edit.error.name");
    }
    if (values.link === undefined) {
        errors.link = i18n.t("kiosk_item.edit.error.link");
    }
    if (values.recurrency === undefined) {
        errors.recurrency = i18n.t("kiosk_item.edit.error.recurrency");
    }
    if (values.emailAccount === undefined) {
        errors.emailAccount = i18n.t("kiosk_item.edit.error.emailAccount");
    }
    if (values.passwordAccount === undefined) {
        errors.passwordAccount = i18n.t("kiosk_item.edit.error.passwordAccount");
    }
    if (!values.cover) {
        errors.cover = i18n.t("kiosk_item.edit.error.cover");
    }
    return errors;
};


const KioskItemActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle>
                {i18n.t("sidebar.kiosk_items.title")}
            </PageTitle>
        </TopToolbar>
    );
};



export const KioskItemEdit = (props: EditProps) => {
    const redirect = (_: string, id: string) => `/kiosk_item/${id}`;
    const { record, save } = useEditController(props);

    const KioskItemToolbar = (props: any) => {
        if (record === undefined) {
            return null
        }
    
        return (
            <Toolbar className={styles.bottomToolbar} {...props}>
                <SaveButton handleSubmitWithRedirect={(values: any) => save(values)} {...props}/>
                <DeleteWithConfirmButton confirmTitle="Remover Item do Quiosque" record={record} />
             </Toolbar>
        );
    };

    return (
        <Edit
            mutationMode="pessimistic"
            className={"mid-container"}
            actions={<KioskItemActions />}
            {...props}
        >
            <SimpleForm validate={validateKioskItemEdit} redirect={redirect} toolbar={<KioskItemToolbar props={props} />} >
                <TextInput
                    source={"name"}
                    label={i18n.t("kiosk_item.edit.name")}
                    style={{ width: "50%" }}
                    required
                />
                <TextInput
                    source={"link"}
                    label={i18n.t("kiosk_item.edit.link")}
                    style={{ width: "50%" }}
                    required
                />
                <SelectInput
                    label={i18n.t("kiosk_item.edit.recurrency")}
                    source="recurrency"
                    defaultValue={"daily"}
                    choices={[
                        { id: 'daily', name: i18n.t('kiosk_item.edit.daily') },
                        { id: 'weekly', name: i18n.t('kiosk_item.edit.weekly') },
                        { id: 'monthly', name: i18n.t('kiosk_item.edit.monthly') },
                    ]}

                />
                <TextInput
                    source={"emailAccount"}
                    label={i18n.t("kiosk_item.edit.emailAccount")}
                    style={{ width: "50%" }}
                    required
                />
                <PasswordInput
                    source={"passwordAccount"}
                    label={i18n.t("kiosk_item.edit.passwordAccount")}
                    style={{ width: "50%" }}
                    required
                />
                <div className={styles.coverImageContainer}>
                    <ImageInput
                        source="cover"
                        accept="image/*"
                        label={`${i18n.t("kiosk_item.edit.cover")}`}
                        isRequired={true}
                    >
                        <ImageField source="src" className={styles.cover} />
                    </ImageInput>
                </div>
            </SimpleForm>
        </Edit>
    );
};