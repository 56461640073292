import { PartnershipCategoryCreate } from './create';
import { PartnershipCategoryEdit } from './edit';
import { PartnershipCategoryList } from './list'

// eslint-disable-next-line
export default {
    list: PartnershipCategoryList,
    create: PartnershipCategoryCreate,
    edit: PartnershipCategoryEdit
};
