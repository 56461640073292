import { firestore } from "../persistence";

const SetupTeamsService = {
    getConfig: async function () {
        const querySnapshot = await firestore.collection("pages_custom").doc("teams").get()
        return querySnapshot.data();
    },

}

export default SetupTeamsService;
