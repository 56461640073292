import { useFormState } from 'react-final-form';
import { AutocompleteArrayInput } from 'ra-ui-materialui';
import i18n from '../../../../language/i18n';

export const RelatedPostsModule = (props: any) => {
    const { values } = useFormState();

    if (["video", "podcast"].includes(values.type)) {
        return null
    }

    return (
        <AutocompleteArrayInput
            label={i18n.t("module.relatedPosts.input")}
            source={"relatedPosts"}
            fullWidth={true}
            choices={props.choices || []}
        />
    );
}