import { TopToolbar, sanitizeListRestProps } from 'react-admin';

import { BroadcastCategoryCreationButton } from '../../../sections/BroadcastCategoryCreationButton';

export const BroadcastCategoryActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;

    return (
        <TopToolbar {...sanitizeListRestProps(rest)}>
            <BroadcastCategoryCreationButton />
        </TopToolbar>
    );
};