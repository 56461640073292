import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme(
    {
        palette: {
            primary: {
                main: '#323232',
            },
            secondary: {
                light: '#0066ff',
                main: '#323232',
                contrastText: '#FFFFFF',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
        },
    }
);

const typography = theme.typography;
typography.fontFamily = "GTAmerica-Light";
typography.h1.fontFamily = "GTAmerica-Light";
typography.h2.fontFamily = "GTAmerica-Light";
typography.h3.fontFamily = "GTAmerica-Light";
typography.h4.fontFamily = "GTAmerica-Light";
typography.h5.fontFamily = "GTAmerica-Light";
typography.h6.fontFamily = "GTAmerica-Light";
typography.subtitle1.fontFamily = "GTAmerica-Light";
typography.subtitle2.fontFamily = "GTAmerica-Light";
typography.body1.fontFamily = "GTAmerica-Light";
typography.body2.fontFamily = "GTAmerica-Light";
typography.button.fontFamily = "GTAmerica-Light";
typography.caption.fontFamily = "GTAmerica-Light";
typography.overline.fontFamily = "GTAmerica-Light";

theme.typography = typography;

export default theme;
