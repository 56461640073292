import { Grid, withStyles } from "@material-ui/core";
import { Title } from '../../../../../sections/Title';
import { TextInput } from "react-admin";

import styles from "./index.module.css";
import i18n from '../../../../../language/i18n';

const MyKMHubFormGrid = withStyles({})(({ children, ...props }) => {
    return (
        <div>
            <div>
                <Grid container >
                    <Grid xs={12}>
                        <Title>{i18n.t("homepage.setup.myKMHub")}</Title>
                    </Grid>
                    <Grid container xs={12} spacing={1}>
                        <Grid item xs={12} className={[styles.myKMHubGrid].join(" ")}>
                            <TextInput
                                source={"myKMHubLink"}
                                label={i18n.t("homepage.setup.myKMHubLink")}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
)

export default MyKMHubFormGrid;