import { TextField, SimpleList, DatagridBodyProps, Record, useRedirect } from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { DateService } from "../../services/date";
import { Table } from "../Table";
import { StatusText } from "../StatusText";
import { SubTitle } from "../SubTitle";
import { Title } from "../Title";
import i18n from '../../language/i18n';

export const PostListSection = () => {
    const getTitle = (title: string) => {
        return (
            <Title>
                {title}
            </Title>
        );
    }

    const getSubTitle = (updatedDate: string | Date) => {

        let date
        if (typeof updatedDate === 'string') {
            date = new Date(updatedDate);
        } else {
            date = updatedDate;
        }

        const [interval, unit] = DateService.toInterval(date);

        return (
            <SubTitle>
                {i18n.t("default.list.lastUpdate")}: {interval} {i18n.t(`default.list.unit.${unit}`)}
            </SubTitle>
        );
    }

    const getStatusText = (text: string) => {
        return (
            <StatusText status={text}>
                {i18n.t(`posts.list.status.${text}`)}
            </StatusText>
        )
    }

    const PostRowConfig = ({ record, id }: DatagridBodyProps) => {
        const redirect = useRedirect();

        if (record === undefined) {
            return null;
        }

        return (
            <TableRow key={id} onClick={() => redirect('edit', '/post', id)}>
                <TableCell
                    key={`${id}-title`}
                    size={'medium'}

                >
                    {getTitle(record.title)}
                    <br />
                    {getSubTitle(record.updatedDate)}
                </TableCell>
                <TableCell
                    key={`${id}-status`}
                    size={'medium'}
                >
                    {getStatusText(record.status)}
                </TableCell>
            </TableRow>
        );
    };

    const PostResponsiveTable = () => {
        return (
            <SimpleList
                primaryText={(record: Record) => getTitle(record.title)}
                secondaryText={(record: Record) => getSubTitle(record.updatedDate)}
                tertiaryText={(record: Record) => getStatusText(record.status)}
                linkType={record => "edit"}
            />
        );
    }

    return (
        <Table rowConfig={<PostRowConfig />} responsiveList={<PostResponsiveTable />}>
            <TextField source="title" label={i18n.t('posts.list.header.title')} sortable={false} />
            <TextField source="status" label={i18n.t('posts.list.header.status')} sortable={false} />
        </Table>
    );
}
