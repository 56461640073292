import {
    Edit,
    EditProps,
    ImageField,
    ImageInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    TopToolbar,
} from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import RichTextInput from "../../../components/richTextInput";
import i18n from "../../../language/i18n";

import styles from "./index.module.css";

const TeamsToolbar = (props: EditProps) => (
    <Toolbar {...(props as any)}>
        <SaveButton redirect={false} />
    </Toolbar>
);

const TeamsActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.teams")} </PageTitle>
        </TopToolbar>
    );
};

export const SetupTeams = (props: EditProps) => {

    const validatePageEdit = (values: any) => {
        const errors: any = {};
        if (!values.tabLeft) {
            errors.tabLeft = i18n.t("pages.teams_config.errors.tab");
        }

        if (!values.tabCenter) {
            errors.tabCenter = i18n.t("pages.teams_config.errors.tab");
        }

        if (!values.tabRight) {
            errors.tabRight = i18n.t("pages.teams_config.errors.tab");
        }

        return errors
    };

    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            actions={<TeamsActions />}
            className={"mid-container"}
        >
            <SimpleForm validate={validatePageEdit} toolbar={<TeamsToolbar {...props} />}>
                <TextInput source={"title"} label={i18n.t("teams.title")} style={{ width: 400 }} />
                <TextInput source={"subtitle"} label={i18n.t("teams.subtitle")} multiline={true} fullWidth={true} />
                <ImageInput
                    source="background"
                    accept="image/*"
                    multiple={false}
                    label={i18n.t("teams.background")}
                    labelMultiple=""
                >
                    <ImageField source="src" />
                </ImageInput>
                <RichTextInput
                    source="tabLeft"
                    label={i18n.t("teams_config.tabLeft") as any}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                    ]}
                    initialValue={null}
                    fullWidth={true}
                    wrapperClassNames={[styles.rtfContainerTab]}
                />
                <RichTextInput
                    source="descriptionLeft"
                    label={i18n.t("teams_config.descriptionLeft") as any}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                    ]}
                    initialValue={null}
                    fullWidth={true}
                    wrapperClassNames={[styles.rtfContainerDescription]}
                />
                <RichTextInput
                    source="tabCenter"
                    label={i18n.t("teams_config.tabCenter") as any}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                    ]}
                    initialValue={null}
                    fullWidth={true}
                    wrapperClassNames={[styles.rtfContainerTab]}
                />
                <RichTextInput
                    source="descriptionCenter"
                    label={i18n.t("teams_config.descriptionCenter") as any}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                    ]}
                    initialValue={null}
                    fullWidth={true}
                    wrapperClassNames={[styles.rtfContainerDescription]}
                />
                <RichTextInput
                    source="tabRight"
                    label={i18n.t("teams_config.tabRight") as any}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                    ]}
                    initialValue={null}
                    fullWidth={true}
                    wrapperClassNames={[styles.rtfContainerTab]}
                />
                <RichTextInput
                    source="descriptionRight"
                    label={i18n.t("teams_config.descriptionRight") as any}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                    ]}
                    initialValue={null}
                    fullWidth={true}
                    wrapperClassNames={[styles.rtfContainerDescription]}
                />
            </SimpleForm>
        </Edit>
    );
};
