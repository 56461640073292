import { Sidebar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import styles from './index.module.css';

const useSidebarStyles = makeStyles({
    drawerPaper: {
        borderRight: '1px solid #e3e3e3',
        boxShadow: 'inset -7px 0px 15px -9px rgba(148,148,148,0.22)',
        marginRight: '1rem',
        width: '300px'
    },

    paperAnchorDockedBottom: {
        paper: 'red !important'
    }

});


export function CustomSidebar(props: any) {
    const classes = useSidebarStyles();

    return (
        <Sidebar classes={classes} className={styles['MuiDrawer-root']} {...props} />
    );
};