import { Edit, SimpleForm, TextInput, TopToolbar } from "ra-ui-materialui";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import styles from "./index.module.css";
import { DeleteWithConfirmButton, SaveButton, Toolbar, useEditController } from "react-admin";

const validatePartnershipCategoryEdit = (values: any) => {
    const errors: any = {};
    if (!values.name) {
        errors.name = i18n.t("partnership_category.edit.errors.name");
    }

    if (!values.description) {
        errors.description = i18n.t("partnership_category.edit.errors.description");
    }

    return errors
};

const PartnershipCategoryEditActions = ({ data }: any) => {
    if (data === undefined) {
        return null
    }

    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.partnership")} - {i18n.t("sidebar.tags")} </PageTitle>
        </TopToolbar>
    );
};



export const PartnershipCategoryEdit = (props: any) => {
    const { record, save } = useEditController(props);

    const PartnershipCategoryEditToolbar = (props: any) => {
        if (record === undefined) {
            return null
        }
    
        return (
            <Toolbar className={styles.bottomToolbar} {...props}>
                <SaveButton handleSubmitWithRedirect={(values: any) => save(values)} {...props}/>
                <DeleteWithConfirmButton confirmTitle="Remover Categoria" record={record} />
             </Toolbar>
        );
    };

    return (
        <Edit mutationMode="pessimistic" {...props} className={"mid-container"} actions={<PartnershipCategoryEditActions />}>
            <SimpleForm validate={validatePartnershipCategoryEdit} redirect={false} toolbar={<PartnershipCategoryEditToolbar props={props} />}>
                <TextInput source="name" label={i18n.t("partnership_category.edit.name")} />
                <TextInput source="description" label={i18n.t("partnership_category.edit.description")} />
            </SimpleForm>
        </Edit>
    )
};
