import { UserProfile } from "../../model/user_profile/UserProfile";
import { EntityMapper } from "../core/EntityMapper";

export class UserProfileMapper extends EntityMapper<UserProfile> {
    
    fromFirestore(data: any): UserProfile | Promise<UserProfile> {
        return Object.assign(
            new UserProfile(data.id),
            data.filename !== null ? { filename: data.filename } : {},
            data.image !== null ? { image: { src: data.image }} : {}
        )
    }

    toFirestore(data: any): object | Promise<object> {
        const image = this.isNewImage(data.image) === true ? data.image.rawFile : data.image.src;

        return Object.assign(
            {},
            data.filename !== null ? { filename: data.filename } : {},
            data.image !== null ? { image: image } : {}
        )
    }

    private isNewImage(image: any) {
        return (image && image.rawFile instanceof File) === true
    }
}