import { Edit, SelectInput, SimpleForm, TextInput, TopToolbar } from "ra-ui-materialui";
import { useCallback, useEffect, useState } from 'react';
import { DeleteWithConfirmButton, FormDataConsumer, Loading, Record, SaveButton, Toolbar, useDataProvider, useEditController, useNotify, useRefresh } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { PageTitle } from '../../../components/pageTitle';
import RichTextInput from "../../../components/richTextInput";
import i18n from '../../../language/i18n';
import { PostStatus } from '../../../model/posts/PostStatus';
import { BroadcastPublishButton } from '../../../sections/BroadcastPublishButton';
import { BroadcastScheduleButton } from '../../../sections/BroadcastScheduleButton';
import { PostPublishForm } from '../../../sections/PostPublishForm';
import { PostScheduleForm } from '../../../sections/PostScheduleForm';
import { getBroadcastTypes } from '../../../utils';
import { PodcastModule } from '../../post/modules/podcast';
import { VideoModule } from '../../post/modules/video';
import styles from './index.module.css';

const validateBroadcastEdit = (values: any) => {
    const errors: any = {};
    if (!values.title) {
        errors.title = i18n.t("broadcast.edit.errors.title");
    }

    if (!values.subTitle) {
        errors.subTitle = i18n.t("broadcast.edit.errors.subtitle");
    }

    if (!values.body) {
        errors.body = i18n.t("broadcast.edit.errors.body");
    }

    return errors
};

const TypesInput = () => {
    return (
        <SelectInput
            source="type"
            label={i18n.t("module.pageSection.carousel.postsType")}
            className={styles.input}
            choices={getBroadcastTypes()}
        />
    )
}

const TagInput = (props: any) => {
    return (
        <FormDataConsumer>
            {() => {
                const choices = props.choices ? props.choices.map((choice: any) => ({ id: choice.id, name: choice.name })) : []
                return (
                    <SelectInput
                        source="tags"
                        optionText="name"
                        choices={choices}
                    />
                );
            }}
        </FormDataConsumer >
    );
};

const BroadcastEditActions = ({ data, resource }: any) => {
    if (data === undefined) {
        return null
    }

    const { status } = data

    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.broadcast")} </PageTitle>
            <div>
                <BroadcastPublishButton data={data} resource={resource} />
                {status === PostStatus.DRAFT && <BroadcastScheduleButton data={data} />}
            </div>
        </TopToolbar>
    );
};

export const BroadcastEdit = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [tags, setTags] = useState<object[] | undefined>(undefined);
    const [broadcast, setBroadcast] = useState<Record | null>(null);
    const dataProvider = useDataProvider();
    const history = useHistory();
    const notify = useNotify();
    const refresh = useRefresh();
    const { record, save } = useEditController(props);

    const BroadcastEditToolbar = (props: any) => {
        if (record === undefined) {
            return null
        }
    
        return (
            <Toolbar className={styles.toolbar} {...props}>
                <SaveButton handleSubmitWithRedirect={(values: any) => save(values)} {...props}/>
                <DeleteWithConfirmButton confirmTitle={record.type === "podcast" ? "Remover Podcast" : "Remover Vídeo"} record={record} />
             </Toolbar>
        );
    };
    
    const fetchData = useCallback(async () => {
        try {
            const tagsRes = await dataProvider.getList('tag', { filter: { types: ["video", "podcast"] }, pagination: { perPage: 1000, page: 1 } } as any);
            const broadcastRes = await dataProvider.getOne('post', { id: props.id });
            setTags(tagsRes.data);
            setBroadcast(broadcastRes.data)
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }, [dataProvider, props.id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (loading === true) {
        return <Loading />
    };

    function onActionError() {
        history.push(`/broadcast/${broadcast!.id}`);
        notify(i18n.t("default.errors.broadcast"), 'error')
    }

    async function onActionSuccess() {
        history.push(`/broadcast/${broadcast!.id}`);
        await fetchData()
        refresh(true)
    }

    function onActionCancel() {
        history.push(`/broadcast/${broadcast!.id}`);
    }

    return (
        <Edit mutationMode="pessimistic" {...props} className={"mid-container"} actions={<BroadcastEditActions />}>
            <SimpleForm
                validate={validateBroadcastEdit}
                redirect={false}
                className={styles.container}
                toolbar={<BroadcastEditToolbar props={props} />}
            >
                <TypesInput />
                <TagInput source="tags" choices={tags} />
                <TextInput
                    source="title"
                    label={i18n.t("broadcasts.edit.title")}
                    fullWidth={true}
                />
                <TextInput
                    multiline
                    source="subTitle"
                    label={i18n.t("broadcasts.edit.subtitle")}
                    fullWidth={true}
                />
                <RichTextInput
                    source="body"
                    label={`${i18n.t("broadcasts.edit.body")}`}
                    toolbar={[
                        [{ 'header': [1, 2, 3, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link'],
                        [{ 'align': [] }]
                    ]}
                />
                <VideoModule />
                <PodcastModule />
                <Route path="/broadcast/:id/publish">
                    {({ match }) => {
                        return (
                            <PostPublishForm
                                open={!!match}
                                data={broadcast}
                                onConfirm={onActionSuccess}
                                onCancel={onActionCancel}
                                onError={onActionError}
                            />
                        );
                    }}
                </Route>
                <Route path="/broadcast/:id/schedule">
                    {({ match }) => (
                        <PostScheduleForm
                            open={!!match}
                            data={broadcast!}
                            onConfirm={onActionSuccess}
                            onCancel={onActionCancel}
                            onError={onActionError}
                        />
                    )}
                </Route>
            </SimpleForm>
        </Edit>
    )
};
