import { useState, useEffect } from "react";
import SortableTree, { addNodeUnderParent, removeNodeAtPath, changeNodeAtPath } from '@nosferatu500/react-sortable-tree';
import { PageTitle } from "../../components/pageTitle";
import i18n from "../../language/i18n";
import { SitemapActionAdd } from "./actions/add";
import { SitemapActionRemove } from "./actions/remove";
import { useNotify } from "ra-core";
import { SitemapActionEdit } from "./actions/edit";
import { Box, Button } from "@material-ui/core";
import { Loading } from "ra-ui-materialui";
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { SitemapService } from "../../services/sitemap/SitemapService";

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    }),
);

export const SitemapPage = (props: any) => {
    const notify = useNotify();
    const classes = useStyles();
    
    const [ sitemapData, setSitemapData ] = useState<Array<object> | undefined>(undefined);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ addIsOpen, setAddIsOpen ] = useState<boolean>(false);
    const [ removeIsOpen, setRemoveIsOpen ] = useState<boolean>(false);
    const [ editIsOpen, setEditIsOpen ] = useState<boolean>(false);
    const [ currentPath, setCurrentPath ] = useState<any>(undefined);
    const [ currentNode, setCurrentNode ] = useState<any>(undefined);

    const getNodeKey = ({ treeIndex } : any) => treeIndex;

    const onRemoveNode = () => {
        const data = removeNodeAtPath({
            treeData: sitemapData,
            path: currentPath,
            getNodeKey,
        });

        setSitemapData(data);
        setRemoveIsOpen(false);
    }

    const onEditNode = (response: any) => {
        const data = changeNodeAtPath({
            treeData: sitemapData,
            path: currentPath,
            getNodeKey,
            newNode: { ...currentNode, ...response },
        });

        setSitemapData(data);
        setEditIsOpen(false);
    }

    const onAddNode = (response: any) => {
        const data = addNodeUnderParent({
            treeData: sitemapData,
            parentKey: currentPath[currentPath.length - 1 || 0],
            expandParent: true,
            getNodeKey,
            newNode: response,
            addAsFirstChild: false,
        }).treeData;

        setSitemapData(data);
        setAddIsOpen(false);
    }

    const createPrimaryNode = (e: any) => {
        e.preventDefault();

        setSitemapData(sitemapData?.concat({
            title: "Novo Separador",
            value: ""
        }));
    }

    const onClickSaveSitemap = (e: any) => {
        e.preventDefault();

        return SitemapService
            .save(sitemapData!)
            .then(() => {
                notify(i18n.t("sitemap.success"));
            })
            .catch(() => {
                notify(i18n.t("sitemap.failure"));
            })
    }

    useEffect(() => {
        SitemapService
            .get()
            .then((data: any) => {
                setSitemapData(data);
                setLoading(false);
            })
    }, [])

    if (loading === true) {
        return <Loading />
    }

    return (
        <>
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12} sm={12} lg={6} xl={6}>
                    <PageTitle> {i18n.t("sidebar.sitemap.title")} </PageTitle>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} xl={6} style={{ alignSelf: "center" }}>
                    <Button
                        id="sitemap-create-menu" 
                        variant="outlined"
                        onClick={createPrimaryNode}
                    >
                        {i18n.t("sitemap.button.primary")}
                    </Button>
                </Grid>
            </Grid>
            <SitemapActionAdd 
                open={addIsOpen}
                data={sitemapData}
                onConfirm={(response: any) => onAddNode(response)}
                onCancel={() => setAddIsOpen(false)}
                onError={() => {}}
            />
            {currentNode !== undefined && 
                <SitemapActionEdit
                    open={editIsOpen}
                    data={currentNode}
                    onConfirm={(response: any) => onEditNode(response)}
                    onCancel={() => { setEditIsOpen(false); setCurrentNode(undefined) }}
                    onError={() => {}}
                />
            }
            <SitemapActionRemove
                open={removeIsOpen}
                data={sitemapData}
                onConfirm={() => onRemoveNode()}
                onCancel={() => setRemoveIsOpen(false)}
                onError={() => {}}
            />
            <SortableTree
                treeData={sitemapData}
                onChange={(treeData: Array<object>) => setSitemapData(treeData) }
                generateNodeProps={({ node, path }: any) => ({
                    buttons: [
                        <Button 
                            id="sitemap-add-button" 
                            variant="outlined"
                            onClick={() => { setCurrentPath(path); setAddIsOpen(true); }}
                        > 
                            {i18n.t("sitemap.button.add")}
                        </Button>,
                        <Button 
                            id={"sitemap-edit-button"} 
                            variant="outlined"
                            onClick={() => { setCurrentPath(path); setCurrentNode(node); setEditIsOpen(true); } }
                        > 
                            {i18n.t("sitemap.button.edit")}
                        </Button>,
                        <Button 
                            id={"sitemap-remove-button"} 
                            variant="outlined"
                            onClick={() => { setCurrentPath(path); setRemoveIsOpen(true); }}
                        > 
                            {i18n.t("sitemap.button.remove")} 
                        </Button>
                    ]
                })}
            />
            <Box>
                <Button
                    onClick={onClickSaveSitemap}
                    variant={"contained"}
                >
                    {i18n.t("sitemap.button.save")}
                </Button>
            </Box>
        </>
    );
}