import React from 'react';
import { List, ListProps } from 'react-admin';
import { PostActions } from '../actions';
import { PostFilter } from '../filter';
import { PostListSection } from "../../../sections/PostList";
import styles from './index.module.css';


export const PostList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<PostActions />}
            filters={<PostFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <PostListSection />
        </List>
    )
}

