import { UpdateParams } from "ra-core";
import { PLMJPLUS } from "../../model/pagesCustom/PLMJPLUS";
import { DocumentData, DocumentSnapshot } from "../../services/persistence";
import { ResourceService } from "../../services/resource";
import { StorageService } from "../../services/storage/StorageService";
import { EntityMapper } from "../core/EntityMapper";

export class PLMJPLUSMapper extends EntityMapper<PLMJPLUS> {
  async toFirestore(params: UpdateParams): Promise<DocumentData> {
    const { data, id } = params;
    const service = ResourceService.pages_plmj_plus;

    const res = Object.assign(
      {},
      super.toFirestore(params),
      this.toFirestoreField(data, "title"),
      this.toFirestoreField(data, "subtitle")
    ) as DocumentData;

    if(data.background && data.background.src && !data.background.rawFile ){
        res.background = data.background.src
    }

    if (
      (data.background !== undefined && this.isNewImage(data.background)) ||
      data.background === null
    ) {
      // If the image is changed, the current one must be deleted
      // Either if it's an actual deletion, or a change of photo
      await StorageService.deleteFolder(
        id as string,
        service,
        "backgroundImage"
      );

      let imageLink = null;
      if (data.background !== null) {
        // If image is not null, photo was changed
        imageLink = await this.uploadbackgroundImage(
          data.background,
          id as string
        );
      }

      res.background = imageLink;
    }

    return res;
  }

  fromFirestore(snapshot: DocumentSnapshot): PLMJPLUS {
    const data = snapshot.data();

    const res = Object.assign(
      new PLMJPLUS(),
      super.fromFirestore(snapshot),
      this.fromFirestoreField(data, "title"),
      this.fromFirestoreField(data, "subtitle")
    );

    if (data!.background) {
      res.background = { src: data!.background } as any;
    }

    return res;
  }

  private isNewImage(image: any) {
    return image && image.rawFile && image.src;
  }

  private async uploadbackgroundImage(file: any, id: string) {
    let link;

    if (file !== undefined && file && file.rawFile) {
      link = await StorageService.uploadCustomFolderImage(
        file.rawFile,
        id as string,
        "backgroundImage",
        ResourceService["pages_plmj_plus"]
      );
    }

    return link;
  }
}
