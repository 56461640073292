import React from 'react'
import { Grid, withStyles } from "@material-ui/core";
import { Title } from '../../../../../sections/Title';
import { DateInput } from "react-admin";

import styles from "./index.module.css";
import i18n from '../../../../../language/i18n';
import ImagePdfField from '../../../../../components/fields/imagePdfField';
import ImageInput from '../../../../../components/input/ImageInput';

const MenuFormGrid = withStyles({})(({ children, ...props }) => {
    return (
        <div>
            <div>
                <Grid container >
                    <Grid xs={12}>
                        <Title className={styles.menuTitle}>{i18n.t("homepage.setup.menu")}</Title>
                    </Grid>
                    <Grid item xs={12} md={12} className={styles.menuGrid} spacing={1}>
                        <ImageInput
                            source="menu"
                            accept="image/*,.pdf"
                            multiple={false}
                            label={i18n.t("homepage.setup.menuImage")}
                            labelMultiple=""
                            placeholder ={<p>{i18n.t("homepage.setup.menuPlaceholder")}</p>}
                        >
                            <ImagePdfField source="src" title="title" className={styles.test} />
                        </ImageInput>
                    </Grid>
                    <Grid container xs={12} spacing={1}>
                        <Grid item xs={12} md={6} className={styles.dateGrid}>
                            <DateInput
                                source="startDate"
                                label={i18n.t("homepage.setup.startDate")}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className={[styles.dateGrid, styles.leftMargin].join(" ")}>
                            <DateInput
                                source="endDate"
                                label={i18n.t("homepage.setup.endDate")}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
)

export default MenuFormGrid;