import React from "react";
import { Col, Row } from "antd";
import styles from './index.module.css';
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import Button from "../../../../base/button";


export interface ButtonWithLinksProps {
    data?: Array<any> 
}

export default function ButtonWithLinks (props: ButtonWithLinksProps) {
    const { isTablet } = useWindowSize();

    const onClick = (link: string) => {
        window.open(link, "_blank");
    }

    if (props.data === undefined || props.data.length === 0) {
        return null;
    }

    const [ firstButton, secondButton ] = props.data

    if (isTablet) {
        return (
            <Row>
                { firstButton &&
                    <Col className={styles.buttonContainer} span={24}>
                        <Button 
                            className={styles.button} 
                            onClick={() => onClick(firstButton.link)}
                        >
                            {firstButton.text}
                        </Button>
                    </Col>
                }
                { secondButton &&
                    <Col className={styles.buttonContainer} span={24}>
                        <Button 
                            className={styles.button} 
                            onClick={() => onClick(secondButton.link)}
                        >
                            {secondButton.text}
                        </Button>
                    </Col>
                }
            </Row>
        );
    }

    return (
        <Row>
            { firstButton &&
                    <Col className={styles.buttonContainer}>
                        <Button 
                            className={styles.button} 
                            onClick={() => onClick(firstButton.link)}
                        >
                            {firstButton.text}
                        </Button>
                    </Col>
                }
                { secondButton &&
                    <Col className={styles.buttonContainer}>
                        <Button 
                            className={styles.button} 
                            onClick={() => onClick(secondButton.link)}
                        >
                            {secondButton.text}
                        </Button>
                    </Col>
                }
        </Row>
    );
}