import { CreateParams, DeleteParams, UpdateParams } from "ra-core"
import { StatusCode } from "../core/StatusCode"
import { ResourceItemInterface } from "../../services/resource"
import { firestore } from "../../services/persistence"

export class Request {
    public createdByRef?: firebase.default.firestore.DocumentReference
    public creationDate?: Date
    public status?: StatusCode
    public details?: string
    public errorCode?: string
    public data?: any
    public kind?: string
    public action?: string
    [key: string]: any;

    static fromDataProviderCreate(resource: string, params: CreateParams) {
        return Object.assign(
            {},
            { action: 'create' },
            { kind: resource },
            { status: StatusCode.PENDING },
            { data: params.data },
            { creationDate: new Date() }
        ) as Request
    }

    static fromDataProviderUpdate(resource: string, params: UpdateParams, service: ResourceItemInterface) {
        const { requestRef, collection } = service
        const { data, id } = params

        return Object.assign(
            {},
            { action: 'update' },
            { kind: resource },
            { status: StatusCode.PENDING },
            { data: data },
            { creationDate: new Date() },
            { [requestRef]: firestore.collection(collection).doc(id as string) }
        ) as Request
    }

    static fromDataProviderDelete(resource: string, params: DeleteParams, service: ResourceItemInterface) {
        const { requestRef, collection } = service
        const { id } = params

        return Object.assign(
            {},
            { action: 'delete' },
            { kind: resource },
            { status: StatusCode.PENDING },
            { creationDate: new Date() },
            { [requestRef]: firestore.collection(collection).doc(id as string) }
        ) as Request
    }

    static fromCustomAction(resource: string, resourceId: string, action: string, service: ResourceItemInterface, data?: any) {
        const { requestRef, collection } = service

        return Object.assign(
            {},
            { action },
            { kind: resource },
            { status: StatusCode.PENDING },
            data !== undefined ? { data: data } : undefined,
            { creationDate: new Date() },
            { [requestRef]: firestore.collection(collection).doc(resourceId) }
        ) as Request
    }

}

export function isRequest(arg: any): arg is Request {
    return arg?.creationDate !== undefined && typeof arg?.creationDate === 'object' &&
        arg?.status !== undefined && typeof arg?.status === 'string' &&
        arg?.kind !== undefined && typeof arg?.kind === 'string' &&
        arg?.action !== undefined && typeof arg?.action === 'string'
}