import { SimpleForm, TopToolbar } from "ra-ui-materialui";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { DeleteWithConfirmButton, Edit, FormDataConsumer, Loading, SaveButton, SelectInput, Toolbar, useDataProvider, useEditController } from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import { EVENT_KIND } from "../../../model/events/Event";

import styles from "./index.module.css";

const validateHighlightedEventEdit = (values: any) => {
    const errors: any = {};


    if (!values.eventRef) {
        errors.eventRef = i18n.t("highlighted_event.edit.errors.event");
    }

    return errors;
};

const HighlightedEventEditActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.highlighted_event")} </PageTitle>
        </TopToolbar>
    );
};


const EventInput = (props: any) => {
    return (
        <FormDataConsumer>
            {({ formData }: any) => {
                const choices = props.choices ? props.choices.map((choice: any) => ({ id: choice.id, name: choice.title })) : []

                return (<SelectInput source="eventRef" label={i18n.t("highlighted_event.edit.event_to_highlight")} optionText={"name"} choices={choices} />)
            }}
        </FormDataConsumer >
    );
};


export const HighlightedEventEdit = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState<object[] | undefined>(undefined);
    const dataProvider = useDataProvider();
    const { record, save } = useEditController(props);


    const HighlitedEventsEditToolbar = (props: any) => {
        if (record === undefined) {
            return null
        }

        return (
            <Toolbar className={styles.bottomToolbar} {...props}>
                <SaveButton handleSubmitWithRedirect={(values: any) => save(values)} {...props}/>
                <DeleteWithConfirmButton confirmTitle="Remover Evento Destacado" record={record} />
            </Toolbar>
        );
    };

    const fetchData = useCallback(async () => {
        try {
            const eventRes = await dataProvider.getList(EVENT_KIND, { filter: { searchText: "" }, pagination: { perPage: 1000, page: 1 } } as any);

            setEvents(eventRes.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }, [dataProvider])

    useEffect(() => {
        fetchData();
    }
        , [fetchData])


    if (loading === true) {
        return <Loading />
    };
    return (
        <Edit mutationMode="pessimistic" {...props} actions={<HighlightedEventEditActions />} className={"mid-container"}>
            <SimpleForm
                validate={validateHighlightedEventEdit}
                redirect={false}
                toolbar={<HighlitedEventsEditToolbar props={props} />}
            >
                <EventInput source="eventRef" choices={events} />
            </SimpleForm>
        </Edit>
    );
};
