import { List, ListProps } from 'react-admin';
import { TeamsListSection } from '../../../sections/TeamsList';
import { TeamsActions } from '../actions';
import { TeamsFilter } from '../filter';
import styles from './index.module.css';

export const TeamsList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<TeamsActions />}
            filters={<TeamsFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <TeamsListSection />
        </List>
    )
}