import { Button, useRedirect } from 'react-admin';
import i18n from '../../language/i18n';

export const PartnershipEnableButton = ({ data }: any) => {
    const redirect = useRedirect();

    const onClick = (event: any) => {
        event.preventDefault();
        redirect(`/partnership/${data.id}/enable`);
    }

    const label = data.disabled === true ?
        i18n.t("partnerships.action.enable.label") : i18n.t("partnerships.action.disable.label")

    return (
        <Button
            id={"enable-partnership-btn"}
            label={label}
            onClick={onClick}
            disabled={false}
            variant="outlined"
            color="primary"
        />
    )

}

