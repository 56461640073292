import { UpdateParams } from "react-admin";
import { DocumentData, DocumentSnapshot } from "../../services/persistence";
import { EntityMapper } from "../core/EntityMapper";

export class OrderJobsMapper extends EntityMapper<any> {
    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        return Object.assign(
            {},
            super.toFirestore(params),
        ) as DocumentData;
    }

    fromFirestore(snapshot: DocumentSnapshot): {} {
        const data = snapshot.data();

        const jobsOrder = data?.teamsPageOrder?.map((value: string) => {
            return {
                "name": value
            }
        });

        return Object.assign(
            {},
            super.fromFirestore(snapshot),
            Array.isArray(data?.teamsPageOrder) ? { teamsPageOrder: jobsOrder } : {}
        );
    }
}