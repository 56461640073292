import { TextField, SimpleList, DatagridBodyProps, Record, useRedirect } from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Table } from "../Table";
import { Title } from "../Title";
import i18n from '../../language/i18n';

export const JobListSection = () => {
    const getTitle = (name: string) => {
        return (
            <Title>
                {name}
            </Title>
        );
    }

    const JobRowConfig = ({ record, id }: DatagridBodyProps) => {
        const redirect = useRedirect();

        if (record === undefined) {
            return null;
        }

        return (
            <TableRow key={id} onClick={() => redirect('edit', '/job_title', id)}>
                <TableCell
                    key={`${id}-name`}
                    size={'medium'}
                >
                    {getTitle(record.name)}
                </TableCell>
            </TableRow>
        );
    };

    const JobResponsiveTable = () => {
        return (
            <SimpleList
                primaryText={(record: Record) => getTitle(record.name)}
                linkType={() => "edit"}
            />
        );
    }

    return (
        <Table rowConfig={<JobRowConfig />} responsiveList={<JobResponsiveTable />}>
            <TextField source="name" label={i18n.t('sidebar.job_titles')} sortable={false} />
        </Table>
    );
}
