// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";
import firebaseConfig from '../../config/firebase.json'
firebase.initializeApp(firebaseConfig.result.sdkConfig);

export type DocumentReference = firebase.firestore.DocumentReference
export type DocumentSnapshot = firebase.firestore.DocumentSnapshot
export type CollectionReference = firebase.firestore.CollectionReference
export type QuerySnapshot = firebase.firestore.QuerySnapshot
export type QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot
export type DocumentData = firebase.firestore.DocumentData
export const FieldPath = firebase.firestore.FieldPath

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.app().functions('europe-west1');
export const storage = firebase.storage();
