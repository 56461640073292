import { Box } from "@material-ui/core";
import { useFormState } from 'react-final-form';
import RichTextInput from "../../../../components/richTextInput";
import i18n from '../../../../language/i18n';
import { Title } from '../../../../sections/Title';
import { ResourceService } from "../../../../services/resource";
import { StorageService } from "../../../../services/storage/StorageService";
import styles from "./index.module.css";

const configureQuill = (quill: any, resource: string, sectionType: string, id: any) => quill.getModule('toolbar')
    .addHandler('image', async function (_: any) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async function () {
            const file = input.files && input.files[0];

            if (file === null) {
                return;
            }

            let subFolderName
            switch (sectionType) {
                case "typography_paragraph":
                    subFolderName = "paragraph"
                    break;
                case "typography_free_text":
                default:
                    subFolderName = "free_text"
                    break;
            }

            const link = await StorageService.uploadCustomFolderImage(file, id, subFolderName, ResourceService[resource]);
            const range = quill.getSelection()

            quill.insertEmbed(range.index, 'image', link);
            // eslint-disable-next-line
        }.bind(quill)
    });

export const TypographyModule = ({ id, pageId }: any) => {
    const { values } = useFormState();
    const sectionId = id.substring(id.indexOf("[") + 1, id.lastIndexOf("]"));
    const sectionType = values && values.sections && values.sections[sectionId].type;

    const settings: { toolbar?: any[], rteStyles?: any[], configureQuill?: any, source?: string } = {}
    settings.source = sectionType.replace("typography_", "")
    switch (sectionType) {
        case "typography_title":
        case "typography_subtitle":
        case "typography_quote":
        case "typography_highlight":
            settings.toolbar = [
                ['bold', 'italic', 'underline', 'strike', 'link']
            ]
            settings.rteStyles = [styles.singleLineRtfTypographyContainer]
            break;
        case "typography_paragraph":
            settings.toolbar = [
                ['bold', 'italic', 'underline', 'strike', 'link'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }]
            ]
            break;
        case "typography_free_text":
        default:
            settings.toolbar = [
                [{ 'header': [1, 2, 3, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image'],
                [{ 'align': [] }]
            ]
            settings.configureQuill = (quill: any) => configureQuill(quill, "page", sectionType, pageId)

            if (sectionType === 'typography_free_text') {
                settings.source = "text"
            }

            break;
    }

    return (
        <>
            <Title> {i18n.t(`module.pageSection.typography.title.${sectionType}`)} </Title>
            <Box display={"flex"} flexDirection={"column"}>
                <RichTextInput
                    source={`${id}.${settings.source}[0].body`}
                    label={""}
                    toolbar={settings.toolbar}
                    configureQuill={settings.configureQuill}
                    initialValue={null}
                    wrapperClassNames={settings.rteStyles}
                />
            </Box>
        </>
    )
}