import { Box } from "@material-ui/core";
import { TextInput } from "ra-ui-materialui";

export interface LinkInputProps {
    source: string;
    label: string;
}

export const LinkInput = ({ source, label } : LinkInputProps) => {
    return (
        <Box display={"flex"} flexDirection={"column"}>
            <TextInput source={source} label={label} />
        </Box>
    )
}
