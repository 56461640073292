import {
  Edit,
  EditProps,
  ImageField,
  ImageInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar,
} from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";

import styles from "./index.module.css";

const PLMJPLUSToolbar = (props: EditProps) => (
  <Toolbar {...(props as any)}>
    <SaveButton redirect={false} />
  </Toolbar>
);

const PLMJPLUSActions = () => {
  return (
    <TopToolbar className={styles.toolbar}>
      <PageTitle> {i18n.t("sidebar.plmjplus")} </PageTitle>
    </TopToolbar>
  );
};

export const SetupPLMJPLUS = (props: EditProps) => {
  return (
    <Edit
      mutationMode="pessimistic"
      {...props}
      actions={<PLMJPLUSActions />}
      className={"mid-container"}
    >
      <SimpleForm toolbar={<PLMJPLUSToolbar {...props} />}>
        <TextInput source={"title"} label={i18n.t("plmjplus.title")} />
        <TextInput source={"subtitle"} label={i18n.t("plmjplus.subtitle")} />
        <ImageInput
          source="background"
          accept="image/*"
          multiple={false}
          label={i18n.t("plmjplus.background")}
          labelMultiple=""
        >
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
