import { generateCode } from "../../utils";
import { Entity } from "../core/Entity";
import { PageStatus } from "./PageStatus";

export class Page extends Entity {
    public title?: string
    public subtitle?: string
    public link?: string
    public status?: PageStatus;
    public tags?: Array<any>;
    public headerBackgroundImage?: string
    public headerTextColor?: string;

    static templatePage() {
        return Object.assign(
            {},
            { title: 'Título da Página' },
            { subtitle: 'Subtítulo da Página' },
            { link: `${generateCode()}` }
        ) as Page
    }

}

export const PAGE_KIND = 'page'
