import { ChipField, Create, SelectArrayInput, SimpleForm, TextInput, TopToolbar } from "ra-ui-materialui";
import ColorInput from "../../../components/ColorInput";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import { BroadcastCategory } from "../../../model/broadcast_category/BroadcastCategory";
import styles from "./index.module.css";

const validateBroadcastCategoryCreate = (values: any) => {
    const errors: any = {};

    if (!values.types || values.types.length === 0) {
        errors.types = i18n.t("broadcast_category.create.errors.types");
    }

    if (!values.name) {
        errors.name = i18n.t("broadcast_category.create.errors.name");
    }

    if (!values.description) {
        errors.description = i18n.t("broadcast_category.create.errors.description");
    }

    if (!values.color) {
        errors.color = i18n.t("broadcast_category.create.errors.color");
    }

    return errors
};

const BroadcastCategoryCreateActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.broadcast")} - {i18n.t("sidebar.tags")} </PageTitle>
        </TopToolbar>
    );
};

export const BroadcastCategoryCreate = (props: any) => {
    const redirect = (_: string, id: string) => `/broadcast_category/${id}`;

    return (
        <Create mutationMode="pessimistic" {...props} actions={<BroadcastCategoryCreateActions />} className={"mid-container"}>

            <SimpleForm validate={validateBroadcastCategoryCreate} redirect={redirect}>
                <SelectArrayInput
                    label={i18n.t("tags.create.types")}
                    choices={BroadcastCategory.getTagTypes()}
                    source="types"
                >
                    <ChipField source={"name"} />
                </SelectArrayInput>
                <TextInput source="name" label={i18n.t("broadcast_category.create.name")} />
                <TextInput source="description" label={i18n.t("broadcast_category.create.description")} />
                <ColorInput source="color" label={i18n.t("broadcast_category.create.color")} />
            </SimpleForm>
        </Create>
    )
};
