import { UpdateParams } from "react-admin";
import { City } from "../../model/city/City";
import { DocumentData, DocumentSnapshot } from "../../services/persistence";
import { EntityMapper } from "../core/EntityMapper";

export class CityMapper extends EntityMapper<City> {
    
    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data } = params
        return Object.assign(
            {},
            super.toFirestore(params),
            this.toFirestoreField(data, 'name'),
        ) as DocumentData;
    }

    fromFirestore(snapshot: DocumentSnapshot): City {
        const data = snapshot.data();

        return Object.assign(
            new City(),
            super.fromFirestore(snapshot),
            this.fromFirestoreField(data, 'name'),
        );
    }
}