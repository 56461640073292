import React from 'react'
import { Grid, withStyles } from "@material-ui/core";
import { Title } from '../../../../../sections/Title';
import { TextInput } from "react-admin";

import styles from "./index.module.css";
import i18n from '../../../../../language/i18n';

const MusicFormGrid = withStyles({})(({ children, ...props }) => {
    return (
        <div>
            <div>
                <Grid container >
                    <Grid xs={12}>
                        <Title>{i18n.t("homepage.setup.songOfTheWeek")}</Title>
                    </Grid>
                    <Grid container xs={12} spacing={1}>
                        <Grid item xs={12} className={[styles.musicGrid, styles.songTitle].join(" ")}>
                            <TextInput
                                source={"songName"}
                                label={i18n.t("homepage.setup.songOfTheWeek")}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className={[styles.musicGrid, styles.leftMargin].join(" ")}>
                            <TextInput
                                source={"songArtist"}
                                label={i18n.t("homepage.setup.songArtist")}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className={[styles.musicGrid, styles.leftMargin].join(" ")}>
                            <TextInput
                                source={"songSuggestedBy"}
                                label={i18n.t("homepage.setup.songSuggestedBy")}
                            />
                        </Grid>
                        <Grid item xs={12} className={[styles.musicGrid, styles.leftMargin].join(" ")}>
                            <TextInput
                                source={"songLink"}
                                label={i18n.t("homepage.setup.songLink")}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
)

export default MusicFormGrid;