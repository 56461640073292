import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Add from '@material-ui/icons/Add';
import { TextInput } from 'react-admin';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import i18n from '../../../../../language/i18n';
import styles from './index.module.css';

const KnowledgeSectionIterator = (props: any) => {
    const currentForm = useForm()

    function onDragEnd(result: DropResult) {
        const { source, destination } = result;

        if (!props.fields.value || !destination || !source) {
            return
        }

        // Get the item
        const item = props.fields.value[source.index];

        // Remove item from array
        const newArray = props.fields.value.filter((el: any, index: number) => index !== Number(source.index));

        // Insert item at destination
        newArray.splice(Number(destination.index), 0, item)

        currentForm.change('knowledgeSection', newArray)
    }

    function onAdd() {
        const newSections = [...props.fields.value];
        newSections.push({ title: '', link: '', description: '' });
        currentForm.change('knowledgeSection', newSections);
    }

    function onRemove(indexToRemove: number) {
        const newSections = [...props.fields.value];
        newSections.splice(indexToRemove, 1)
        currentForm.change('knowledgeSection', newSections)
    }

    return (
        <FieldArray name="knowledgeSection">
            {
                () => {
                    const inputs = props.fields.value || [{ name: '' }]
                    return (
                        <DragDropContext
                            onDragEnd={onDragEnd}
                        >
                            <TableContainer>
                                <Table className={styles.table}>
                                    <Droppable droppableId="droppable-jobs" type="LINK">
                                        {(provided) => (
                                            <TableBody
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                {inputs.map((_: string, index: number) => {
                                                    return (
                                                        <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                                            {(provided) => (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                >
                                                                    <TableCell {...provided.dragHandleProps}>
                                                                        <DragHandleIcon />
                                                                    </TableCell>
                                                                    <TableCell className={styles.inputContainer}>
                                                                        <TextInput
                                                                            source={`knowledgeSection[${index}].title`}
                                                                            fullWidth={true}
                                                                            label={i18n.t("homepage.setup.iterator.title")}
                                                                            isRequired={true}
                                                                        />
                                                                        <TextInput
                                                                            source={`knowledgeSection[${index}].link`}
                                                                            fullWidth={true}
                                                                            label={i18n.t("homepage.setup.iterator.link")}
                                                                            isRequired={true}
                                                                        />
                                                                        <TextInput
                                                                            source={`knowledgeSection[${index}].description`}
                                                                            fullWidth={true}
                                                                            label={i18n.t("homepage.setup.iterator.description")}
                                                                            isRequired={true}
                                                                            multiline={true}
                                                                            style={{ minHeight: 50 }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell className={styles.removeContainer}>
                                                                        <Button style={{ color: 'red' }} type="button" onClick={() => onRemove(index)}>
                                                                            {i18n.t("default.delete")}
                                                                        </Button>
                                                                    </TableCell>
                                                                </ TableRow>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })}
                                                {provided.placeholder}
                                            </TableBody>
                                        )}
                                    </Droppable>
                                </Table>
                                <Button
                                    type="button"
                                    onClick={onAdd}
                                    className={styles.addButton}
                                    color="secondary"
                                    variant="contained"
                                    style={{ marginTop: '16px' }}
                                >
                                    <Add />
                                </Button>
                            </TableContainer>
                        </DragDropContext>
                    )
                }
            }
        </FieldArray>
    )
}


export default KnowledgeSectionIterator;