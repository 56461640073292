import { Button, useRedirect } from 'react-admin';
import i18n from '../../language/i18n';

export const PageUpdateStatusButton = ({ data }: any) => {
    const redirect = useRedirect();

    const onClick = (event: any) => {
        event.preventDefault();
        redirect(`/page/${data.id}/update-status`);
    }

    return (
        <Button
            id={"update-status-page-btn"}
            label={i18n.t("pages.action.updateStatus.label")}
            onClick={onClick}
            disabled={false}
            variant="outlined"
            color="primary"
        />
    )

}

