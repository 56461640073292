import { GetListParams, UpdateParams } from "react-admin";
import { Schemas } from "../../model/core/Schemas";
import { Event } from "../../model/events/Event"
import { firestore, DocumentData, DocumentSnapshot } from "../../services/persistence";
import { EntityMapper } from "../core/EntityMapper"

export class BroadcastMapper extends EntityMapper<Event> {
    toFirestoreCriteria(_: GetListParams) {
        return {
            types: ["video", "podcast"],
            status: ["published", "unpublished", "draft", "archived"]
        }
    }

    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data } = params

        const res = Object.assign(
            {},
            super.toFirestore(params),
            this.toFirestoreField(data, 'title'),
            this.toFirestoreField(data, 'subTitle'),
            this.toFirestoreField(data, 'body'),
            this.toFirestoreField(data, 'status'),
            this.toFirestoreField(data, 'type'),
            this.toFirestoreField(data, 'video'),
            this.toFirestoreField(data, 'podcast'),

            data.tags ? { tags: [firestore.doc(`${Schemas.TAGS.collection}/${data.tags}`)] } : {},

        ) as DocumentData;

        return res
    }

    async fromFirestore(snapshot: DocumentSnapshot): Promise<Event> {
        const data = snapshot.data();

        const obj = Object.assign(
            new Event(),
            super.fromFirestore(snapshot),
            this.fromFirestoreField(data, 'title'),
            this.fromFirestoreField(data, 'subTitle'),
            this.fromFirestoreField(data, 'body'),
            this.fromFirestoreField(data, 'status'),
            this.fromFirestoreField(data, 'type'),
            this.fromFirestoreField(data, 'video'),
            this.fromFirestoreField(data, 'podcast'),
            data?.tags && data?.tags.length > 0 ? { tags: data?.tags[0].id } : {},
        );
        return obj;
    }

}