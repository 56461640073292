import { Create, SelectInput, SimpleForm, TextInput, TopToolbar } from "ra-ui-materialui";
import RichTextInput from '../../../components/richTextInput';
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import SetupTeamsService from "../../../services/setupTeams";

const validateTeamsCreate = (values: any) => {
    const errors: any = {};

    if (!values.name) {
        errors.name = i18n.t("teams.errors.name");
    }

    if (!values.type) {
        errors.type = i18n.t("teams.errors.type");
    }

    return errors;
};

const TeamsCreateActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.teams")} </PageTitle>
        </TopToolbar>
    );
};

export const TeamsCreate = (props: any) => {
    const redirect = (_: string, id: string) => `/teams/${id}`;

    const [ loading, setLoading ] = useState<boolean>(true);
    const [ config, setConfig ] = useState<Array<{ value: string, type: string}>|undefined>(undefined);

    useEffect(() => {
        getPageData()
        .then((data: any) => {
            const areasConfig = data.tabs.map((item: { value: string, type: string}) => {
                return { "id": item.type, "name": item.value}
            });

            setConfig(areasConfig);
            setLoading(false);
        })
        .catch((e) => {
            setLoading(false);
        });
    }, []);

    async function getPageData() {
        try {
            return await SetupTeamsService.getConfig()
        } catch (error) {
            console.log("error", error)
        }
    }

    if (loading === true) {
        return null;
    }

    return (
        <Create mutationMode="pessimistic" {...props} actions={<TeamsCreateActions />} className={"mid-container"}>
            <SimpleForm validate={validateTeamsCreate} redirect={redirect}>
                <SelectInput
                    source="type"
                    choices={config}
                    style={{ width: 400 }}
                    label={i18n.t("teams.list.label")}
                />
                <TextInput source="name" label={i18n.t("teams.name")} style={{ width: 400 }} />
                <RichTextInput
                    source="description"
                    label={`${i18n.t("teams.description")}`}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link']
                    ]}
                    initialValue={null}
                />
                <RichTextInput
                    source="details"
                    label={`${i18n.t("teams.details")}`}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link']
                    ]}
                    initialValue={null}
                />
            </SimpleForm>
        </Create>
    )
};
