import { GetListParams, UpdateParams } from "react-admin";
import { Schemas } from "../../model/core/Schemas";
import { Partnership } from "../../model/partnership/Partnership";
import { DocumentData, DocumentSnapshot, firestore } from "../../services/persistence";
import { ResourceService } from "../../services/resource";
import { StorageService } from "../../services/storage/StorageService";
import { EntityMapper } from "../core/EntityMapper";

export class PartnershipMapper extends EntityMapper<Partnership> {

    toFirestoreCriteria(_: GetListParams) {
        return { status: ["enabled", "disabled"] }
    }

    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data, id } = params
        const service = ResourceService.partnership
        const res = Object.assign(
            {},
            super.toFirestore(params),
            this.toFirestoreField(data, 'title'),
            this.toFirestoreField(data, 'description'),
            this.toFirestoreField(data, "disabled"),
            this.toFirestoreField(data, "link"),
            this.toFirestoreField(data, "location"),
            this.toFirestoreField(data, "discount"),
            this.toFirestoreField(data, "email"),
            this.toFirestoreField(data, "email_body"),
            this.toFirestoreField(data, "instructions"),
            this.toFirestoreField(data, "phone"),
            data.categories ? { categories: [firestore.doc(`${Schemas.PARTNERSHIPS.collection}/${data.categories}`)] } : {},

        ) as DocumentData;

        if ((data.image !== undefined && this.isNewImage(data.image)) || data.image === null) {
            // If the image is changed, the current one must be deleted
            // Either if it's an actual deletion, or a change of photo
            await StorageService.deleteFolder(id as string, service)

            if (data.image !== null) { // If image is not null, photo was changed
                await StorageService.uploadImage(data.image, id as string, service)
            }

            delete data.image
        }
        return res
    }

    fromFirestore(snapshot: DocumentSnapshot): Partnership {
        const data = snapshot.data();

        const obj = Object.assign(
            new Partnership(),
            super.fromFirestore(snapshot),
            this.fromFirestoreField(data, 'title'),
            this.fromFirestoreField(data, 'description'),
            this.fromFirestoreField(data, "disabled"),
            this.fromFirestoreField(data, "link"),
            this.fromFirestoreField(data, "location"),
            this.fromFirestoreField(data, "discount"),
            this.fromFirestoreField(data, "email"),
            this.fromFirestoreField(data, "email_body"),
            this.fromFirestoreField(data, "instructions"),
            this.fromFirestoreField(data, "phone"),
            data!.categories && data!.categories.length > 0 ? { categories: data?.categories[0].id } : {}
        );

        if (data!.image) {
            obj.image = { src: data!.image }
        }

        return obj
    }

    private isNewImage(image: any) {
        return image && image.rawFile && image.src
    }

}