import { TextField, SimpleList, DatagridBodyProps, Record, useRedirect, NumberField, BooleanField } from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Table } from "../Table";
import { DateService } from "../../services/date";
import { SubTitle } from "../SubTitle"; 
import { Title } from "../Title";
import i18n from '../../language/i18n';

export const PartnershipListSection = () => {
    const getTitle = (title: string) => {
        return (
            <Title>
                {title}
            </Title>
        );
    }

    const getSubTitle = (updatedDate: string | Date) => {

        let date
        if (typeof updatedDate === 'string') {
            date = new Date(updatedDate);
        } else {
            date = updatedDate;
        }

        const [interval, unit] = DateService.toInterval(date);

        return (
            <SubTitle>
                {i18n.t("default.list.lastUpdate")}: {interval} {i18n.t(`default.list.unit.${unit}`)}
            </SubTitle>
        );
    }

    const getDiscount = (discount: string) => {

        return (
            <Title>
                {discount !== undefined ? discount + "%" : discount } 
            </Title>
        );
    }

    const PartnershipRowConfig = ({ record, id }: DatagridBodyProps) => {
        const redirect = useRedirect();

        if (record === undefined) {
            return null;
        }

        return (
            <TableRow key={id} onClick={() => redirect('edit', '/partnership', id)}>
                <TableCell
                    key={`${id}-title`}
                    size={'medium'}
                >
                    {getTitle(record.title)}
                    <br />
                    {getSubTitle(record.updatedDate)}
                </TableCell>
                <TableCell
                    key={`${id}-discount`}
                    size={'medium'}
                >
                    {getDiscount(record.discount)}
                </TableCell>
                <TableCell
                    key={`${id}-disabled`}
                    size={'medium'}
                >
                    {getTitle(String(record.disabled))}
                </TableCell>
            </TableRow>
        );
    };

    const PartnershipResponsiveTable = () => {
        return (
            <SimpleList
                primaryText={(record: Record) => getTitle(record.title)}
                secondaryText={(record: Record) => getDiscount(record.discount)}
                tertiaryText={(record: Record) => getTitle(record.disabled)}
            />
        );
    }

    return (
        <Table rowConfig={<PartnershipRowConfig />} responsiveList={<PartnershipResponsiveTable />}>
            <TextField source="title" label={i18n.t('partnerships.list.header.title')} sortable={false} />
            <NumberField source="discount" label={i18n.t("partnerships.list.header.discount") } sortable={false}/>
            <BooleanField source="disabled" label={i18n.t("partnerships.list.header.disabled")} sortable={false}/>
        </Table>
    );
}
