import { Box, Grid } from "@material-ui/core";
import { Title } from '../../../../sections/Title';
import i18n from '../../../../language/i18n';
import { BooleanInput, SelectInput } from "ra-ui-materialui";
import { getBroadcastTypes, getEventTypes, getPostTypes } from "../../../../utils";
import { useFormState } from "react-final-form";

export const GridModule = ({ id, categories }: any) => {
    const { values } = useFormState();
    const sectionId = id.substring(id.indexOf("[") + 1, id.lastIndexOf("]"));
    const { postsType } = values && values.sections && values.sections[sectionId];

    const filteredCategories = categories.filter((category: any) => category.types && category.types.some((type: string) => type === postsType))
    return (
        <>
            <Title> {i18n.t("module.pageSection.grid.title")} </Title>
            <Box display={"flex"} flexDirection={"column"}>
                <Grid container spacing={2} style={{ flexGrow: 1 }}>
                    <Grid item xs={12} sm={6} lg={6} xl={6}>
                        <SelectInput
                            source={`${id}.postsType`}
                            label={i18n.t("module.pageSection.carousel.postsType")}
                            style={{ width: '100%' }}
                            choices={[...getPostTypes(), ...getBroadcastTypes(), ...getEventTypes()]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} xl={6}>
                        <SelectInput
                            source={`${id}.postsCategory`}
                            label={i18n.t("module.pageSection.carousel.postsTags")}
                            style={{ width: '100%' }}
                            allowEmpty
                            emptyText={"Todos"}
                            choices={filteredCategories || []}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} xl={6}>
                        <Title> {i18n.t("module.pageSection.grid.orderTitle")} </Title>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <SelectInput
                            source={`${id}.postsOrder`}
                            label={i18n.t("module.pageSection.grid.orderBy")}
                            choices={[
                                { id: 'asc', name: i18n.t("module.pageSection.grid.order.asc") },
                                { id: 'desc', name: i18n.t("module.pageSection.grid.order.desc") },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} xl={6}>
                        <Title> {i18n.t("module.pageSection.grid.searchbarTitle")} </Title>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <BooleanInput
                            source={`${id}.searchbar`}
                            label={i18n.t("module.pageSection.grid.searchbar")}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}