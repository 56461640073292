import { List, ListProps } from 'react-admin';
import { EventCategoryListSection } from '../../../sections/EventCategoryList';
import { EventCategoryActions } from '../actions';
import { EventCategoryFilter } from '../filter';
import styles from './index.module.css';

export const EventCategoryList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<EventCategoryActions />}
            filters={<EventCategoryFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <EventCategoryListSection />
        </List>
    )
}