import { useCallback, useEffect, useState } from "react";
import { Post } from "../model/post/Post";
import PostsService from "../services/post/postsService";

import { useDebouncedSearch } from "./useDebouncedSearch";

const defaultSize = 16;
const defaultTypes = ["not_defined", "news", "blog", "note", "video", "podcast", "event"]
export function usePosts(initialQuery?: string | null, initialTypes: string[] = defaultTypes, initialOrder?: object, initialTags?: string[] | null) {
    const [data, setData] = useState<Post[]>([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState<object | undefined>([initialOrder]);
    const [types, setTypes] = useState<string[]>(initialTypes);
    const [tags, setTags] = useState<string[] | undefined>(initialTags === null ? undefined : initialTags);

    const getPostsData = useCallback(async (query: string = "", from: number = 0, size: number = defaultSize, types: string[], sort: object = [{ field: "creationDate", order: "desc" }], tags: string[]) => {
        const criteria = {
            ...(query !== "" ? { query } : {}),
            ...(sort !== undefined ? { sort } : {}),
            ...{ types },
            ...(tags !== undefined ? { tags } : {}),
            from,
            size
        }

        try {
            const response = await PostsService.getAll(criteria);
            if (total === 0) {
                setTotal(response.data.total)
            }
            return response.data;
        } catch (e) {
            console.log('e ', e)
            return { response: [] };
        }
    }, [total]);


    const useGetPosts = () => useDebouncedSearch(getPostsData, defaultSize, initialQuery === null ? undefined : initialQuery, types, sort, tags);

    const {
        setSize,
        query,
        setQuery,
        setFrom,
        searchResults
    } = useGetPosts();

    useEffect(() => {
        setLoading(searchResults.loading)

        if (searchResults.result === undefined) {
            return
        }

        setData(searchResults.result.response)
    }, [searchResults])

    return {
        loading,
        data,
        setSize,
        query,
        setSort,
        setQuery,
        setFrom,
        setTypes,
        setTags,
        tags,
        total,
        searchResults,
        defaultSize
    }
}