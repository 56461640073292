import "../styles/global.css";
import { localeProvider } from "../language/localeProvider";
import AnonymousRoute from "../components/anonymousRoute";
import LoginPage from "../pages/login";
import { AuthProvider } from "../services/authentication/authProvider";
import AccessDeniedPage from "../pages/accessDenied";
import AuthOnlyAdmin from "../components/authOnlyAdmin";
import { Resource, RouteWithoutLayout } from "react-admin";
import { RequestProvider } from "../services/provider";
import post from "../pages/post";
import tag from "../pages/tag";
import page from "../pages/page";
import partnershipCategory from "../pages/partnershipCategory";
import partnership from "../pages/partnership";
import i18n from "../language/i18n";
import theme from "../styles/theme";
import PostAddIcon from "@material-ui/icons/PostAdd";
import LabelIcon from "@material-ui/icons/Label";
import DescriptionIcon from "@material-ui/icons/Description";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { CustomLayout } from "../sections/CustomLayout";
import "../styles/global.css";
import { SitemapPage } from "../pages/sitemap";
import { Route } from "react-router";
import "@nosferatu500/react-sortable-tree/style.css";
import event from "../pages/event";
import highlightedEvent from "../pages/highlightedEvent";
import broadcast from "../pages/broadcast";
import broadcastCategory from "../pages/broadcastCategory";
import eventCategory from "../pages/eventCategory";
import { SetupHomepage } from "../pages/pagesCustom/homepage/setupHomepage";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import { SetupPLMJPLUS } from "../pages/pagesCustom/plmjplus";
import { SetupAgenda } from "../pages/pagesCustom/agenda";
import { SetupWhoIsWho } from "../pages/pagesCustom/whoiswho";
import userProfile from "../pages/userProfile";
import city from "../pages/city";
import { SetupTeams } from "../pages/pagesCustom/teams";
import teams from "../pages/teams";
import { SetupBroadcast } from "../pages/pagesCustom/broadcast";
import jobs from "../pages/jobTitle";
import apps from "../pages/application";

import { OrderJobsPage } from "../pages/jobTitle/order";
import kiosk_item from "../pages/kiosk_item";
import PageLayout from "../pages/pagesPreview/layout";
import withAuthentication from "../pages/pagesPreview/auth/withAuthentication";

const createHistory = require("history").createBrowserHistory;
const history = createHistory();

function App() {
    return (
        <AuthOnlyAdmin
            history={history}
            i18nProvider={localeProvider}
            dataProvider={RequestProvider}
            authProvider={AuthProvider()}
            loginPage={LoginPage}
            customRoutes={[
                <AnonymousRoute
                    exact
                    path="/access-denied"
                    component={AccessDeniedPage}
                    noLayout
                />,
                <Route exact path="/sitemap" component={SitemapPage} />,
                <RouteWithoutLayout noLayout path="/page/:id/preview"  component={withAuthentication(PageLayout)} />,
            ]}
            theme={theme}
            layout={CustomLayout}
        >
            <Resource
                name="event"
                {...event}
                options={{ label: i18n.t("sidebar.events") }}
                icon={PostAddIcon}
            />
            <Resource
                name="event_category"
                {...eventCategory}
                options={{ label: i18n.t("sidebar.event_category") }}
                icon={LabelIcon}
            />
            <Resource
                name="broadcast"
                {...broadcast}
                options={{ label: i18n.t("sidebar.broadcast") }}
                icon={OndemandVideoIcon}
            />
            <Resource
                name="broadcast_category"
                {...broadcastCategory}
                options={{ label: i18n.t("sidebar.broadcast_category") }}
                icon={LabelIcon}
            />
            <Resource
                name="post"
                {...post}
                options={{ label: i18n.t("sidebar.posts") }}
                icon={PostAddIcon}
            />
            <Resource
                name="page"
                {...page}
                options={{ label: i18n.t("sidebar.pages") }}
                icon={DescriptionIcon}
            />
            <Resource
                name="partnership"
                {...partnership}
                options={{ label: i18n.t("sidebar.partnership") }}
                icon={SupervisorAccountIcon}
            />
            <Resource
                name="tag"
                {...tag}
                options={{ label: i18n.t("sidebar.tags") }}
                icon={LabelIcon}
            />
            <Resource
                name="partnership_category"
                {...partnershipCategory}
                options={{ label: i18n.t("sidebar.partnership_category") }}
                icon={LabelIcon}
            />
            <Resource
                name="highlighted_event"
                {...highlightedEvent}
                options={{ label: i18n.t("sidebar.highlighted_event") }}
                icon={LabelIcon}
            />
            <Resource
                name="pages_plmj_homepage"
                edit={SetupHomepage}
                options={{ label: i18n.t("sidebar.homepage") }}
                icon={LabelIcon}
            />
            <Resource
                name="pages_plmj_plus"
                edit={SetupPLMJPLUS}
                options={{ label: i18n.t("sidebar.plmj_plus") }}
                icon={LabelIcon}
            />
            <Resource
                name="pages_plmj_agenda"
                edit={SetupAgenda}
                options={{ label: i18n.t("sidebar.agenda") }}
                icon={LabelIcon}
            />
            <Resource
                name="pages_plmj_whoiswho"
                edit={SetupWhoIsWho}
                options={{ label: i18n.t("sidebar.whoiswho") }}
                icon={LabelIcon}
            />
            <Resource
                name="pages_plmj_teams"
                edit={SetupTeams}
                options={{ label: i18n.t("sidebar.teams_config") }}
                icon={LabelIcon}
            />
            <Resource
                name="teams"
                {...teams}
                options={{ label: i18n.t("sidebar.teams") }}
                icon={LabelIcon}
            />
            <Resource
                name="user_profile"
                {...userProfile}
                options={{ label: "quem é quem" }}
                icon={LabelIcon}
            />
            <Resource
                name="city"
                {...city}
                options={{ label: i18n.t("sidebar.city")}}
                icon={LabelIcon}
            />
            <Resource
                name="pages_plmj_broadcast"
                edit={SetupBroadcast}
                options={{ label: i18n.t("sidebar.broadcast_config") }}
                icon={LabelIcon}
            />
            <Resource
                name="job_title"
                {...jobs}
                options={{ label: i18n.t("sidebar.job_title")}}
                icon={LabelIcon}
            />
            <Resource
                name="application"
                {...apps}
                options={{ label: i18n.t("sidebar.applications.title")}}
                icon={LabelIcon}
            />
            <Resource
                name="kiosk_item"
                {...kiosk_item}
                options={{ label: i18n.t("sidebar.kiosk_item.title")}}
                icon={LabelIcon}
            />
            <Resource
                name="job_title_settings"
                edit={OrderJobsPage}
            />
        </AuthOnlyAdmin>
    );
}

export default App;
