import { Create, SimpleForm, TopToolbar } from "ra-ui-materialui";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FormDataConsumer, Loading, SelectInput, useDataProvider } from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import { EVENT_KIND } from "../../../model/events/Event";
import styles from "./index.module.css";

const validateHighlightedEventCreate = (values: any) => {
    const errors: any = {};


    if (!values.eventRef) {
        errors.eventRef = i18n.t("highlighted_event.create.errors.event");
    }

    return errors;
};

const HighlightedEventCreateActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.highlighted_event")} </PageTitle>
        </TopToolbar>
    );
};

const EventInput = (props: any) => {
    return (
        <FormDataConsumer>
            {({ formData }: any) => {
                const choices = props.choices ? props.choices.map((choice: any) => ({ id: choice.id, name: choice.title })) : []
                return (
                    <SelectInput source="eventRef" label={i18n.t("highlighted_event.create.event_to_highlight")} optionText={"name"} choices={choices} />
                )
            }}
        </FormDataConsumer >
    );
};


export const HighlightedEventCreate = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState<object[] | undefined>(undefined);
    const redirect = (_: string, id: string) => `/highlighted_event/${id}`;
    const dataProvider = useDataProvider();

    const fetchData = useCallback(async () => {
        try {
            const eventRes = await dataProvider.getList(EVENT_KIND, { filter: { searchText: "" }, pagination: { perPage: 1000, page: 1 } } as any);
            setEvents(eventRes.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }, [dataProvider])

    useEffect(() => {
        fetchData();
    }
        , [fetchData])


    if (loading === true) {
        return <Loading />
    };
    return (
        <Create mutationMode="pessimistic" {...props} actions={<HighlightedEventCreateActions />} className={"mid-container"}>
            <SimpleForm
                validate={validateHighlightedEventCreate}
                redirect={redirect}
            >
                <EventInput source="eventRef" choices={events} />
            </SimpleForm>
        </Create>
    );
};
