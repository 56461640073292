import { isBefore } from 'date-fns';
import { AutocompleteArrayInput, BooleanInput, DateTimeInput, Edit, ImageField, ImageInput, SelectInput, SimpleForm, TextInput, TopToolbar } from "ra-ui-materialui";
import { useCallback, useEffect, useState } from 'react';
import { DeleteWithConfirmButton, FormDataConsumer, Loading, Record, SaveButton, Toolbar, useDataProvider, useEditController, useNotify, useRefresh } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { PageTitle } from '../../../components/pageTitle';
import RichTextInput from '../../../components/richTextInput';
import i18n from '../../../language/i18n';
import { PostStatus } from '../../../model/posts/PostStatus';
import { EventPublishButton } from '../../../sections/EventPublishButton';
import { EventScheduleButton } from '../../../sections/EventScheduleButton';
import { PostPublishForm } from '../../../sections/PostPublishForm';
import { PostScheduleForm } from '../../../sections/PostScheduleForm';
import { FirestoreService } from '../../../services/firestore/FirestoreService';
import { UsefulLinksModule } from '../../post/modules/usefulLinks';
import styles from './index.module.css';

const validateEventEdit = (values: any) => {
    const errors: any = {};
    if (!values.title) {
        errors.title = i18n.t("events.edit.errors.title");
    }

    if (values.startDate && values.endDate) {

        if (isBefore(new Date(values.endDate), new Date(values.startDate))) {
            errors.startDate = i18n.t("events.edit.errors.invalidDates")
        }
    }

    return errors
};

const TagInput = (props: any) => {
    return (
        <FormDataConsumer>
            {() => {
                const choices = props.choices ? props.choices.map((choice: any) => ({ id: choice.id, name: choice.name })) : []
                return (
                    <SelectInput
                        source="tags"
                        optionText="name"
                        choices={choices}
                    />
                );
            }}
        </FormDataConsumer >
    );
};

const EventEditActions = ({ data, resource }: any) => {
    if (data === undefined) {
        return null
    }

    const { status } = data

    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.event")} </PageTitle>
            <div>
                <EventPublishButton data={data} resource={resource} />
                {status === PostStatus.DRAFT && <EventScheduleButton data={data} />}
            </div>
        </TopToolbar>
    );
};



export const EventEdit = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [tags, setTags] = useState<object[] | undefined>(undefined);
    const [users, setUsers] = useState<any[]>([])
    const [event, setEvent] = useState<Record | null>(null);
    const dataProvider = useDataProvider();
    const history = useHistory();
    const notify = useNotify();
    const refresh = useRefresh();
    const { record, save } = useEditController(props);

    const EventEditToolbar = (props: any) => {
        if (event === (undefined || null)) {
            return null
        }
    
        return (
            <Toolbar className={styles.toolbar} {...props}>
                <SaveButton handleSubmitWithRedirect={(values: any) => save(values)} {...props}/>
                <DeleteWithConfirmButton confirmTitle="Remover Evento" record={record} />
             </Toolbar>
        );
    };

    const fetchUsers = useCallback(async () => {
        try {
            const usersRes = await await FirestoreService.callFunction("searchUsers", { size: 500 })

            const res = usersRes.response.map((user: any) => ({
                id: user.providerId || null,
                name: user.displayName || null
            }))

            setUsers(res)
            setLoadingUsers(false);
        } catch (e) {
            setLoadingUsers(false);
        }
    }, [])

    const fetchData = useCallback(async () => {
        try {
            const tagsRes = await dataProvider.getList('tag', { filter: { types: ["event"] }, pagination: { perPage: 1000, page: 1 } } as any);
            const eventRes = await dataProvider.getOne('post', { id: props.id });
            setTags(tagsRes.data);
            setEvent(eventRes.data)
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }, [dataProvider, props.id]);

    useEffect(() => {
        fetchData();
        fetchUsers();
    }, [fetchData, fetchUsers]);

    if (loading === true) {
        return <Loading />
    };

    function onActionError() {
        history.push(`/event/${event!.id}`);
        notify(i18n.t("default.errors.event"), 'error')
    }

    async function onActionSuccess() {
        history.push(`/event/${event!.id}`);
        await fetchData()
        refresh(true)
    }

    function onActionCancel() {
        history.push(`/event/${event!.id}`);
    }

    return (
        <Edit mutationMode="pessimistic" {...props} className={"mid-container"} actions={<EventEditActions />}>
            <SimpleForm
                validate={validateEventEdit}
                redirect={false}
                className={styles.container}
                toolbar={<EventEditToolbar props={props} />}
            >
                <TagInput source="tags" choices={tags} />
                <AutocompleteArrayInput
                    label={i18n.t("events.edit.organizers")}
                    source={"organizers"}
                    fullWidth={true}
                    choices={users}
                    loading={loadingUsers}
                    disabled={loadingUsers}
                />
                <TextInput
                    source="title"
                    label={i18n.t("events.edit.title")}
                    fullWidth={true}
                />
                <TextInput
                    multiline
                    source="subTitle"
                    label={i18n.t("events.edit.subtitle")}
                    fullWidth={true}
                />
                <RichTextInput
                    source="body"
                    label={`${i18n.t("events.edit.body")}`}
                    toolbar={[
                        [{ 'header': [1, 2, 3, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link'],
                        [{ 'align': [] }]
                    ]}
                />
                <RichTextInput
                    source="instructions"
                    label={`${i18n.t("events.edit.instructions")}`}
                    toolbar={[
                        [{ 'header': [1, 2, 3, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link'],
                        [{ 'align': [] }]
                    ]}
                />
                <ImageInput
                    className={styles.imageText}
                    source="images"
                    accept="image/*"
                    label={`${i18n.t("events.edit.images.label")}`}
                    labelMultiple=""
                    multiple={false}
                >
                    <ImageField source="src" />
                </ImageInput>
                <TextInput
                    source="location.address"
                    label={i18n.t("events.edit.locationAddress")}
                    fullWidth={true}
                />
                <TextInput
                    source="location.name"
                    label={i18n.t("events.edit.locationName")}
                    fullWidth={true}
                />
                <TextInput
                    source="phone"
                    label={i18n.t("events.edit.phone")}
                    fullWidth={true}
                />
                <TextInput
                    source="email"
                    label={i18n.t("events.edit.email")}
                    fullWidth={true}
                />
                <DateTimeInput
                    source="startDate"
                    label={i18n.t("events.edit.startDate")}
                />
                <DateTimeInput
                    source="endDate"
                    label={i18n.t("events.edit.endDate")}
                />
                <BooleanInput source={`isPrivate`} label={i18n.t("events.edit.isPrivate")} />
                <UsefulLinksModule />
                <Route path="/event/:id/publish">
                    {({ match }) => {
                        return (
                            <PostPublishForm
                                open={!!match}
                                data={event}
                                onConfirm={onActionSuccess}
                                onCancel={onActionCancel}
                                onError={onActionError}
                            />
                        );
                    }}
                </Route>
                <Route path="/event/:id/schedule">
                    {({ match }) => (
                        <PostScheduleForm
                            open={!!match}
                            data={event!}
                            onConfirm={onActionSuccess}
                            onCancel={onActionCancel}
                            onError={onActionError}
                        />
                    )}
                </Route>
            </SimpleForm>
        </Edit>
    )
};
