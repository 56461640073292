import { BackTop, Tooltip } from "antd"
import React from "react"
import i18n from "../../../../../../language/i18n";

import styles from './index.module.css';


const BackToTop: React.FC = () => {

    return (
            <Tooltip title={i18n.t("backToTop.tooltip")} overlayClassName={styles.tooltip} arrowPointAtCenter={true} placement={"topLeft"}>
                <BackTop className={styles.backTop} visibilityHeight={700}/>
            </Tooltip>
    )
}

export default BackToTop;