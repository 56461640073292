import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Popper } from "../../../components/base/popper";
import { PopperForm } from "../../../components/base/popperForm";
import { PopperFormHeader } from "../../../components/base/popperFormHeader";
import { Box, TextField } from "@material-ui/core";
import i18n from "../../../language/i18n";

export type SitemapActionType = {
    onConfirm: (response?: any) => void;
    onCancel: () => void;
    onError: (error: any) => void;
    open: boolean;
    data: any;
}

export const SitemapActionAdd = (props: SitemapActionType) => { 
    const [loading, setLoading] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [link, setLink] = useState<string>("");

    const handleSubmit = async (event: React.MouseEvent) => {
        setLoading(true);
        const data = { title: title, value: link }
        props.onConfirm && props.onConfirm(data);
        setTitle("");
        setLink("");
        setLoading(false);
    }

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    };
    
    const header = (<PopperFormHeader>{i18n.t("sitemap.action.add.title")}</PopperFormHeader>);
    
    const body = (
        <Box>
            <TextField 
                value={title} 
                onChange={(event: any) => setTitle(event.target.value)}
                placeholder={i18n.t("sitemap.action.add.input")}
            />
            <br />
            <TextField 
                value={link} 
                onChange={(event: any) => setLink(event.target.value)} 
                placeholder={i18n.t("sitemap.action.add.link")}/>
        </Box>
    );

    const footer = (
        <>
            <Button onClick={handleCancel} disabled={loading}>
                {i18n.t("default.cancel")}
            </Button>
            <Button onClick={handleSubmit} color="primary" disabled={loading}>
                {i18n.t("default.confirm")}
            </Button>
        </>
    );

    return (
        <Popper
            open={props.open === true}
            anchor={document.getElementById("sitemap-add-button")}
        >
            <PopperForm
                header={header}
                body={body}
                footer={footer}
            />
        </Popper>
    );
}