import { CoreError } from "../../model/core/CoreError";
import { ErrorCode } from "../../model/core/ErrorCode";
import { AuthContainer } from "../../services/authentication/AuthContainer";
import { firestore } from "../../services/persistence";
import { ResourceItemInterface } from "../../services/resource";
import { CoreRepositoryInterface } from "../core";
import { EntityRepository } from "../entity";
import { JobTitleService } from "../../services/jobTitle";

export const JobTitleRepository: CoreRepositoryInterface = {
    ...EntityRepository,
    update: async (params: any, service: ResourceItemInterface) => {
        const currentUser = AuthContainer.getUser()

        if (currentUser === undefined) {
            throw new CoreError(ErrorCode.UNAUTHENTICATED)
        }

        const jobs =  params.data.teamsPageOrder.map((values: any) =>  values.name);
        await JobTitleService.save(jobs);

        const documentSnapshot = await firestore.collection(service.collection).doc(params.id).get();

        return documentSnapshot
    },
}


        

