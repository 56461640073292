import ptBrMessages from 'ra-language-pt-br';
import polyglotI18nProvider from "ra-i18n-polyglot";

ptBrMessages.ra.auth.username = "Utilizador";
ptBrMessages.ra.navigation.page_rows_per_page = "Resultados por página";
ptBrMessages.ra.action.refresh = "Atualizar";
ptBrMessages.ra.action.save = "Guardar";
ptBrMessages.ra.action.search = "Pesquisar";
ptBrMessages.ra.message.error = "Ocorreu um problema.";
ptBrMessages.ra.message.are_you_sure = "Tens a certeza?";
ptBrMessages.ra.message.delete_content = "Confirma que quer remover este item?";
ptBrMessages.ra.message.loading = "A página está a carregar";
ptBrMessages.ra.page.loading = "A carregar";
ptBrMessages.ra.input.file.upload_several = "Arraste imagens para fazer o upload ou pressione para selecionar uma ou mais.";
ptBrMessages.ra.input.file.upload_single = "Arraste imagens para fazer o upload ou pressione para selecionar uma.";
ptBrMessages.ra.input.image.upload_several = "Arraste imagens para fazer o upload ou pressione para selecionar uma ou mais.";
ptBrMessages.ra.input.image.upload_single = "Arraste imagens para fazer o upload ou pressione para selecionar uma.";


const messages: any = {
    'pt': ptBrMessages
};

export const localeProvider = polyglotI18nProvider((locale: string) => messages[locale], "pt");



