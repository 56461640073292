import { Entity } from "../core/Entity";
import { PostStatus } from "./PostStatus";

export class Post extends Entity {
    public title?: string
    public subTitle?: string
    public body?: string
    public images?: Array<string>;
    public scheduled?: boolean;
    public status?: PostStatus;
    public publishDate?: Date;
    public tags?: Array<any>;

    static templatePost() {
        return Object.assign(
            {},
            { title: 'Título da Publicação' },
            { subTitle: 'Subtítulo da Publicação' },
            { body: 'Conteúdo da Publicação' }
        ) as Post
    }

}

export const POST_KIND = 'post'
