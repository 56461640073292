// ./AuthOnlyAdmin.js
// Use <AuthOnlyAdmin> instead of <Admin> to avoid flash-of-dashboard when checkAuth is not fulfilled.
// See: https://github.com/marmelab/react-admin/issues/5229
import { useEffect, useState } from 'react';
import { AdminContext, AdminUI, useCheckAuth, useTimeout } from 'react-admin';

const AuthOnlyAdminUI = (props: any) => {
    const checkAuth = useCheckAuth();
    const [authChecked, setAuthChecked] = useState(false);
    const threeSecondsHasPassed = useTimeout(3000);

    useEffect(() => {
        checkAuth(undefined, undefined, undefined, true)
            .catch(() => console.log('User Not Authenticated'))
            .finally(() => setAuthChecked(true))
    }, [checkAuth, setAuthChecked]);

    if (authChecked) {
        return <AdminUI {...props} />;
    }

    if (threeSecondsHasPassed) {
        const {
            loading: LoadingPage,
            theme,
        } = props;
        return <LoadingPage theme={theme} />;
    }

    return null;
};

AuthOnlyAdminUI.defaultProps = AdminUI.defaultProps;

const AuthOnlyAdmin = (props: any) => {
    const {
        authProvider,
        dataProvider,
        i18nProvider,
        history,
        customReducers,
        customSagas,
        initialState,
        logoutButton,
        children,
        ...restProps
    } = props;
    return (
        <AdminContext
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            history={history}
            customReducers={customReducers}
            customSagas={customSagas}
            initialState={initialState}
        >
            <AuthOnlyAdminUI
                {...restProps}
                logout={authProvider ? logoutButton : undefined}
            >
                {children}
            </AuthOnlyAdminUI>
        </AdminContext>
    );
};

export default AuthOnlyAdmin;