import { Create, SimpleForm, TextInput, TopToolbar } from "ra-ui-materialui";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import styles from "./index.module.css";

const validatePartnershipCategoryCreate = (values: any) => {
  const errors: any = {};
  if (!values.name) {
    errors.name = i18n.t("partnership_category.create.errors.name");
  }
  if (!values.description) {
    errors.description = i18n.t(
      "partnership_category.create.errors.description"
    );
  }

  return errors;
};

const PartnershipCategoryCreateActions = () => {
  return (
      <TopToolbar className={styles.toolbar}>
          <PageTitle> {i18n.t("sidebar.partnership")} - {i18n.t("sidebar.tags")} </PageTitle>
      </TopToolbar>
  );
};


export const PartnershipCategoryCreate = (props: any) => {
  const redirect = (_: string, id: string) => `/partnership_category/${id}`;

  return (
    <Create mutationMode="pessimistic" {...props} actions={<PartnershipCategoryCreateActions />} className={"mid-container"}>
      <SimpleForm
        validate={validatePartnershipCategoryCreate}
        redirect={redirect}
      >
        <TextInput
          source="name"
          label={i18n.t("partnership_category.create.name")}
        />
        <TextInput
          source="description"
          label={i18n.t("partnership_category.create.description")}
        />
      </SimpleForm>
    </Create>
  );
};
