import { GetListParams, UpdateParams } from "react-admin";
import { Schemas } from "../../model/core/Schemas";
import { Event } from "../../model/events/Event"
import { firestore, DocumentData, DocumentSnapshot } from "../../services/persistence";
import { ResourceService } from "../../services/resource";
import { StorageService } from "../../services/storage/StorageService";
import { EntityMapper } from "../core/EntityMapper"

export class EventMapper extends EntityMapper<Event> {

    toFirestoreCriteria(_: GetListParams) {
        return {
            status: ["published", "unpublished", "draft", "archived"]
        }
    }

    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data, id } = params
        const service = ResourceService.post

        const res = Object.assign(
            {},
            super.toFirestore(params),
            this.toFirestoreField(data, 'title'),
            this.toFirestoreField(data, 'subTitle'),
            this.toFirestoreField(data, 'body'),
            this.toFirestoreField(data, 'status'),
            this.toFirestoreField(data, 'type'),
            this.toFirestoreField(data, 'lead'),
            this.toFirestoreField(data, 'location'),
            this.toFirestoreField(data, 'phone'),
            this.toFirestoreField(data, 'email'),
            this.toFirestoreField(data, 'phone'),
            this.toFirestoreField(data, 'instructions'),
            data.isPrivate === null ? { isPrivate: false } : this.toFirestoreField(data, 'isPrivate'),
            data.showOrganizers === null ? { showOrganizers: false } : this.toFirestoreField(data, 'showOrganizers'),
            data.organizers ? { organizers: data.organizers } : {},
            data.startDate ? { startDate: data.startDate } : {},
            data.endDate ? { endDate: data.endDate } : {},
            data.attendees ? { attendees: data.attendees.map((ref: any) => firestore.doc(`${Schemas.USERS.collection}/${ref}`)) } : {},
            data.tags ? { tags: [firestore.doc(`${Schemas.TAGS.collection}/${data.tags}`)] } : {},
            data.relatedPosts ? { relatedPosts: data.relatedPosts.map((ref: any) => firestore.doc(`${Schemas.POSTS.collection}/${ref}`)) } : {},
            data.usefulLinks ? { usefulLinks: data.usefulLinks.map((value: any) => value.link) } : {},
        ) as DocumentData;

        if ((data.images !== undefined && this.isNewImage(data.images)) || data.images === null) {
            // If the image is changed, the current one must be deleted
            // Either if it's an actual deletion, or a change of photo
            await StorageService.deleteFolder(id as string, service)

            if (data.images !== null) { // If images is not null, photo was changed
                await StorageService.uploadImage(data.images, id as string, service)
            }

            delete data.images
        }

        return res
    }

    async fromFirestore(snapshot: DocumentSnapshot): Promise<Event> {
        const data = snapshot.data();

        const obj = Object.assign(
            new Event(),
            super.fromFirestore(snapshot),
            this.fromFirestoreField(data, 'title'),
            this.fromFirestoreField(data, 'subTitle'),
            this.fromFirestoreField(data, 'body'),
            this.fromFirestoreField(data, 'status'),
            this.fromFirestoreField(data, 'type'),
            this.fromFirestoreField(data, 'lead'),
            this.fromFirestoreField(data, 'location'),
            this.fromFirestoreField(data, 'phone'),
            this.fromFirestoreField(data, 'email'),
            this.fromFirestoreField(data, 'phone'),
            this.fromFirestoreField(data, 'isPrivate'),
            this.fromFirestoreField(data, 'showOrganizers'),
            this.fromFirestoreField(data, 'instructions'),
            data?.organizers ? { organizers: data.organizers.map((org: any) => org.id) } : {},
            data!.relatedPosts ? { relatedPosts: data!.relatedPosts.map((ref: any) => ref.id) } : {},
            data?.attendees ? { attendees: data?.attendees.map((ref: any) => ref.id) } : {},
            data?.startDate ? { startDate: data?.startDate.toDate() } : {},
            data?.endDate ? { endDate: data?.endDate.toDate() } : {},
            data?.tags && data?.tags.length > 0 ? { tags: data?.tags[0].id } : {},
            data?.usefulLinks ? { usefulLinks: data?.usefulLinks.map((value: any) => ({ link: value })) } : {},
        );

        if (data!.images) {
            obj.images = data!.images.map((imageLink: string) => ({ src: imageLink }))
        }

        return obj;
    }

    private isNewImage(image: any) {
        return image && image.rawFile && image.src
    }

}