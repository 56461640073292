import { List, ListProps } from 'react-admin';
import { TagFilter } from './filter/index';
import { TagListSection } from '../../sections/TagList';
import { TagActions } from './actions';
import styles from './index.module.css';

export const TagList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<TagActions />}
            filters={<TagFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <TagListSection />
        </List>
    )
}

