import {
    Edit,
    SimpleForm,
    TextInput,
    TopToolbar
} from "ra-ui-materialui";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import styles from "./index.module.css";
import { DeleteWithConfirmButton, SaveButton, Toolbar, useEditController } from "react-admin";

const validateCityEdit = (values: any) => {
    const errors: any = {};

    if (values.name === undefined) {
        errors.name = i18n.t("cities.edit.name.error");
    }

    return errors;
};

const CitiesEditActions = ({ data }: any) => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle>
                {i18n.t("sidebar.cities")}
            </PageTitle>
        </TopToolbar>
    );
};



export const CityEdit = (props: any) => {
    const { record, save } = useEditController(props);

    const CityEditToolbar = (props: any) => {
        return (
            <Toolbar className={styles.bottomToolBar} {...props}>
                <SaveButton handleSubmitWithRedirect={(values: any) => save(values)} {...props}/>
                <DeleteWithConfirmButton confirmTitle="Remover Localização" record={record} />
             </Toolbar>
        );
    };

    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            className={"mid-container"}
            actions={<CitiesEditActions />}
        >
            <SimpleForm validate={validateCityEdit} redirect={false} toolbar={<CityEditToolbar props={props} />}>
                <TextInput 
                    source="name" 
                    label={i18n.t("cities.edit.name")}
                    helperText={i18n.t("cities.form.helper")}
                    style={{ width: "50%" }}
                    required
                />
            </SimpleForm>
        </Edit>
    );
};
