import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useFormState } from 'react-final-form';
import i18n from '../../../../language/i18n';
import { Title } from '../../../../sections/Title';
import { LinkInput } from '../../../../sections/LinkInput';

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    }),
);

export const VideoModule = (props: any) => {
    const { values } = useFormState();
    const classes = useStyles();

    if (values.type === "video") {
        return (
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Title> {i18n.t("module.video.title")} </Title>
                    <LinkInput source={"video.link"} label={i18n.t("module.video.input")} />
                </Grid>
            </Grid>
        )
    }

    return null;
}
