import { Box } from "@material-ui/core";
import { Title } from '../../../../sections/Title';
import i18n from '../../../../language/i18n';
import { ArrayInput, SimpleFormIterator, TextInput } from "react-admin";
import CustomIterator from "./customIterator";

export const MenuModule = (props: any) => {
    const sectionId = props.id && props.id.substring(props.id.indexOf("[") + 1, props.id.lastIndexOf("]"));

    function renderFormIterator() {

        if (props.menuType !== "menu_with_link") {
            return (
                <SimpleFormIterator variant={"outlined"}>
                    <TextInput source="link" fullWidth={true} label={i18n.t("module.pageSection.menu.linkValue")} />
                    <TextInput source="text" fullWidth={true} label={i18n.t("module.pageSection.menu.linkText")} />
                </SimpleFormIterator>
            )
        }

        return (
            <CustomIterator {...props} id={sectionId} />
        )
    }

    return (
        <>
            <Title> {i18n.t("module.pageSection.menu.title")} </Title>
            <Box display={"flex"} flexDirection={"column"} >
                <ArrayInput
                    source={`sections[${sectionId}].menu`}
                    label={""}
                    id={"menu-sections"}
                >
                    {renderFormIterator()}
                </ArrayInput>
            </Box>
        </>
    )
}