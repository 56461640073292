import { TopToolbar, sanitizeListRestProps } from 'react-admin';
import { ApplicationCreationButton } from '../../sections/ApplicationCreationButton';

export const ApplicationActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;

    return (
        <TopToolbar {...sanitizeListRestProps(rest)}>
            <ApplicationCreationButton />
        </TopToolbar>
    );
};
