import { ChipField, Edit, SimpleForm, TextInput, TopToolbar } from "ra-ui-materialui";
import { DeleteWithConfirmButton, SaveButton, SelectArrayInput, Toolbar, useEditController } from "react-admin";
import ColorInput from "../../../components/ColorInput";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import { BroadcastCategory } from "../../../model/broadcast_category/BroadcastCategory";
import styles from "./index.module.css";

const validateBroadcastCategoryEdit = (values: any) => {
    const errors: any = {};
    if (!values.types || values.types.length === 0) {
        errors.types = i18n.t("broadcast_category.edit.errors.types");
    }

    if (!values.name) {
        errors.name = i18n.t("broadcast_category.edit.errors.name");
    }

    if (!values.description) {
        errors.description = i18n.t("broadcast_category.edit.errors.description");
    }

    if (!values.color) {
        errors.color = i18n.t("broadcast_category.edit.errors.color");
    }

    return errors
};

const BroadcastCategoryEditActions = ({ data }: any) => {
    if (data === undefined) {
        return null
    }

    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.broadcast")} - {i18n.t("sidebar.tags")} </PageTitle>
        </TopToolbar>
    );
};



export const BroadcastCategoryEdit = (props: any) => {
    const { record, save } = useEditController(props);

    const BroadcastCategoryEditToolbar = (props: any) => {
        if (record === undefined) {
            return null
        }
    
        return (
            <Toolbar className={styles.bottomToolbar} {...props}>
                <SaveButton handleSubmitWithRedirect={(values: any) => save(values)} {...props}/>
                <DeleteWithConfirmButton confirmTitle="Remover Categoria" record={record} />
             </Toolbar>
        );
    };

    return (
        <Edit mutationMode="pessimistic" {...props} className={"mid-container"} actions={<BroadcastCategoryEditActions />}>
            <SimpleForm validate={validateBroadcastCategoryEdit} redirect={false} toolbar={<BroadcastCategoryEditToolbar props={props} />}>
                <SelectArrayInput
                    label={i18n.t("tags.edit.types")}
                    choices={BroadcastCategory.getTagTypes()}
                    source="types"
                >
                    <ChipField source={"name"} />
                </SelectArrayInput>
                <TextInput source="name" label={i18n.t("broadcast_category.edit.name")} />
                <TextInput source="description" label={i18n.t("broadcast_category.edit.description")} />
                <ColorInput source="color" label={i18n.t("tags.edit.color")} />
            </SimpleForm>
        </Edit>
    )
};
