import { useEffect } from 'react';
import {
    Button, useCreate, useNotify, useRedirect
} from 'react-admin';
import { Application, APPLICATION_KIND } from '../../model/application/Application';
import i18n from '../../language/i18n';

export function ApplicationCreationButton() {
    const [create, { data, loaded, loading, error }] = useCreate();
    const redirect = useRedirect();
    const notify = useNotify();

    useEffect(() => {
        if (error) {
            notify(i18n.t("default.errors.application"), 'error')
            return
        }

        if (loaded === false) {
            return
        }

        if (data === null || data === undefined) {
            notify(i18n.t("default.errors.application"), 'error')
            return
        }

        redirect('edit', '/application', data.id)
    }, [loaded, data, error, notify, redirect])

    async function onClick(event: any) {
        event.preventDefault()
        create(APPLICATION_KIND, Application.template())
    }

    return (
        <Button
            label={i18n.t("default.create")}
            onClick={onClick}
            disabled={loading}
            color="primary"
            variant="outlined"
        />
    )
}
