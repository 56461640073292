import { TopToolbar, sanitizeListRestProps } from 'react-admin';

import { EventCategoryCreationButton } from '../../../sections/EventCategoryCreationButton';

export const EventCategoryActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;

    return (
        <TopToolbar {...sanitizeListRestProps(rest)}>
            <EventCategoryCreationButton />
        </TopToolbar>
    );
};