import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { PopperFormHeaderProps } from "../popperFormHeader";
import styles from './index.module.css'

export type PopperFormType = {
    header: React.CElement<PopperFormHeaderProps, any>;
    body?: React.ReactNode;
    footer: React.ReactNode;
}

export const PopperForm: React.FC<PopperFormType> = (props: PopperFormType) => {
    const body = props.body && (
        <div className={styles.body}>
            <Grid item md>
                {props.body}
            </Grid>
        </div>
    )
    return (
        <FormControl component="fieldset">
            <Grid container direction="column">
                <Grid item md>
                    {props.header}
                </Grid>
                {body}
                <Grid container direction="row" justify="flex-end">
                    {props.footer}
                </Grid>
            </Grid>
        </FormControl>
    )
}
