import { List, ListProps } from 'react-admin';
import { ApplicationListSection } from '../../../sections/ApplicationList';
import { ApplicationActions } from '../actions';
import { ApplicationFilter } from '../filter';

import styles from './index.module.css';

export const ApplicationList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<ApplicationActions />}
            filters={<ApplicationFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <ApplicationListSection />
        </List>
    )
}
