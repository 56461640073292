import { UpdateParams } from "react-admin";
import { Schemas } from "../../model/core/Schemas";
import { Page } from "../../model/page/Page"
import { PageVideoType } from "../../pages/page/modules/video";
import { firestore, DocumentData, DocumentSnapshot } from "../../services/persistence";
import { ResourceService } from "../../services/resource";
import { StorageService } from "../../services/storage/StorageService";
import { EntityMapper } from "../core/EntityMapper"

export class PageMapper extends EntityMapper<Page> {

    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data, id } = params
        const service = ResourceService.page

        const res = Object.assign(
            {},
            super.toFirestore(params),
            this.toFirestoreField(data, 'title'),
            this.toFirestoreField(data, 'subtitle'),
            this.toFirestoreField(data, 'sections'),
            this.toFirestoreField(data, 'link'),
            this.toFirestoreField(data, 'status'),
            this.toFirestoreField(data, 'headerTextColor'),
            data.tags ? { tags: data.tags.map((ref: any) => firestore.doc(`${Schemas.TAGS.collection}/${ref}`)) } : {},
        ) as DocumentData;

        if ((data.headerBackgroundImage !== undefined && this.isNewImage(data.headerBackgroundImage)) || data.headerBackgroundImage === null) {
            // If the image is changed, the current one must be deleted
            // Either if it's an actual deletion, or a change of photo
            await StorageService.deleteFolder(id as string, service, "backgroundImage")

            let imageLink
            if (data.headerBackgroundImage !== null) { // If image is not null, photo was changed
                imageLink = await this.uploadbackgroundImage(data.headerBackgroundImage, id as string)
            }

            if (imageLink) {
                res.headerBackgroundImage = imageLink
            }
        }

        if (this.existSectionWithImage(data.sections)) {
            await Promise.all(data.sections.map(async (section: any) => {
                if (
                    section.type !== 'structure_image' &&
                    section.type !== 'structure_image_text' &&
                    section.type !== 'structure_text_image' &&
                    section.type !== 'structure_image_image'
                ) {
                    return Promise.resolve()
                }

                switch (section.type) {
                    case 'structure_image':
                        const file = section.image[0].src
                        if (file.src !== undefined && !file.src.startsWith("blob")) {
                            section.image[0].src = file.src
                            return
                        }
                        const link = await this.uploadSectionPhoto(file, id as string)
                        section.image[0].src = link
                        break;
                    case 'structure_image_text':
                        const imageTextFile = section.imageText[0].src
                        if (imageTextFile.src !== undefined && !imageTextFile.src.startsWith("blob")) {
                            section.imageText[0].src = imageTextFile.src
                            return
                        }
                        const imageTextLink = await this.uploadSectionPhoto(imageTextFile, id as string)
                        section.imageText[0].src = imageTextLink
                        break;
                    case 'structure_text_image':
                        const textImageFile = section.textImage[1].src
                        if (textImageFile.src !== undefined && !textImageFile.src.startsWith("blob")) {
                            section.textImage[1].src = textImageFile.src
                            return
                        }
                        const textImageLink = await this.uploadSectionPhoto(textImageFile, id as string)
                        section.textImage[1].src = textImageLink
                        break;
                    case 'structure_image_image':
                        const imageImageFile0 = section.imageImage[0].src
                        if (imageImageFile0.src !== undefined && !imageImageFile0.src.startsWith("blob")) {
                            section.imageImage[0].src = imageImageFile0.src
                        } else {
                            const imageImageLink0 = await this.uploadSectionPhoto(imageImageFile0, id as string)
                            section.imageImage[0].src = imageImageLink0
                        }
                        const imageImageFile1 = section.imageImage[1].src
                        if (imageImageFile1.src !== undefined && !imageImageFile1.src.startsWith("blob")) {
                            section.imageImage[1].src = imageImageFile1.src
                        } else {
                            const imageImageLink1 = await this.uploadSectionPhoto(imageImageFile1, id as string)
                            section.imageImage[1].src = imageImageLink1
                        }

                        break;
                    default:
                        break;
                }

            }))
        }

        if (this.existSectionVideo(data.sections)) { 
            for (const section of res.sections) {
                if (section.type === "video") {
                    switch (section.video.provider) {
                        case PageVideoType.Youtube.toLowerCase():
                            section.video.link = this.getEmbedLinkYoutube(section.video.link);
                            break;
                        case PageVideoType.Microsoft.toLowerCase():
                            section.video.link = this.getEmbedLinkMicrosoft(section.video.link);
                            break;
                        default:
                            break;
                    }
                }
            }
        }

        return res
    }

    async fromFirestore(snapshot: DocumentSnapshot): Promise<Page> {
        const data = snapshot.data();

        const obj = Object.assign(
            new Page(),
            super.fromFirestore(snapshot),
            this.fromFirestoreField(data, 'title'),
            this.fromFirestoreField(data, 'subtitle'),
            this.fromFirestoreField(data, 'sections'),
            this.fromFirestoreField(data, 'link'),
            this.fromFirestoreField(data, 'status'),
            this.fromFirestoreField(data, 'headerTextColor'),
            data!.tags ? { tags: data!.tags.map((ref: any) => ref.id) } : {}
        );

        if (data!.headerBackgroundImage) {
            obj.headerBackgroundImage = { src: data!.headerBackgroundImage }
        }

        if (data && this.existSectionWithImage(data.sections)) {
            data.sections.forEach((section: any) => {
                if (
                    section.type !== 'structure_image' &&
                    section.type !== 'structure_image_text' &&
                    section.type !== 'structure_text_image' &&
                    section.type !== 'structure_image_image'
                ) {
                    return
                }

                switch (section.type) {
                    case 'structure_image':
                        section.image[0].src = { src: section.image[0].src }
                        break;
                    case 'structure_image_text':
                        section.imageText[0].src = { src: section.imageText[0].src }
                        break;
                    case 'structure_text_image':
                        section.textImage[1].src = { src: section.textImage[1].src }
                        break;
                    case 'structure_image_image':
                        section.imageImage[0].src = { src: section.imageImage[0].src }
                        section.imageImage[1].src = { src: section.imageImage[1].src }
                        break;
                    default:
                        break;
                }

            })
        }

        return obj;
    }

    private isNewImage(image: any) {
        return image && image.rawFile && image.src
    }

    private existSectionWithImage(sections: any[]) {
        return sections && sections.some(section =>
            section.type === 'structure_image' ||
            section.type === 'structure_image_text' ||
            section.type === 'structure_text_image' ||
            section.type === 'structure_image_image'
        )
    }

    private existSectionVideo(sections: any[]) {
        return sections && sections.some(section => section.type === 'video');
    }

    private getMicrosoftId(link: string) {
        // eslint-disable-next-line
        let regex = /(https?:\/\/web.microsoftstream.com\/(video|channel)\/([\w\-]+))/;

        if (link.includes('embed')) {
            // eslint-disable-next-line
            regex = /(https?:\/\/web\.microsoftstream\.com\/embed\/(video|channel)\/([\w\-]+))/;
        }

	    const matches = regex.exec(link);

        if (matches && matches.length > 1) {
            return matches[matches.length - 1];
        }

        return undefined;
    }

    private getEmbedLinkMicrosoft(link: string) {
        const baseUrl = `https://web.microsoftstream.com/embed/video/`;
        const videoId = this.getMicrosoftId(link);

        return `${baseUrl}${videoId}?autoplay=false&showinfo=true`;
    }

    private getEmbedLinkYoutube(link: string) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = link.match(regExp);
        const videoId = (match && match[2].length === 11) ? match[2] : null;

        return `https://www.youtube.com/embed/${videoId}`;
    }

    private async uploadSectionPhoto(file: any, id: string) {
        let link

        if (file !== undefined && file && file.rawFile) {
            link = await StorageService.uploadCustomFolderImage(file.rawFile, id as string, "imageSection", ResourceService["page"])
        }

        return link
    }

    private async uploadbackgroundImage(file: any, id: string) {
        let link

        if (file !== undefined && file && file.rawFile) {
            link = await StorageService.uploadCustomFolderImage(file.rawFile, id as string, "backgroundImage", ResourceService["page"])
        }

        return link
    }

}