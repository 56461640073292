import { List, ListProps } from 'react-admin';
import { JobListSection } from '../../../sections/JobsList';
import { JobActions } from '../actions';
import { JobsFilter } from '../filter';

import styles from './index.module.css';

export const JobList = (props: ListProps) => {
    return (
        <List {...props}
            bulkActionButtons={false}
            actions={<JobActions />}
            filters={<JobsFilter />}
            className={["mid-container", styles.filterContainer].join(" ")}
        >
            <JobListSection />
        </List>
    )
}
