export abstract class Entity {
    id?: string;
    creationDate: Date;
    updatedDate: Date;

    constructor(id?: string) {
        this.id = id;
        this.creationDate = new Date();
        this.updatedDate = new Date();
    }

}
