import { cloneElement } from 'react';
import { TopToolbar, useListContext, sanitizeListRestProps } from 'react-admin';
import { PartnershipCreationButton } from '../../../sections/PartnershipCreationButton';

export const PartnershipActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;

    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
    } = useListContext();

    return (
        <TopToolbar {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <PartnershipCreationButton />
        </TopToolbar>
    );
};
