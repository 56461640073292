import { UpdateParams } from "ra-core";
import { Schemas } from "../../model/core/Schemas";
import { HighlightedEvent } from "../../model/highlightedEvent/HighlightedEvent";
import { DocumentData, DocumentSnapshot, firestore } from "../../services/persistence";
import { EntityMapper } from "../core/EntityMapper";


export class HighlightedEventMapper extends EntityMapper<HighlightedEvent> {

    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data } = params

        return Object.assign(
            {},
            super.toFirestore(params),
            data.eventRef && { eventRef: firestore.doc(`${Schemas.POSTS.collection}/${data.eventRef}`) }
        ) as DocumentData;
    }

    fromFirestore(snapshot: DocumentSnapshot): HighlightedEvent {
        const data = snapshot.data()

        return Object.assign(
            new HighlightedEvent(),
            super.fromFirestore(snapshot),
            data?.eventRef && { eventRef: data.eventRef.id }
        )
    }
}