import { Record, useUpdate } from 'react-admin';
import Button from '@material-ui/core/Button';
import { PopperFormHeader } from '../../components/base/popperFormHeader';
import { PARTNERSHIP_KIND } from '../../model/partnership/Partnership';
import { Popper } from '../../components/base/popper';
import { PopperForm } from '../../components/base/popperForm';
import i18n from '../../language/i18n';
import { useEffect } from 'react';

export type PartnershipEnableFormProps = {
    onLoadingStarts?: () => void;
    onConfirm: (response?: any) => void;
    onCancel: () => void;
    onError: (error?: any) => void;
    open: boolean;
    data: Record;
}

export function PartnershipEnableForm(props: PartnershipEnableFormProps) {
    const scheduleBtnElement = document.getElementById("enable-partnership-btn")
    const [update, { data, loaded, loading, error }] = useUpdate();
    const isEnabled = props.data.disabled === false

    useEffect(() => {

        if (error) {
            props.onError(error)
            return
        }

        if (loaded === false) {
            return
        }

        if (data === null || data === undefined) {
            props.onError()
            return
        }

        props.onConfirm()

    }, [loaded, loading, data, error, props])

    async function createRequest(disabled: boolean) {
        const data = Object.assign({}, props.data, { disabled })
        update(PARTNERSHIP_KIND, props.data.id as string, data)
    }

    const handleSubmit = async (event: React.MouseEvent) => {
        event.preventDefault();
        props.onLoadingStarts && props.onLoadingStarts();

        if (isEnabled) {
            createRequest(true)
        } else {
            createRequest(false)
        }
    };

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    };

    const header = (
        <PopperFormHeader>
            {isEnabled ? i18n.t("partnerships.action.disable.description") : i18n.t("partnerships.action.enable.description")}
        </PopperFormHeader>
    );

    const footer = (
        <>
            <Button onClick={handleCancel} disabled={loading}>
                {i18n.t("default.no")}
            </Button>
            <Button onClick={handleSubmit} color="primary" disabled={loading}>
                {i18n.t("default.yes")}
            </Button>
        </>
    );

    return (
        <Popper
            open={props.open === true}
            anchor={scheduleBtnElement}
        >
            <PopperForm
                header={header}
                footer={footer}
            />
        </Popper>
    );
}
