import { TextProps, Text } from "../../components/base/text";
import styles from "./index.module.css";

export const SubTitle = (props: TextProps) => {
    return (
        <Text className={styles.subTitle}>
            {props.children}
        </Text>
    )
}
