import { useState, useEffect } from 'react';
import { Edit, ImageField, ImageInput, RadioButtonGroupInput, SimpleForm, TextInput, Toolbar } from "ra-ui-materialui";
import { useDataProvider, Loading, useNotify, useRefresh, TopToolbar, SaveButton } from 'react-admin';
import i18n from '../../../language/i18n';
import { useCallback } from 'react';
import { useHistory, Route } from 'react-router';
import { PageUpdateStatusForm } from '../../../sections/PageUpdateStatusForm';
import { PageUpdateStatusButton } from '../../../sections/PageUpdateStatusButton';
import styles from './index.module.css';
import { PageTitle } from '../../../components/pageTitle';
import { PageSectionModule } from '../modules/pageSection';
import RichTextInput from '../../../components/richTextInput';
import { InputAdornment } from "@material-ui/core";
import { PageStatus } from '../../../model/page/PageStatus';

const validatePageEdit = (values: any) => {
    const errors: any = {};
    if (!values.title) {
        errors.title = i18n.t("pages.edit.errors.title");
    }

    if (!values.link) {
        errors.link = i18n.t("pages.edit.errors.link");
    }

    return errors
};

const PageEditActions = ({ data, resource }: any) => {
    if (data === undefined) {
        return null
    }

    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.pages")} </PageTitle>
            <PageUpdateStatusButton data={data} resource={resource} />
        </TopToolbar>
    );
};

export const PageEdit = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [tags, setTags] = useState<object[] | undefined>(undefined);
    const [posts, setPosts] = useState<object[] | null>(null);
    const [page, setPage] = useState<any | undefined>(undefined);

    const dataProvider = useDataProvider();
    const history = useHistory()
    const notify = useNotify()
    const refresh = useRefresh()

    const fetchData = useCallback(async () => {
        try {
            const pageRes = await dataProvider.getOne('page', { id: props.id });
            const tagsRes = await dataProvider.getList('tag', { filter: { types: ["news", "note", "blog", "event", "video", "podcast"] }, pagination: { perPage: 1000, page: 1 } } as any);
            const postsRes = await dataProvider.getList('post', { filter: { searchText: "" }, pagination: { perPage: 1000, page: 1 } } as any);

            setTags(tagsRes.data);
            setPage(pageRes.data);
            setPosts(postsRes.data.map((p: any) => { return Object.create({ id: p.id, name: p.title }) }));

            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }, [dataProvider, props.id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    function onActionError() {
        history.push(`/page/${page!.id}`);
        notify(i18n.t("default.errors.page"), 'error')
    }

    async function onActionSuccess() {
        history.push(`/page/${page!.id}`);
        await fetchData()
        refresh(true)
    }

    function onActionCancel() {
        history.push(`/page/${page!.id}`);
    }

    if (loading === true) {
        return <Loading />
    };
    return (
        <Edit
            mutationMode="pessimistic" {...props}
            actions={<PageEditActions />}
            className={"mid-container"}
        >
            <SimpleForm
                validate={validatePageEdit}
                redirect={false}
                toolbar={<Toolbar alwaysEnableSaveButton>
                    <SaveButton />
                    {page.status !== PageStatus.PUBLISHED &&
                        <SaveButton
                            className={styles.savePreviewButton}
                            label={i18n.t("pages.edit.previewButton")}
                            id={"preview-page-btn"}
                            variant="outlined"
                            color="primary"
                            onSuccess={() => {
                                window.open(`/page/${page.id}/preview`, '_blank', 'noopener,noreferrer');
                            }} />
                    }
                </Toolbar>}
            >
                <TextInput
                    source="link"
                    fullWidth={true}
                    label={i18n.t("pages.edit.link")}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{i18n.t("default.base.url")}</InputAdornment>,
                    }}
                />
                <RichTextInput
                    source="title"
                    label={`${i18n.t("pages.edit.title")}`}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike', 'link']
                    ]}
                    initialValue={null}
                    wrapperClassNames={[styles.rtfContainerMinHeight]}
                />
                <RichTextInput
                    source="subtitle"
                    label={`${i18n.t("pages.edit.subtitle")}`}
                    toolbar={[
                        ['bold', 'italic', 'underline', 'strike', 'link']
                    ]}
                    initialValue={null}
                    wrapperClassNames={[styles.rtfContainerMinHeight]}
                />
                <ImageInput
                    source="headerBackgroundImage"
                    accept="image/*"
                    multiple={false}
                    label={i18n.t("pages.edit.headerBackgroundImage")}
                    labelMultiple=""
                >
                    <ImageField source="src" />
                </ImageInput>
                <RadioButtonGroupInput
                    label={i18n.t("pages.edit.headerTextColor")}
                    source="headerTextColor"
                    defaultValue={"#000000"}
                    choices={[
                        { id: '#000000', name: i18n.t("pages.edit.headerTextColorOptions.black") },
                        { id: '#FFFFFF', name: i18n.t("pages.edit.headerTextColorOptions.white") }
                    ]}
                />
                <PageSectionModule pageId={page.id} posts={posts} categories={tags} />
                <Route path="/page/:id/update-status">
                    {({ match }) => {
                        return (
                            <PageUpdateStatusForm
                                open={!!match}
                                data={page}
                                onConfirm={onActionSuccess}
                                onCancel={onActionCancel}
                                onError={onActionError}
                            />
                        );
                    }}
                </Route>
            </SimpleForm>
        </Edit>
    )
};
