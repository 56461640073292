import i18n from "../../language/i18n";
import { Entity } from "../core/Entity"

export class Tag extends Entity {
    public types?: string[]
    public name?: string
    public description?: string
    public color?: string

    static getTagTypes() {
        return [
            { id: 'news', name: i18n.t("post.type.news") },
            { id: 'blog', name: i18n.t("post.type.blog") },
            { id: 'note', name: i18n.t("post.type.note") },
        ];
    }

}