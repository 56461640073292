import { Col, Dropdown, Layout, Menu, Row } from "antd";
import { default as React, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import i18n from "../../../../../../language/i18n";
import { App } from "../../../../model/app/App";
import { AuthUser } from "../../../../model/auth/AuthUser";
import AppsIcon from "../../../base/icon/apps";
import BurgerIcon from "../../../base/icon/burger";
import IntouchIcon from "../../../base/icon/intouch";
import NotificationIcon from "../../../base/icon/notification";
import SearchIcon from "../../../base/icon/search";
import B2 from "../../../base/text/b2";
import styles from "./index.module.css";

export interface HeaderProps {
    className?: string;
    user?: AuthUser;
    onSelectSidebar?: (open: boolean) => void;
    apps?: Array<App>;
}



const settingsMenu = (
    <Menu className={styles.menu}>
        <Menu.Item key="0" className={styles.logout}>
            <a className={"noDecoration"} href="/logout" onClick={undefined}>
                {" "}
                {i18n.t("homePage.logout")}{" "}
            </a>
        </Menu.Item>
    </Menu>
);


const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
    const [headerBackgroundTransparency, setheaderBackgroundTransparency] =
        useState<boolean>(false);
    const history = useHistory();
    const isResultsPageCurrent = history.location.pathname === "/resultados";



    useEffect(() => {
        changeBackground();
        window.addEventListener("scroll", changeBackground);
    });

    function changeBackground() {
        if (window.scrollY >= 1) {
            setheaderBackgroundTransparency(false);
        } else {
            setheaderBackgroundTransparency(true);
        }
    }


    const notificationMenu = (
        <div
            className={[
                styles.menuContainer
            ].join(" ")}
        >
            <Menu className={styles.menu}>
            </Menu>

            <Row className={styles.seeAllCategories}>
                <Col span={24}>
                    <Link to="/categorias" onClick={()=>{}}>
                        <B2>{i18n.t("header.notifications.seeCategories")}</B2>
                    </Link>
                </Col>
            </Row>
        </div>
    );

    const isLightText = document
        .getElementById("mainHeader")
        ?.classList.contains("lightTextHeader");
    const isDarkText = document
        .getElementById("mainHeader")
        ?.classList.contains("darkTextHeader");
    let headerClasses = [
        styles.header,
        isLightText ? "lightTextHeader" : null,
        isDarkText ? "darkTextHeader" : null,
    ];
    let icon = <BurgerIcon onClick={undefined} />;



    return  (
            <Layout.Header
                className={headerClasses.join(" ")}
                style={{
                    padding: 0,
                    zIndex: 999,
                    position: "fixed",
                    width: "100vw",
                    backgroundColor: headerBackgroundTransparency
                        ? "transparent"
                        : "white",
                }}
                id="mainHeader"
            >
                <div className={styles.headerGrid}>
                    {React.cloneElement(icon, {
                        className: [
                            styles.icon,
                            styles.end,
                            styles.burger,
                            styles.desktop,
                        ].join(" "),
                    })}
                    <div className={styles.menuGrid}>
                        {React.cloneElement(icon, {
                            className: [
                                styles.icon,
                                styles.end,
                                styles.burger,
                                styles.mobile,
                            ].join(" "),
                        })}
                        <IntouchIcon
                            onClick={undefined}
                            className={[styles.icon, styles.logo, styles.start].join(" ")}
                        />
                    </div>
                    <div className={styles.appsGrid}>
                        <SearchIcon
                            className={[
                                styles.icon,
                                styles.search,
                                isResultsPageCurrent && styles.hideSearchIcon,
                            ].join(" ")}
                            onClick={undefined}
                        />
                        <Dropdown
                            overlay={notificationMenu}
                            onVisibleChange={undefined}
                            placement="bottomCenter"
                            overlayClassName={styles.dropdown}
                            trigger={[]}
                        >
                            <div className={styles.notificationIconContainer}>
                                <NotificationIcon
                                    className={[styles.icon, styles.notificationIcon].join(" ")}
                                    onClick={(undefined)}
                                />
                            </div>
                        </Dropdown>
                        <Dropdown
                            overlay={settingsMenu}
                            placement="bottomCenter"
                            overlayClassName={[styles.dropdown, styles.settings].join(" ")}
                            trigger={["click"]}
                        >
                            <></>
                        </Dropdown>
                    </div>
                    <Dropdown
                        overlay={<></>}
                        placement="bottomRight"
                        overlayClassName={styles.appsDropdown}
                        trigger={["click"]}
                    >
                        <AppsIcon
                            className={[styles.icon, styles.apps].join(" ")}
                            onClick={(e: any) => e.preventDefault()}
                        />
                    </Dropdown>
                </div>
            </Layout.Header>
    ) 
};


export default Header;