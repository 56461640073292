import React from 'react';
import { Layout } from 'antd';


import { AuthUser } from '../../model/auth/AuthUser';
import Header from '../../components/sections/standalone/header';
import Footer from '../../components/sections/footer';
import { App } from '../../model/app/App';
import BackToTop from '../../components/sections/standalone/backToTop';
import 'antd/dist/antd.css';
// import './global.css';
import styles from './index.module.css';


const { Content } = Layout;

export type WithAuthenticationState = { authUser: AuthUser, open: boolean, sidebar: Array<object>, headerApps: Array<App> }

/**
 * High Order Component that passed down authentication information to all interested parties
 * 
 * @param Component the component to pass down
 */
const withAuthentication = (Component: any) => {
    class WithAuthentication extends React.Component<any, WithAuthenticationState> {

        constructor(props: any) {
            super(props);
            this.state = {
                authUser: {
                    accessDenied: false,
                    loading: true,
                    auth: null,
                    claims: undefined
                },
                open: false,
                sidebar: [],
                headerApps: []
            };
        }


        toogleSidebar = (open: boolean) => {
            document.body.style.overflow = open ? 'hidden' : 'unset';
            this.setState({ open: open });
        }

        render() {

            return (
                <div className={styles.container} style={{minHeight: "100vh", display:"flex", flexDirection:"column", margin: 0}}>
                    <Header
                        user={this.state.authUser}
                        onSelectSidebar={(open: boolean) => this.toogleSidebar(open)}
                        apps={this.state.headerApps}
                    />
                    <Content>
                            <Component {...this.props} />
                    </Content>
                            <BackToTop/>
                    <Footer />
                </div>
            );
        }
    }

    return WithAuthentication;
};

export default withAuthentication;