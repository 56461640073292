import { Login } from 'ra-ui-materialui';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import azureConfig from '../../config/azure.json';
import i18n from '../../language/i18n';
import { auth } from '../../services/persistence';

// Configure FirebaseUI.
const uiConfig: any = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
        {
            provider: 'microsoft.com',
            customParameters: {
                prompt: "login",
                tenant: azureConfig.tenantId
            },
            fullLabel: i18n.t("default.login")
        }
    ]
};

export default function LoginPage({ ...props }) {
    return (
        <Login {...props}>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </Login>
    );
}