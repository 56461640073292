import { Mapper } from "../../mappers/core/Mapper";
import { CoreError } from "../../model/core/CoreError";
import { ErrorCode } from "../../model/core/ErrorCode";
import { Schemas } from "../../model/core/Schemas";
import { AuthContainer } from "../../services/authentication/AuthContainer";
import { FirestoreService } from "../../services/firestore/FirestoreService";
import { firestore } from "../../services/persistence";
import { CoreRepositoryInterface } from "../core";
import { Request } from "../../model/request/Request";
import { ResourceItemInterface } from "../../services/resource";

export const EntityRepository: CoreRepositoryInterface = {
    getOne: async (id: string, collection: string, mapper: Mapper<any>) => {
        const documentSnapshot = await firestore.collection(collection).doc(id).get();

        if (!documentSnapshot.exists) {
            throw new CoreError(ErrorCode.NOT_EXISTS);
        }

        const object = await mapper.fromFirestore(documentSnapshot);

        return {
            data: {
                id: id,
                ...object
            } as any
        };
    },
    create: async (params: any, requestKind: string, mapper: Mapper<any>) => {
        const currentUser = AuthContainer.getUser();
        
        if (currentUser === undefined) {
            throw new CoreError(ErrorCode.UNAUTHENTICATED)
        }

        params.data = await mapper.toFirestore(params);
        const request = Request.fromDataProviderCreate(requestKind, params);

        request.createdByRef = firestore.collection(Schemas.USERS.collection).doc(currentUser.id as string)

        //This gives time for the creation 
        //to be indexed in elastic search 
        await timeout(2000);

        return await FirestoreService.onRequestSnapshot(request)
    },
    update: async (params: any, service: ResourceItemInterface) => {
        const currentUser = AuthContainer.getUser()

        if (currentUser === undefined) {
            throw new CoreError(ErrorCode.UNAUTHENTICATED)
        }

        params.data = await service.mapper.toFirestore(params);
        const request = Request.fromDataProviderUpdate(service.requestKind, params, service)

        request.createdByRef = firestore.collection(Schemas.USERS.collection).doc(currentUser.id as string)
        await FirestoreService.onRequestSnapshot(request)

        const latestSnapshot = await firestore.collection(service.collection).doc(params.id as string).get();
        // This gives time for the update 
        // to be indexed in elastic search
        await timeout(4000);

        return latestSnapshot
    },
    delete: async (params: any, service: ResourceItemInterface) => {
        const currentUser = AuthContainer.getUser()

        if (currentUser === undefined) {
            throw new CoreError(ErrorCode.UNAUTHENTICATED)
        }

        const request = Request.fromDataProviderDelete(service.requestKind, params, service)
        request.createdByRef = firestore.collection(Schemas.USERS.collection).doc(currentUser.id as string)
        await FirestoreService.onRequestSnapshot(request)

        //This gives time for the deletion 
        //to be indexed in elastic search 
        //before redirecting to the listing page
        await timeout(2000);

        return;
    }
}

const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));