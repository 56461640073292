import i18n from "../../language/i18n";
import { Entity } from "../core/Entity"

export class BroadcastCategory extends Entity {
    public name?: string
    public description?: string
    public color?: string

    static getTagTypes() {
        return [

            { id: 'video', name: i18n.t("post.type.video") },
            { id: 'podcast', name: i18n.t("post.type.podcast") },

        ];
    }
}
export const BROADCAST_CATEGORY_KIND = 'broadcast_category'