import { TextField, SimpleList, DatagridBodyProps, Record, useRedirect } from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { DateService } from "../../services/date";
import { Table } from "../Table";
import { SubTitle } from "../SubTitle";
import { Title } from "../Title";
import i18n from '../../language/i18n';
import { ColorText } from '../../components/ColorText';

export const TagListSection = () => {
    const getTitle = (name: string) => {
        return (
            <Title>
                {name}
            </Title>
        );
    }

    const getSubTitle = (updatedDate: string | Date) => {

        let date
        if (typeof updatedDate === 'string') {
            date = new Date(updatedDate);
        } else {
            date = updatedDate;
        }

        const [interval, unit] = DateService.toInterval(date);

        return (
            <SubTitle>
                {i18n.t("default.list.lastUpdate")}: {interval} {i18n.t(`default.list.unit.${unit}`)}
            </SubTitle>
        );
    }

    const getColorText = (color: string) => {
        return (
            <ColorText color={color} />
        )
    }

    const TagRowConfig = ({ record, id }: DatagridBodyProps) => {
        const redirect = useRedirect();

        if (record === undefined) {
            return null;
        }

        return (
            <TableRow key={id} onClick={() => redirect('edit', '/tag', id)}>
                <TableCell
                    key={`${id}-name`}
                    size={'medium'}

                >
                    {getTitle(record.name)}
                    <br />
                    {getSubTitle(record.updatedDate)}
                </TableCell>
                <TableCell
                    key={`${id}-color`}
                    size={'medium'}
                >
                    {getColorText(record.color)}
                </TableCell>
            </TableRow>
        );
    };

    const TagResponsiveTable = () => {
        return (
            <SimpleList
                primaryText={(record: Record) => getTitle(record.name)}
                secondaryText={(record: Record) => getSubTitle(record.updatedDate)}
                tertiaryText={(record: Record) => getColorText(record.color)}
                linkType={record => "edit"}
            />
        );
    }

    return (
        <Table rowConfig={<TagRowConfig />} responsiveList={<TagResponsiveTable />}>
            <TextField source="name" label={i18n.t('tags.list.header.name')} sortable={false} />
            <TextField source="color" label={i18n.t('tags.list.header.color')} sortable={false} />
        </Table>
    );
}
