import i18n from "../../language/i18n";
import { CoreError } from "../../model/core/CoreError";
import { ErrorCode } from "../../model/core/ErrorCode";
import { Schemas } from "../../model/core/Schemas";
import { StatusCode } from "../../model/core/StatusCode";
import { Request } from "../../model/request/Request";
import { firestore, functions } from "../persistence";

const TIMEOUT_LIMIT = 14000;

export class FirestoreService {

    static onRequestSnapshot(request: Request) {
        let unsubscribeSnapshot: () => void | undefined;
        let timeoutId: NodeJS.Timeout;

        return new Promise<any>(async (resolve, reject) => {

            const requestRef: firebase.default.firestore.DocumentReference = await firestore.collection(Schemas.REQUEST.collection).add(request);

            unsubscribeSnapshot = requestRef.onSnapshot(
                snap => {
                    const requestData = snap.data();

                    if (!requestData) {
                        return reject(new CoreError(i18n.t(ErrorCode.INTERNAL)))
                    }

                    if (requestData.status === StatusCode.FAILURE) {
                        return reject(new CoreError(i18n.t(requestData.errorCode)))
                    }

                    if (requestData.status === StatusCode.SUCCESS) {
                        return resolve(requestData)
                    }
                },
                err => {
                    console.error(err);
                    return reject(new CoreError(i18n.t(ErrorCode.UNKNOWN), err.message));
                }
            );

            // Set a timeout in case no state changes happen
            timeoutId = setTimeout(
                reject => reject(new CoreError(i18n.t(ErrorCode.TIMEOUT))),
                TIMEOUT_LIMIT,
                reject.bind(this)
            );
        }).finally(() => {
            // Clear listener and timeout on promise reject/resolve
            if (unsubscribeSnapshot) unsubscribeSnapshot();
            if (timeoutId) clearTimeout(timeoutId);
        });
    }

    static async callFunction(functionName: string, body: any) {
        const callableResult = await functions.httpsCallable(functionName)(body);

        return callableResult.data
    }
}