import { Grid } from "@material-ui/core";
import { Title } from '../../../../../sections/Title';
import { ArrayInput } from "react-admin";
import i18n from '../../../../../language/i18n';
import KnowledgeSectionIterator from "../iterator";

const KnowledgeFormGrid = (props: any) => {
    const validateSection = (values: Array<{ title: string, link: string, description: string }>) => {
        if (values.length > 0) {
            const section = [...values];
            const hasEmptySection = section.some(({ title, link, description }) => {
                const titleIsEmpty = title === undefined || title.trim() === '';
                const linkIsEmpty = link === undefined || link.trim() === '';
                const descriptionIsEmpty = description === undefined || description.trim() === '';

                return titleIsEmpty || linkIsEmpty || descriptionIsEmpty;
            });

            if (hasEmptySection === true) {
                return i18n.t("homepage.setup.errors.knowledgeSection");
            }
        }

        return undefined;
    };

    return (
        <div>
            <div>
                <Grid container >
                    <Grid xs={12}>
                        <Title>{i18n.t("homepage.setup.knowledgeGrid.title")}</Title>
                        <ArrayInput source="knowledgeSection" label={''} validate={validateSection}>
                            <KnowledgeSectionIterator {...props} />
                        </ArrayInput>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default KnowledgeFormGrid;