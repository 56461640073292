import {
    Create,
    SimpleForm,
    TextInput,
    TopToolbar,
} from "ra-ui-materialui";
import { ArrayInput, SimpleFormIterator, CreateProps } from "react-admin";
import { PageTitle } from "../../../components/pageTitle";
import i18n from "../../../language/i18n";
import styles from "./index.module.css";

type ValidationProps = { name: string, values: string[] }

const validateJobTitleCreate = (values: ValidationProps) => {
    const errors: any = {};

    if (values.name === undefined) {
        errors.name = i18n.t("job_titles.create.error.name");
    }
    return errors;
};

const validateArrayInput = (values: any) => {
    if (values === undefined) {
        return undefined
    }
    const result = values.some((value: any) => {
        return value === undefined || (value.job === undefined || value.job === "")
    })
    if (result) {
        return i18n.t("job_titles.create.error.values");
    }
    return undefined
}

const JobTitleActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle>
                {i18n.t("sidebar.job_titles")}
            </PageTitle>
        </TopToolbar>
    );
};

export const JobTitleCreate = (props: CreateProps) => {
    const redirect = (_: string, id: string) => `/job_title/${id}`;
    return (
        <Create
            className={"mid-container"}
            actions={<JobTitleActions />}
            {...props}
        >
            <SimpleForm validate={validateJobTitleCreate} redirect={redirect} >
                <TextInput
                    source={"name"}
                    label={i18n.t("job_title.create.name")}
                    style={{ width: "50%" }}
                    required
                />
                <ArrayInput
                    source={"values"}
                    label={i18n.t("module.values.input")}
                    validate={validateArrayInput}
                >
                    <SimpleFormIterator >
                        <TextInput
                            source={"job"}
                            label={i18n.t("job_title.create.name")}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    );
};