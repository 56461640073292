import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import PT from '../language/locale/pt_PT.json';

const defaultLanguage = "pt"
const deviceLanguage = navigator.language;

export const parseLanguage = (lang: any) => {
    if (lang !== null && lang.trim() !== "") {
        if (lang.toLowerCase().startsWith("pt") || lang.toLowerCase().includes("pt")) {
            return "pt"
        }
    }
    return defaultLanguage
}

export let shortLocale = parseLanguage(deviceLanguage) as any;

const initOptions: InitOptions = {
    fallbackLng: defaultLanguage,
    debug: false,
    returnNull: false,
    defaultNS: "translation",
    resources: {
        pt: { translation: PT }
    },
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
        return "_" + key;
    },
    missingInterpolationHandler: (key) => {
        return "_" + key;
    },
    parseMissingKeyHandler: (key) => {
        return "_" + key;
    },
    interpolation: {
        escapeValue: false // react already safes from xss
    },
    react: {
        wait: true
    }
}

export class LanguageService {
    static initialize = () => {
        i18n.use(initReactI18next).init({ ...initOptions, lng: parseLanguage(deviceLanguage) }, (err: any) => {
            if (err) return
            // if languages loaded correctly ... do code here ...
        }).catch((err: any) => {
            // internal err ...
            console.log(err)
        });
    }

    static changeLanguage = (lang: any) => {
        const lng = parseLanguage(lang)
        shortLocale = lng
        i18n.changeLanguage(shortLocale)
    }
}

LanguageService.initialize()

export default i18n;

