import { GetListParams, UpdateParams } from "react-admin";
import { Schemas } from "../../model/core/Schemas";
import { Post } from "../../model/posts/Post"
import { firestore, DocumentData, DocumentSnapshot } from "../../services/persistence";
import { ResourceService } from "../../services/resource";
import { StorageService } from "../../services/storage/StorageService";
import { EntityMapper } from "../core/EntityMapper"

export class PostMapper extends EntityMapper<Post> {

    toFirestoreCriteria(_: GetListParams) {
        return {
            types: ["not_defined", "news", "blog", "note"],
            status: ["published", "unpublished", "draft", "archived"]
        }
    }

    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data, id } = params
        const service = ResourceService.post

        const res = Object.assign(
            {},
            super.toFirestore(params),
            this.toFirestoreField(data, 'title'),
            data.subTitle ? { subTitle: data.subTitle } : { subTitle: "" },
            this.toFirestoreField(data, 'lead'),
            this.toFirestoreField(data, 'body'),
            this.toFirestoreField(data, 'scheduled'),
            this.toFirestoreField(data, 'status'),
            this.toFirestoreField(data, 'type'),
            this.toFirestoreField(data, 'video'),
            this.toFirestoreField(data, 'podcast'),
            this.toFirestoreField(data, 'event'),
            data.publishDate ? { publishDate: data.publishDate } : {},
            data.tags ? { tags: [firestore.doc(`${Schemas.TAGS.collection}/${data.tags}`)] } : {},
            data.relatedPosts ? { relatedPosts: data.relatedPosts.map((ref: any) => firestore.doc(`${Schemas.POSTS.collection}/${ref}`)) } : {},
            data.usefulLinks ? { usefulLinks: data.usefulLinks.map((value: any) => value.link) } : {},
        ) as DocumentData;

        if (data.images !== undefined) {
            await StorageService.uploadImages(data.images, id as string, service)
            delete data.images
        }

        return res
    }

    async fromFirestore(snapshot: DocumentSnapshot): Promise<Post> {
        const data = snapshot.data();

        const obj = Object.assign(
            new Post(),
            super.fromFirestore(snapshot),
            this.fromFirestoreField(data, 'title'),
            this.fromFirestoreField(data, 'subTitle'),
            this.fromFirestoreField(data, 'lead'),
            this.fromFirestoreField(data, 'body'),
            this.fromFirestoreField(data, 'scheduled'),
            this.fromFirestoreField(data, 'status'),
            this.fromFirestoreField(data, 'type'),
            this.fromFirestoreField(data, 'video'),
            this.fromFirestoreField(data, 'podcast'),
            this.fromFirestoreField(data, 'event'),
            data!.publishDate ? { publishDate: data!.publishDate.toDate() } : {},
            data!.tags && data!.tags.length > 0 ? { tags: data?.tags[0].id } : {},
            data!.relatedPosts ? { relatedPosts: data!.relatedPosts.map((ref: any) => ref.id) } : {},
            data!.usefulLinks ? { usefulLinks: data!.usefulLinks.map((value: any) => { return { link: value } }) } : {},
        );

        if (data!.images) {
            obj.images = data!.images.map((imageLink: string) => ({ src: imageLink }))
        }

        return obj;
    }

}